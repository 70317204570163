import { Modal } from 'antd'
import { Button } from '@mui/material'
import { Close } from '@mui/icons-material'
import moment from 'moment'
import { useState } from 'react'
import { getColorCode } from '../../../utils/helper'

const data1 = [
  {
    id: 276,
    date: '2023-12-06T16:53:48.148871',
    fw_version: '3.4.9',
    fw_version_num: 3004009,
    device_model: 'IPQ5',
    dev_only: true,
    changelog: '',
    description: 'STC ENV',
    url: 'https://fw.io.xunison.com/nand-ipq5018-apps-3.4.9-9bf110fa7-Debug.img'
  },
  {
    id: 242,
    date: '2023-09-14T06:07:08.01163',
    fw_version: '3.3.4',
    fw_version_num: 3003004,
    device_model: 'IPQ5',
    dev_only: false,
    changelog: '',
    description: 'STC ENV',
    url: 'https://fw.io.xunison.com/norplusnand-ipq5018-apps-3.3.4-289d527c3-Release.img'
  },
  {
    id: 275,
    date: '2023-10-13T14:15:18.911891',
    fw_version: '3.3.3',
    fw_version_num: 3003003,
    device_model: 'IPQ5',
    dev_only: false,
    changelog: '',
    description: 'STC ENV',
    url: 'https://fw.io.xunison.com/norplusnand-ipq5018-apps-3.3.3-289d527c3-Release.img'
  },
  {
    id: 209,
    date: '2023-01-20T11:54:46',
    fw_version: '3.1.3',
    fw_version_num: 3001003,
    device_model: 'IPQ5',
    dev_only: true,
    changelog: '',
    description: '',
    url: 'https://fw.stc.io.xunison.com/norplusnand-ipq5018-apps-3.1.3-2a7b5bf-Release.img'
  }
]

const UpdateFirmware = ({ isOpen, onClose, onSubmit, title, subTitle, data }: any) => {
  const [confirmation, setConfirmation] = useState(false)
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const [selected, setSelected] = useState('')

  return (
    <>
      <Modal
        open={isOpen}
        footer={null}
        closable={false}
        width={500}
        wrapClassName={'reset_modal_wrapper'}
        centered={true}
      >
        <div className='reset_modal_body_wrapper device-tab'>
          <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
            <h3>{title}</h3>
            <Close
              className='cursor-pointer'
              onClick={() => {
                onClose()
              }}
            />
          </div>
          <div className='mb-2 row-gap-1 text-center'>
            {!data && <div className='mb-3 mt-3 text-lg font-bold'>Data not available</div>}
            {data?.length === 0 && <div className='mb-3 mt-3 text-lg font-bold'>Already updated</div>}
            {/* <div className='mb-1 mt-1 text-xl font-normal'>Live Versions</div> */}
            {data?.length !== 0 && selected === '' && <div className='text-sm font-normal'>Select the version</div>}
            {data?.map((obj: any, key: any) => {
              return (
                <div
                  className={`border mb-3 pb-15 pt-3  ${selected === obj.id ? 'bg-blue-200' : ''} `}
                  key={key}
                  onClick={() => {
                    setSelected(obj.id)
                  }}
                >
                  <div className={`flex justify-between pl-8 pr-8 text-sm font-normal`}>
                    <div>
                      {obj.fw_version} {obj.selected ? '*' : ''}
                    </div>
                    <div>
                      {moment.tz(moment.utc(obj.date), timeZone).format(`${process.env.REACT_APP_DATE_FORMAT}`)}
                    </div>
                  </div>
                </div>
              )
            })}

            {data && data?.length !== 0 && (
              <div className='mt-3'>
                <Button
                  onClick={e => {
                    e.preventDefault()
                    setConfirmation(true)
                  }}
                  variant='contained'
                  size='medium'
                  sx={{ marginRight: '10px', background: getColorCode('infoBlue') }}
                  disabled={selected === ''}
                >
                  Update
                </Button>
                <Button
                  onClick={() => onClose()}
                  size='medium'
                  variant='outlined'
                  sx={{ color: getColorCode('infoBlue'), borderColor: getColorCode('infoBlue') }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        open={confirmation}
        footer={null}
        closable={false}
        width={300}
        wrapClassName={'reset_confirmation_modal_wrapper'}
        centered={true}
      >
        <div className='reset_modal_body_wrapper device-tab'>
          <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
            <h3>Confirm</h3>
            <Close
              className='cursor-pointer'
              onClick={() => {
                onClose()
              }}
            />
          </div>
          <div className='mb-2 row-gap-1 text-center'>
            <h4>Are you sure you want to update the FW?</h4>

            {data && data?.length !== 0 && (
              <div className='mt-3'>
                <Button
                  onClick={e => {
                    e.preventDefault()
                    onSubmit(selected)
                  }}
                  variant='contained'
                  size='medium'
                  sx={{ marginRight: '10px', background: getColorCode('infoBlue') }}
                  disabled={selected === ''}
                >
                  Ok
                </Button>
                <Button
                  onClick={() => onClose()}
                  size='medium'
                  variant='outlined'
                  sx={{ color: getColorCode('infoBlue'), borderColor: getColorCode('infoBlue') }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UpdateFirmware
