import { Modal } from 'antd'
import { Button, FormHelperText, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Formik } from 'formik'
import TextArea from 'antd/es/input/TextArea'
import * as Yup from 'yup'
import { getColorCode } from '../../../utils/helper'

// import './index.scss'

const SendCommandModal = ({ isOpen, onClose, onSubmit, title, subTitle }: any) => {
  const handleValidSubmit = (values: any, actions: any) => {
    actions.setSubmitting(false)

    onSubmit(JSON.parse(values.description))
  }
  return (
    <Modal
      open={isOpen}
      footer={null}
      closable={false}
      width={500}
      wrapClassName={'reset_wifi_wrapper'}
      centered={true}
    >
      <Formik
        initialValues={{
          description: ''
        }}
        validationSchema={Yup.object().shape({
          description: Yup.string().test('is-json', 'Enter valid JSON Format', function (value: any) {
            try {
              JSON.parse(value)
              return true
            } catch (error) {
              return false
            }
          })
        })}
        onSubmit={handleValidSubmit}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, resetForm }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className='reset_wifi_body_wrapper device-tab'>
              <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
                <h3>{title}</h3>
                <Close
                  className='cursor-pointer'
                  onClick={() => {
                    onClose()
                    resetForm()
                  }}
                />
              </div>
              <div className='mb-2 row-gap-1 text-center'>
                <div className='mb-3 mt-1'>{subTitle}</div>
                <div className='mb-3 mt-1'>
                  {/* <TextField
                    fullWidth
                    error={Boolean(touched.description && errors.description)}
                    id='description'
                    value={values.description}
                    // multiline={true}
                    rows={4}
                    name='description'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder='Enter description'
                  /> */}
                  <TextArea
                    // error={Boolean(touched.description && errors.description)}
                    id='description'
                    value={values.description}
                    name='description'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder='Enter description'
                    style={
                      touched.description && errors.description
                        ? { border: `1px solid ${getColorCode('redToBlue')}` }
                        : { border: `1px solid #ccc` }
                    }
                  />
                  {touched.description && errors.description && (
                    <span
                      className='errorText'
                      style={{ color: getColorCode('redToBlue'), display: 'block', textAlign: 'left' }}
                    >
                      {errors.description}
                    </span>
                  )}
                  <div className='mt-3'>
                    <Button
                      type='submit'
                      variant='contained'
                      size='medium'
                      sx={{ background: getColorCode('infoBlue'), marginRight: '10px' }}
                    >
                      Send
                    </Button>

                    <Button
                      onClick={() => {
                        resetForm()
                        onClose()
                      }}
                      size='medium'
                      variant='outlined'
                      sx={{ color: getColorCode('infoBlue'), borderColor: getColorCode('infoBlue') }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>

                <div></div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default SendCommandModal
