import React from "react";

interface TriangleArrowProps {
  fillColor: string; // Add a type annotation for the fillColor prop
  width?: string;
  height?: string;
}

const TriangleArrow: React.FC<TriangleArrowProps> = ({ fillColor,width, height}) => {
  return (
    <svg width={width ? width : '15px'}
        height={height ? height : '10px'} viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.36682 7.85982C-0.175952 8.51114 0.287204 9.5 1.13504 9.5H13.5316C14.3795 9.5 14.8426 8.51114 14.2998 7.85982L8.10155 0.421865C7.70176 -0.057889 6.96491 -0.057889 6.56511 0.421865L0.36682 7.85982Z" fill={fillColor}/>
    </svg>

  );
};

export default TriangleArrow;
