import React from 'react'

const BreadcrumbsArrowRight = () => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.50513 2.71978L10.875 7.06645C11.391 7.57978 11.391 8.41978 10.875 8.93312L6.50513 13.2798'
        stroke='#6B7A9F'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default BreadcrumbsArrowRight
