import { useEffect, useState } from 'react'
import { ArrowRight2 } from 'iconsax-react'
import './index.scss'
import { Box, Grid, Button, FormControlLabel, Checkbox, TextField, Snackbar } from '@mui/material'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import { useSelector } from 'react-redux'
import { CustomersRootState } from '../../../redux/customerStatistics/types'
import { SnackBarProps } from '../../../components/Device-table/Provisioning'
import Loader from '../../../components/Loader'
import { getColorCode } from '../../../utils/helper'
import { useLocation } from 'react-router-dom'
import { useSessionContex } from '../../../SessionContex'
import MuiAlert from '@mui/material/Alert'

// import { Checkbox } from 'antd'
// import TableData from './TableData'
// import DCChart from './DCChart'
// import ResetModal from './ResetModal'
// import ResetWifiPassword from './ResetWifiPassword'
// import ChangePinModal from './ChangePinModal'
// import SendCommandModal from './SendCommandModal'
// import UpdateFirmware from './UpdateFirmware'

const leftArray: any = [
  {
    name: '5G/4G Manager',
    title: 'Overview',
    description: 'Overview of 5G/4G device'
  }
]
const hardwareArray1: any = [
  {
    title: 'Hardware',
    data: [
      {
        name: 'Manufacturer',
        value: 'N/A'
      },
      {
        name: 'Model',
        value: 'N/A'
      },
      {
        name: 'Firmware Revision',
        value: 'N/A'
      }
    ]
  },
  {
    title: '3GPP',
    data: [
      {
        name: 'IMEI',
        value: 'N/A',
        key: 'imei'
      },
      {
        name: 'Operator ID',
        value: 'N/A',
        key: 'oper',
        main: 'network_info'
      },
      {
        name: 'Operator Name',
        value: 'N/A',
        main: 'mobile_operator',
        key: 'oper'
      },
      {
        name: 'Band',
        value: 'N/A',
        main: 'network_info',
        key: 'band'
      },
      {
        name: 'Channel',
        value: 'N/A',
        main: 'network_info',
        key: 'channel'
      },
      {
        name: 'Act',
        value: 'N/A',
        main: 'network_info',
        key: 'Act'
      },
      {
        name: 'Type',
        value: 'N/A',
        key: 'connection_label'
      }
    ]
  },
  {
    title: 'Status',
    data: [
      {
        name: 'State',
        value: 'N/A',
        key: 'status'
      },
      {
        name: 'Sim State',
        value: 'N/A',
        key: 'sim_status'
      }
    ]
  }
]
const signalArray1: any = [
  {
    title: '5G',
    data: [
      {
        name: 'RSRP',
        value: 'N/A',
        key: 'rsrp'
      },
      {
        name: 'RSRQ',
        value: 'N/A',
        key: 'rsrq'
      },
      {
        name: 'SNR',
        value: 'N/A',
        key: 'sinr'
      }
    ]
  }
]
function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}
const ConfigurationPage = () => {
  const { openModal, setOpenModal } = useSessionContex()
  const [checkBoxArray, setCheckBoxArray] = useState([1, 3, 5, 7, 8, 20, 28, 32, 38, 40, 41, 42, 43])
  const [checkBoxArray1, setCheckBoxArray1] = useState([1, 3, 5, 7, 8, 20, 28, 32, 38, 40, 41, 42, 43])
  const [checkBoxArray2, setCheckBoxArray2] = useState([1, 3, 5, 7, 8, 20, 28, 32, 38, 40, 41, 42, 43])

  const [hardwareArray, setHardwareArray] = useState(hardwareArray1)
  const [signalArray, setSignalArray] = useState(signalArray1)
  const [selected, setSelected] = useState(0)
  const [checkedList, setCheckedList] = useState<any>([])
  const [itemsChecked, setItemsChecked] = useState<any>(true)
  const [checkedList1, setCheckedList1] = useState<any>([])
  const [itemsChecked1, setItemsChecked1] = useState<any>(true)
  const [checkedList2, setCheckedList2] = useState<any>([])
  const [itemsChecked2, setItemsChecked2] = useState<any>(true)
  const [mqtt, setMqtt] = useState<any>(null)
  const [connectedToMqtt, setConnectedToMqtt] = useState<boolean>(false)
  const [fetchingRouterSettings, setFetchingRouterSettings] = useState<boolean>(true)
  const [apnVal, setApnVal] = useState<any>('')
  const [buttonDisable, setButtonDisable] = useState(true)
  const [fieldDisable, setFieldDisable] = useState(true)
  const [mqttMessage, setMqttMessage] = useState('')

  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed'
  })

  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)

  const { data: gatewayInfo, refetch }: any = useGatewayInfo(activeGw_Uid) //User device data

  useEffect(() => {
    refetch()
    setHardwareArray(hardwareArray1)
    setSignalArray(signalArray1)
    setMqttMessage('')
    setFieldDisable(true)
  }, [activeGw_Uid])

  const initializeMqtt = async () => {
    const JsMqttClientImpl = (await import('../../../mqtt')).JsMqttClientImpl
    setMqtt(new JsMqttClientImpl())
  }
  useEffect(() => {
    if (gatewayInfo === 401) {
      !openModal && setOpenModal(true)
    } else {
      initializeMqtt()
    }
  }, [gatewayInfo])

  const topic = `/router/${activeGw_Uid}/command`
  const topic2 = `/router/${activeGw_Uid}/resp`
  const topic3 = `/router/${activeGw_Uid}/site_survey`

  const message = JSON.stringify({
    cmd: 'getsysconfig',
    callid: 3,
    response_topic: 'resp',
    op: 'wizard',
    params: ['sim_apn', 'cell_data', 'lte_4g_bands', 'lte_5g_bands', 'lte_nsa_5g_bands']
  })

  useEffect(() => {
    if (!activeGw_Uid) return
    setConnectedToMqtt(false)
    setFetchingRouterSettings(true)

    if (gatewayInfo && !connectedToMqtt) {
      mqtt
        ?.connect()
        .then(() => {
          setConnectedToMqtt(true)

          setSnackBarInfo({
            isOpen: true,
            message: 'connected to Router',
            type: 'Closed'
          })
          console.log('connected to Router')

          mqtt.subscribeToTopics([topic2, topic, topic3])
          mqtt.sendMessage({ message, topic })
        })
        .catch((err: any) => {
          mqtt.disconnect()
          setSnackBarInfo({
            isOpen: true,
            message: 'Not connected to Router',
            type: 'redToRed'
          })
          console.log('not connected to Router')
          setTimeout(() => {
            initializeMqtt()
          }, 500)
        })
    }
    if (gatewayInfo && !gatewayInfo?.is_online) {
      setFetchingRouterSettings(false)
      setSnackBarInfo({
        isOpen: true,
        message: 'This device is offline',
        type: 'redToRed'
      })
      setMqttMessage('Device is offline, To get updated status please make it online')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mqtt, activeGw_Uid])

  useEffect(() => {
    if (mqtt) {
      mqtt?.addMessageListener((message: any) => {
        const res = JSON.parse(message)
        console.log('==========', res)

        if (res.name === 'sysconfig') {
          if (res.callid === 3) {
            setSnackBarInfo({
              isOpen: true,
              message: 'Fetched router configuration successfully',
              type: 'Closed'
            })
            console.log('Fetched router configuration successfully')
            setTimeout(() => {
              setFetchingRouterSettings(false)
            }, 1000)
          }
          if (res.callid === 356 && res.result === 'OK') {
            setSnackBarInfo({
              isOpen: true,
              message: 'Applied new config successfully',
              type: 'Closed'
            })
            console.log('Applied new config successfully')
          }

          if (res && res?.params && res?.params.cell_data) {
            const cell = res?.params?.cell_data
            if (
              cell.status !== 'FAIL' &&
              (gatewayInfo?.model === 'D50' || gatewayInfo?.model === 'D60') &&
              gatewayInfo?.lte_module === 'Not installed'
            ) {
              if (mqttMessage === '') {
                setMqttMessage('This router does not support LTE connectivity.')
              }
            }
            if (cell.status === 'FAIL') {
              if (gatewayInfo?.dev_mesh_role === 'NonCAP') {
                setMqttMessage('Modifying the LTE settings from the satellite device is restricted and not permitted.')
              }
              if (gatewayInfo?.dev_mesh_role === 'CAP') {
                setMqttMessage('This router does not support LTE connectivity')
              }
            }
            if (cell?.sim_status !== '') {
              setFieldDisable(false)
            } else {
              setFieldDisable(true)
            }

            // if (cell?.sim_status !== '') {
            //   setFieldDisable(false)
            // } else {
            //   setFieldDisable(true)
            // }
            setCheckBoxArray(cell?.supported_band_4g)
            setCheckedList(res?.params?.lte_4g_bands?.split(':').map(Number))
            setCheckBoxArray1(cell?.supported_band_5g)
            setCheckedList1(res?.params?.lte_5g_bands?.split(':').map(Number))
            setCheckBoxArray2(cell?.supported_band_nsa_5g)
            setCheckedList2(res?.params?.lte_nsa_5g_bands?.split(':').map(Number))
            const hardwareData = cell?.ati?.split(' ')

            hardwareArray[0].data[0].value = hardwareData[0]
            hardwareArray[0].data[1].value = hardwareData[1]
            hardwareArray[0].data[2].value = hardwareData[3]

            hardwareArray[1].data.forEach((obj: any) => {
              if (obj.main) {
                obj.value = cell[obj.main] ? cell[obj.main][obj.key] : 'N/A'
              } else {
                obj.value = cell[obj.key] || 'N/A'
              }
            })

            //status
            hardwareArray[2].data.forEach((obj: any) => {
              obj.value = cell[obj.key] || 'N/A'
            })

            signalArray[0].data.forEach((obj: any) => {
              obj.value = cell?.serving_cell?.mode_data[obj.key] || 'N/A'
            })

            setHardwareArray(hardwareArray)
            setSignalArray(signalArray)
            setApnVal(res?.params?.sim_apn || '')
          }
        }
      })
    }
  }, [mqtt, activeGw_Uid])

  useEffect(() => {
    if (checkedList?.length === checkBoxArray?.length) {
      setItemsChecked(true)
    } else {
      setItemsChecked(false)
    }
  }, [checkedList])

  useEffect(() => {
    if (checkedList1?.length === checkBoxArray1?.length) {
      setItemsChecked1(true)
    } else {
      setItemsChecked1(false)
    }
  }, [checkedList1])

  useEffect(() => {
    if (checkedList2?.length === checkBoxArray2?.length) {
      setItemsChecked2(true)
    } else {
      setItemsChecked2(false)
    }
  }, [checkedList2])

  const handleCheckboxClick = (e: any, value: any) => {
    setButtonDisable(false)
    const { checked } = e.target

    if (checked) {
      setCheckedList([...checkedList, value])
    } else {
      setCheckedList(checkedList.filter((item: any) => item !== value))
    }
  }

  const selectItem = () => {
    setButtonDisable(false)
    if (itemsChecked) {
      setCheckedList([])
    } else {
      setCheckedList([...checkBoxArray])
    }
    setItemsChecked(!itemsChecked)
  }

  const handleCheckboxClick1 = (e: any, value: any) => {
    setButtonDisable(false)
    const { checked } = e.target

    if (checked) {
      setCheckedList1([...checkedList1, value])
    } else {
      setCheckedList1(checkedList1.filter((item: any) => item !== value))
    }
  }

  const selectItem1 = () => {
    setButtonDisable(false)
    if (itemsChecked1) {
      setCheckedList1([])
    } else {
      setCheckedList1([...checkBoxArray1])
    }
    setItemsChecked1(!itemsChecked1)
  }

  const handleCheckboxClick2 = (e: any, value: any) => {
    setButtonDisable(false)
    const { checked } = e.target

    if (checked) {
      setCheckedList2([...checkedList2, value])
    } else {
      setCheckedList2(checkedList2.filter((item: any) => item !== value))
    }
  }

  const selectItem2 = () => {
    setButtonDisable(false)
    if (itemsChecked2) {
      setCheckedList2([])
    } else {
      setCheckedList2([...checkBoxArray2])
    }
    setItemsChecked2(!itemsChecked2)
  }

  async function sendCommand(message: any, topic: string) {
    if (connectedToMqtt) {
      await mqtt.sendMessage({ message, topic })
    }
  }

  const onUpdate = (e: any) => {
    e.preventDefault()
    setButtonDisable(true)
    setFetchingRouterSettings(true)

    const routerConfigPayload = JSON.stringify({
      cmd: 'setsysconfig',
      // callid: 356,
      // response_topic: 'resp',
      params: {
        lte_4g_bands: checkedList?.join(':'),
        lte_5g_bands: checkedList1?.join(':'),
        lte_nsa_5g_bands: checkedList2?.join(':'),
        sim_apn: apnVal
      }
    })
    sendCommand(routerConfigPayload, topic).then(
      (result: any) => {
        setSnackBarInfo({
          isOpen: true,
          message: 'Applying new config',
          type: 'chartPurpleBlue'
        })
        // setTimeout(() => {
        sendCommand(message, topic)
        // }, 500)
      },
      error => {
        setFetchingRouterSettings(false)

        setSnackBarInfo({
          isOpen: true,
          message: `You can not update`,
          type: 'redToRed'
        })
      }
    )
  }

  return (
    <div className='device-control sec-color w-full'>
      <Snackbar
        open={snackBarInfo.isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={() =>
          setSnackBarInfo({
            isOpen: false,
            message: snackBarInfo.message,
            type: snackBarInfo.type
          })
        }
        sx={{ backgroundColor: 'transparent' }}
      >
        <div>
          <Alert severity='success' sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}>
            {snackBarInfo.message}
          </Alert>
        </div>
      </Snackbar>
      <Grid container rowSpacing={1} sx={{ height: '100%' }}>
        <Grid item sm={12} lg={3} md={3} sx={{ height: '100%' }}>
          <div className='bg-white rounded-sm p-3' style={{ height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: { md: 'column', sm: 'row' }, overflow: 'auto', height: '90%' }}>
              {leftArray?.map((obj: any, key: any) => {
                return (
                  <div
                    key={key}
                    className={`flex items-center justify-between p-3 cursor-pointer ${
                      key === selected ? 'active-bg rounded' : 'bg-white'
                    }`}
                    // onClick={() => {
                    //   displayData(key)
                    // }}
                  >
                    <div>
                      <div className='text-base font-bold'> {obj.name}</div>
                    </div>
                    <div>
                      <ArrowRight2 />
                    </div>
                  </div>
                )
              })}
            </Box>
          </div>
        </Grid>
        <Grid item sm={12} lg={9} md={9} sx={{ height: '100%' }}>
          <Box className='pl-5 pr-5 ml-5' sx={{ height: { md: 'calc(100vh - 250px)', sm: 'auto' } }}>
            <Box
              display='flex'
              justifyContent={'space-between'}
              className='bg-white info-card-dev mb-3'
              alignItems={'center'}
            >
              <div className='  p-10'>
                <div className='font-bold title'>{leftArray[selected]?.title}</div>
                <div>{leftArray[selected]?.description}</div>
              </div>
              <Box textAlign={'end'} sx={{ marginRight: 2 }}>
                {mqttMessage === '' && !fieldDisable && (
                  <Button
                    variant='contained'
                    onClick={onUpdate}
                    disabled={buttonDisable}
                    sx={{ background: getColorCode('blueBlue') }}
                    // disabled
                  >
                    Update
                  </Button>
                )}
              </Box>
            </Box>
            {fetchingRouterSettings ? (
              <Loader />
            ) : (
              <Box sx={{ height: { md: 'calc(100% - 75px)', sm: 'auto' }, overflow: 'auto' }}>
                {mqttMessage !== '' ? (
                  <Box
                    className='bg-white info-card-dev mb-4 p-2 text-center'
                    style={{ color: getColorCode('redToBlue') }}
                  >
                    {mqttMessage}
                  </Box>
                ) : (
                  <>
                    {hardwareArray.map((obj: any, key: number) => {
                      return (
                        <Box key={key} className='bg-white info-card-dev mb-4 pb-2'>
                          <Box className='font-bold title pt-2 pl-10 border-b-2'>{obj.title}</Box>
                          {obj.data.map((item: any, index: any) => (
                            <div className='d-flex info-div' key={index}>
                              <label className='info-name-cls'> {item.name}</label>
                              <label className='info-desc-cls'> {item.value}</label>
                            </div>
                          ))}
                        </Box>
                      )
                    })}

                    {/* <div className='font-bold title'>Signal</div> */}
                    {signalArray.map((obj: any, key: number) => {
                      return (
                        <Box key={key} className='bg-white info-card-dev mb-4 pb-2'>
                          <div className='font-bold title pt-2 pl-10 border-b-2'>Signal-{obj.title}</div>
                          {obj.data.map((item: any, index: any) => (
                            <div className='d-flex info-div'>
                              <label className='info-name-cls'> {item.name}</label>
                              <label className='info-desc-cls'> {item.value}</label>
                            </div>
                          ))}
                        </Box>
                      )
                    })}
                    <Box className='bg-white info-card-dev mb-4 pb-2'>
                      <div className='font-bold title pt-2 pl-10 border-b-2'>Signal-APN</div>

                      <div className='d-flex info-div'>
                        <label className='info-name-cls'>Apn</label>
                        <label className='info-desc-cls'>
                          <TextField
                            fullWidth
                            type='text'
                            value={apnVal}
                            disabled={fieldDisable}
                            onChange={e => {
                              setApnVal(e.target.value)
                              setButtonDisable(false)
                            }}
                            size='small'
                          />
                        </label>
                      </div>
                    </Box>
                    <Box className='bg-white info-card-dev mb-4 pb-2'>
                      <div className='font-bold title pt-2 pl-10 border-b-2'>Bands</div>
                      <div className='d-flex info-div'>
                        <div className='info-name-cls'> Band LTE</div>
                        <div className='info-desc-cls '>
                          <Box display='flex' sx={{ flexWrap: 'wrap', marginTop: '5px' }}>
                            {checkBoxArray && checkBoxArray.length !== 0 ? (
                              checkBoxArray?.map(item => {
                                return (
                                  <Box marginRight={3}>
                                    <Checkbox
                                      value={item}
                                      disabled={fieldDisable}
                                      checked={checkedList.includes(item)}
                                      onChange={e => handleCheckboxClick(e, item)}
                                    />
                                    <span className='pl-2'>{item}</span>
                                  </Box>
                                )
                              })
                            ) : (
                              <Box>Not available</Box>
                            )}
                          </Box>
                          {checkBoxArray && checkBoxArray.length !== 0 && (
                            <>
                              <Checkbox disabled={fieldDisable} checked={itemsChecked} onClick={selectItem} />{' '}
                              <span className='pl-2'>Select All</span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className='d-flex info-div'>
                        <div className='info-name-cls'>Band 5G</div>
                        <div className='info-desc-cls '>
                          <Box display='flex' sx={{ flexWrap: 'wrap', marginTop: '5px' }}>
                            {checkBoxArray1 && checkBoxArray1.length !== 0 ? (
                              checkBoxArray1?.map(item => {
                                return (
                                  <Box marginRight={3}>
                                    <Checkbox
                                      value={item}
                                      disabled={fieldDisable}
                                      checked={checkedList1.includes(item) || false}
                                      onChange={e => handleCheckboxClick1(e, item)}
                                    />
                                    <span className='pl-2'>{item}</span>

                                    {/* <Checkbox
                              value={item}
                              checked={checkedList1.includes(item)}
                              onChange={handleCheckboxClick1}
                            />
                             */}
                                  </Box>
                                )
                              })
                            ) : (
                              <Box>Not available</Box>
                            )}
                          </Box>
                          {checkBoxArray1 && checkBoxArray1.length !== 0 && (
                            <>
                              <Checkbox
                                disabled={fieldDisable}
                                checked={itemsChecked1}
                                onClick={selectItem1}
                                value={'all'}
                              />
                              <span className='pl-2'>Select All</span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className='d-flex info-div'>
                        <div className='info-name-cls'> Band NSA 5G</div>
                        <div className='info-desc-cls '>
                          <Box display='flex' sx={{ flexWrap: 'wrap', marginTop: '5px' }}>
                            {checkBoxArray2 && checkBoxArray2.length !== 0 ? (
                              checkBoxArray2?.map(item => {
                                return (
                                  <Box marginRight={3}>
                                    <Checkbox
                                      disabled={fieldDisable}
                                      value={item}
                                      checked={checkedList2.includes(item)}
                                      onChange={e => handleCheckboxClick2(e, item)}
                                    />
                                    <span className='pl-2'>{item}</span>
                                  </Box>
                                )
                              })
                            ) : (
                              <Box>Not available</Box>
                            )}
                          </Box>
                          {checkBoxArray2 && checkBoxArray2.length !== 0 && (
                            <>
                              <Checkbox disabled={fieldDisable} checked={itemsChecked2} onClick={selectItem2} />
                              <span className='pl-2'>Select All</span>
                            </>
                          )}
                        </div>
                      </div>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      {/* {isOpen && renderContent()} */}
    </div>
  )
}
export default ConfigurationPage
