import moment from 'moment/moment'
import { useGatewayActivityLogs, useGatewayAllActivityLogs } from '../../../../hook/useGatewayLogs'
import Loader from '../../../../components/Loader'
import { Tooltip } from 'antd'
import TablePagination from '@mui/material/TablePagination'
import { useSelector } from 'react-redux'
import { CustomersRootState } from '../../../../redux/customerStatistics/types'
import { useEffect, ChangeEvent, useState } from 'react'
import { useSessionContex } from '../../../../SessionContex'

const GatewayActivityLogs = () => {
  const gw_uid = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const { openModal, setOpenModal } = useSessionContex()
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(6)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const { data, isLoading, refetch } = useGatewayActivityLogs(gw_uid || '', limit, offset)
  const {
    data: allGetwayData,
    isLoading: allGetwayIsLoadning,
    refetch: allGetwayRefech
  } = useGatewayAllActivityLogs(gw_uid || '')

  useEffect(() => {
    if (allGetwayData === 401) {
      !openModal && setOpenModal(true)
    }
  }, [allGetwayData])

  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data])

  useEffect(() => {
    refetch()
  }, [gw_uid, offset, limit, currentPage])

  useEffect(() => {
    allGetwayRefech()
    setCurrentPage(0)
    setOffset(0)
  }, [gw_uid])

  // useEffect(() => {
  //   allGetwayRefech()
  // }, [])

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage)

    const newOffset = newPage * limit
    setOffset(newOffset)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value))
    setOffset(0)
    setCurrentPage(0)
  }

  return (
    <div className='w-full'>
      {isLoading ? (
        <div className='d-flex w-full justify-center aline-center'>
          <Loader />
        </div>
      ) : (
        <table className='pc-table w-full'>
          <thead>
            <tr>
              <td>Gateway UID</td>
              <td>Email</td>
              <td>Event</td>
              <td>Time</td>
            </tr>
          </thead>
          <tbody className='w-full'>
            {data && data?.length > 0 ? (
              data?.map((item: any, index: any) => {
                const e_mail = sessionStorage.getItem('email') || data[0]?.email
                return (
                  <tr key={`admin_logs${index}`}>
                    <td>{item?.gw_uid}</td>
                    <td>{item?.email === null ? e_mail : item.email}</td>
                    <td>{item?.event.replace('Admin', '') || '-'}</td>
                    <td className='cursor-pointer'>
                      <Tooltip
                        placement='top'
                        title={
                          moment.utc(item?.timestamp).tz(timeZone).format(`${process.env.REACT_APP_DATE_FORMAT} - LT`)
                          // moment
                          // .tz(moment.utc(item.timestamp), timeZone)
                          // .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm`)
                        }
                      >
                        {moment.utc(item?.timestamp).tz(timeZone).fromNow() || '-'}
                        {/* {moment.tz(moment.utc(item.timestamp), timeZone).fromNow()} */}
                      </Tooltip>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr className='empty-row'>
                <td colSpan={4} style={{ textAlign: 'center' }}>
                  Activity logs not found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {!isLoading && data && data?.length > 0 && (
        <TablePagination
          className='customers_footer'
          sx={{ marginRight: 0 }}
          rowsPerPageOptions={[6, 10, 20, 50]}
          component='div'
          count={allGetwayData?.length || 0}
          rowsPerPage={limit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}
export default GatewayActivityLogs
