import { FC, useContext, useEffect } from 'react'
import { BreadcrumbContext } from '../../../../contexts/BreadcrumbContext'
import { useParams } from 'react-router-dom'
import { useParentalConnectedDevices } from '../../../../hook/parental-control/useParental'
import { currentISOTime } from '../../../../utils/helper'
import { useGatewayInfo } from '../../../../hook/useGatewayInfo'
import ConnectedDevices from '../../../../components/parental-components/connected-devices/connected-devices'
import WebSupervision from '../../../../components/parental-components/web-supervision/web-supervision'
import SocialPieChart from '../../../../components/parental-components/charts/social-supervision-pie-chart'
import ParentalLayout from '../../../../Layouts/parentalLayout/parental-layout'
import './styles.scss'
import TimeSuperVision from '../../../../components/customers/cards/time-supervision/time-supervision'
import SessionTimeOutModal from '../../../../components/SessionTimeOutModal'
import { useSessionContex } from '../../../../SessionContex'
// import Loader from '../../../../components/Loader'

const ParentalDashboard: FC = () => {
  // const { updateBreadcrumbData, updateFilters } = useContext(BreadcrumbContext)
  const { id: serialNumber } = useParams()
  const { openModal, setOpenModal } = useSessionContex()

  //@ts-ignore
  const { data: gatewayInfo } = useGatewayInfo(serialNumber as any)
  useEffect(() => {
    if (gatewayInfo === 401) {
      !openModal && setOpenModal(true)
    }
  }, [gatewayInfo])
  //  const {
  //    data: connectedDevices,
  //    isLoading: LoadingConnectedDevices,
  //    refetch: refetchConnectedDevices
  //  } = useParentalConnectedDevices(currentISOTime(), sessionStorage.getItem('email') || null)
  // useEffect(() => {
  //   if (gatewayInfo) {
  //     const newBreadcrumbData = [
  //       { label: 'Home', href: `/dashboard` },
  //       { label: 'Customers', href: `/dashboard/${gatewayInfo.gw_uid}` },
  //       { label: gatewayInfo?.email || '-', href: `/dashboard/${gatewayInfo?.gw_uid}` },
  //       { label: 'Parental Control', href: `/dashboard/${gatewayInfo?.gw_uid}` },
  //       { label: 'Home', href: `/dashboard/${gatewayInfo?.gw_uid}?email=${gatewayInfo?.email}` }
  //     ]
  //     const filters: any = []
  //     updateFilters(filters)
  //     updateBreadcrumbData(newBreadcrumbData)
  //   }
  // }, [gatewayInfo])

  // const chartData = [
  //   {
  //     name: 'Category 1',
  //     y: 30
  //   },
  //   {
  //     name: 'Category 2',
  //     y: 50
  //   },
  //   {
  //     name: 'Category 3',
  //     y: 20
  //   }
  // ]
  // useEffect(() => {
  //   refetchConnectedDevices()
  // }, [])

  return (
    <ParentalLayout serialNumber={serialNumber as any} useSidebar='Parental'>
      <div className='dashboard-main-wrapper'>
        {gatewayInfo && <WebSupervision email={gatewayInfo?.email} isCard={true} />}

        <div className='flex gap-x-6 flex-col md:flex-row  w-full pt-[66px] dashboard-inner-wrapper'>
          <div className=' flex flex-col w-full gap-y-[41px] dashboard-main-block'>
            {gatewayInfo && <TimeSuperVision email={gatewayInfo?.email} isCard={true} />}
          </div>
          <div className='dashboard-social-wrapper'>
            <div className='dashboard-social '>
              {gatewayInfo && <SocialPieChart email={gatewayInfo.email} isFilter={true} />}
            </div>
            <div className='w-full dashboard-other-block '>
              {gatewayInfo && <ConnectedDevices email={gatewayInfo?.email} isFilter={true} />}
            </div>
          </div>
        </div>
      </div>
    </ParentalLayout>
  )
}

export default ParentalDashboard
