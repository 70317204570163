import { useMutation, useQuery } from '@tanstack/react-query'
import { notifyError } from '../../components/Toaster'
import xcpemSec from '../../axiosInstances/xcpemSec'
import xcpemReadOnlySec from '../../axiosInstances/xcpemReadOnlySec'
import xcpemCyber from '../../axiosInstances/xcpemCyber'
import axios from 'axios'

const host = `https://${process.env.REACT_APP_IOTCORE}`
const baseUrl = `${host}`

export const useDeviceControlCount = (
  gwuid: string,
  from?: string,
  event?: string | any
): {
  data: any[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
  isSuccess: boolean
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch, isSuccess } = useQuery(
    ['device-control-count'],
    async () => {
      if (!gwuid || gwuid === '') return undefined

      try {
        const response = await xcpemSec.get<any[]>(
          `/rpc/webapp_device_control_count?_gw_uid=${gwuid}&_timestamp_from=${from}&_event_type=${encodeURIComponent(
            event
          )}`
        )

        return response.data
      } catch (error: any) {
        console.log('========', error)

        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`device-control-count: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch, isSuccess }
}
export const usefirmware = (
  modal?: string
): {
  data: any[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['fw_versions'],
    async () => {
      try {
        if (!modal) return

        const response = await xcpemSec.get(
          `${baseUrl}/webapp_list_fw_versions?device_model=eq.${encodeURIComponent(modal)}`
        )

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`fw_versions: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useDeviceControlLogs = (
  currentPage: any,
  activeGw_Uid: string,
  limit?: any,
  time?: string,
  event?: string | any
): {
  data: [] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
  isSuccess: boolean
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch, isSuccess } = useQuery(
    ['device-control-admin-log'],
    async () => {
      const gw_uid = !!activeGw_Uid ? activeGw_Uid : sessionStorage.getItem('gwuid')

      if (gw_uid === null) return []

      const endpoint = `/webapp_dashboard_admin_log?gw_uid=eq.${gw_uid}&limit=${limit}&offset=${currentPage}&timestamp=gt.${time}&event=not.is.null&event_type=eq.${encodeURIComponent(
        event
      )}`
      try {
        const response = await xcpemReadOnlySec.get<[]>(endpoint)
        // const response = await xcpemReadOnlySec.get<[]>(
        //   '/webapp_dashboard_admin_log?gw_uid=eq.gw760f86604bf6c004dbeb841c645ca198&timestamp=gt.2022-11-21T04:19:09.071&event=eq.Admin GW DE-PROVISIONED&limit=10&offset=0'
        // )
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`device-control-admin-log: ${error?.response?.data?.message || error?.message}`)
        }
        // throw new Error('Failed to fetch admin logs')
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch, isSuccess }
}

export const useDeviceControlFailLogs = (
  currentPage: any,
  activeGw_Uid: string,
  limit?: any,
  // ltTime?: any,
  time?: string,
  event?: string | any
): {
  data: [] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  isSuccess: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, isSuccess, refetch } = useQuery(
    ['device-control-fail-admin-log'],
    async () => {
      const gw_uid = !!activeGw_Uid ? activeGw_Uid : sessionStorage.getItem('gwuid')

      if (gw_uid === null) return []

      const endpoint = `/webapp_dashboard_admin_log?gw_uid=eq.${gw_uid}&limit=${limit}&offset=${currentPage}&timestamp=gt.${time}&event=is.null&event_type=eq.${encodeURIComponent(
        event
      )}`
      try {
        const response = await xcpemReadOnlySec.get<[]>(endpoint)
        // const response = await xcpemReadOnlySec.get<[]>(
        //   '/webapp_dashboard_admin_log?gw_uid=eq.gw760f86604bf6c004dbeb841c645ca198&timestamp=gt.2022-11-21T04:19:09.071&event=eq.Admin GW DE-PROVISIONED&limit=10&offset=0'
        // )
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`device-control-admin-log: ${error?.response?.data?.message || error?.message}`)
        }
        // throw new Error('Failed to fetch admin logs')
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, isSuccess, refetch }
}
export const useDeviceControlActivityLogs = (
  currentPage: any,
  activeGw_Uid: string,
  limit?: any,
  time?: string,
  event?: string
): {
  data: [] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['device-control-activity-log'],
    async () => {
      const gw_uid = !!activeGw_Uid ? activeGw_Uid : sessionStorage.getItem('gwuid')

      if (gw_uid === null) return []

      const endpoint = `/webapp_dashboard_activity_log?gw_uid=eq.${gw_uid}&limit=${limit}&offset=${currentPage}&timestamp=gt.${time}event=eq.Gateway REBOOTED`
      try {
        const response = await xcpemReadOnlySec.get<[]>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`device-control-activity-log: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
// export const useModalReset = () => {
//   const postRequest = async ({ url, payload }: any) => {
//     try {
//       const response = await xcpemSec.post(`${baseUrl}/rpc/${url}`, payload)
//       return response.data
//     } catch (error: any) {
//       notifyError(`Factory Reset Token: ${error?.response?.data?.message || error?.message}`)
//       throw new Error(error?.response?.data?.message || error?.message)
//     }
//   }

//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const { mutate, isLoading, error } = useMutation(postRequest)

//   return { mutate, isLoading, error }
// }

const usePostModalReset = async ({ url, payload }: any) => {
  const response = await xcpemSec.post(`${baseUrl}/rpc/${url}`, payload)
  return response.data
}

export const useModalReset = () => {
  return useMutation(usePostModalReset)
}
const usePostSendMail = async ({ mailPayload }: any) => {
  // const response = await xcpemSec.post(`${baseUrl}/api/wifi/reset-wifi-password`, mailPayload)
  const response = await axios.post(`${baseUrl}/api/wifi/reset-wifi-password`, mailPayload)
  return response.data
}

export const useSendMail = () => {
  return useMutation(usePostSendMail)
}
export const useResetAccount = () => {
  const postRequest = async ({ url, email, payloadData }: any) => {
    try {
      const response = await xcpemCyber.post(
        `https://${process.env.REACT_APP_CYBER}/api/v1/panel/${url}?email=${encodeURIComponent(email)}`,
        payloadData
      )

      return response.data
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        notifyError(`Factory Reset Token: ${error?.response?.data?.message || error?.message}`)
        throw new Error(error?.response?.data?.message || error?.message)
      }
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(postRequest)

  return { mutate, isLoading, error }
}
