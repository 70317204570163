import React, { FC, useMemo } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Loader from '../../Loader'
import { getColorCode } from '../../../utils/helper'
import moment from 'moment'

interface Props {
  data: any[] | any
}

const TimeSupervisionChart: FC<Props> = ({ data }) => {
  const isValidDateFormat = (dateString: string): boolean => {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/
    return dateFormatRegex.test(dateString)
  }
  const sortedData = !!data
    ? isValidDateFormat(data[0].title)
      ? data.sort((a: any, b: any) => new Date(a.title).getTime() - new Date(b.title).getTime())
      : data
    : []

  const labelY = sortedData?.map((item: any) => +(item.seconds / 3600).toFixed(2) as unknown as number) || []

  const labelX = isValidDateFormat(sortedData[0]?.title)
    ? sortedData?.map((item: any) => moment(item.title).format('DD MMM')) || []
    : sortedData?.map((item: any) => item.title) || []

  // const chartData = {
  //   type: "bar",
  //   labels: labels,
  //   datasets: [
  //     {
  //       data: data,
  //       backgroundColor: ["#6C83FF"],
  //       borderColor: ["#6C83FF"],
  //       borderWidth: 1,
  //       borderRadius: 10,
  //       barThickness: 13,
  //       borderSkipped: false,
  //       barSpacing: 18,
  //     },
  //   ],
  // };

  const options: Highcharts.Options = {
    chart: {
      type: 'column'
    },
    title: {
      text: ''
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.point.category}</b>: ${this.point.y}hr`
      }
    },
    series: [
      {
        // `type: column` is required for type-checking this options as a column series
        showInLegend: false,
        type: 'column',
        data: labelY
      }
    ],
    xAxis: {
      categories: labelX,
      allowDecimals: false,
      labels: {
        step: 1
      }
    },
    yAxis: {
      title: {
        text: 'Hour'
      }
    },
    plotOptions: {
      area: {
        stacking: 'percent',
        lineColor: 'black',
        lineWidth: 2,
        marker: {
          enabled: false
        },
        label: {
          useHTML: false
        }
      },
      column: {
        pointWidth: 12,
        color: getColorCode('chartPurpleBlue')
      }
    }
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default React.memo(TimeSupervisionChart)
