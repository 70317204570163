import { useQuery } from '@tanstack/react-query'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'

export const useGatewayInfo = (
  activeGw_Uid: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const gwuid = !!activeGw_Uid ? activeGw_Uid : sessionStorage.getItem('gwuid')

  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['gateway-info', gwuid],
    async () => {
      if (!gwuid) return
      const endpoint = `/webapp_dashboard_all_devices?gw_uid=eq.${gwuid}`

      try {
        const response = await xcpemSec.get<any[]>(endpoint)
        return response.data[0] || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`All devices: ${error?.response?.data?.message || error?.message}`)
        }
        // throw new Error('Failed to fetch gateway info')
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
