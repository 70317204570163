import axios from 'axios'
import { apiUrl } from '../../app-config'
import { notifyError } from '../../components/Toaster'

export const fetchGatewayNotes = async ({ gwUid }: { gwUid: string }) => {
  const access_token = sessionStorage.getItem('access_token')
  const gwuid = sessionStorage.getItem('gwuid')
  try {
    const response = await axios.get(`${apiUrl}/gateways_support_notes_list?gw_uid=eq.${gwUid}&order=timestamp.desc`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
    return response.data
  } catch (error: any) {
    if (error?.response?.status === 401) {
      return error?.response?.status
      // sessionStorage.setItem('error', 'true')
      //  localStorage.clear()
      //  sessionStorage.clear()
      //  window.location.href = '/#/login'
    } else {
      notifyError(`Support notes list: ${error?.response?.data?.message || error?.message}`)
    }

    return null
  }
}
