import axios from 'axios'
import { apiUrl, readOnlyApiUrl } from '../app-config'

// Create an instance of axios
const xcpemReadOnlySec = axios.create({
  baseURL: apiUrl
})

// Add a request interceptor to set the access_token from sessionStorage before each request
xcpemReadOnlySec.interceptors.request.use(
  config => {
    const access_token = sessionStorage.getItem('access_token')
    if (access_token) {
      config.headers['Authorization'] = `Bearer ${access_token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

xcpemReadOnlySec.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // if (error.response && (error.response.status === 401 )) {
    //   // Clear session storage upon encountering a 401
    //   sessionStorage.setItem('error', 'true')

    //   //   localStorage.clear()
    //   //   sessionStorage.clear()
    //   //  window.location.href = '/#/login'
    //   // You might also want to navigate the user to a login page
    //   // or display an error message.
    // }
    return Promise.reject(error)
  }
)

export default xcpemReadOnlySec
