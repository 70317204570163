import { Switch } from 'antd'
import styles from './styles.module.scss'
import { useState } from 'react'
import SessionTimeOutModal from '../../SessionTimeOutModal'

interface SecurityCardProps {
  icon: React.ReactNode
  title: string
  activeColor: string
  isChecked: boolean
  onChange?: any
}

const SecurityCard = ({ icon, title, activeColor, isChecked, onChange }: SecurityCardProps) => {
  const [isToggled, setIsToggled] = useState(isChecked)

  const handleToggle = (e: any) => {
    // setIsToggled(!isToggled)
    onChange(e, title)
  }

  return (
    <div className={styles.tile_container}>
      <div className={styles.tile}>
        <span
          style={{
            color: isToggled ? activeColor : '#CBCFDE',
            transition: 'color 1000ms ease'
          }}
        >
          {icon}
        </span>
        <span style={{ marginLeft: '20.06px' }}>{title}</span>
        <span className={styles.switch_main}>
          <Switch
            // defaultChecked={isChecked}
            onChange={(e: any) => handleToggle(e)}
            //  onChange={(e: any, title: any) => onChange(e, title)}
            checked={isChecked}
          />
          {/* <label className={styles.switch_label}>
            <input type='checkbox' defaultChecked={isChecked} value='' className={styles.peer} />
            <div className={styles.switch_circle}></div>
          </label> */}
        </span>
      </div>
    </div>
  )
}

export default SecurityCard
