import { Button, Skeleton } from '@mui/material'
import { Edit2, Eye, EyeSlash, InfoCircle, ArrowDown2, ArrowUp2 } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { DatePicker, Modal, Tooltip } from 'antd'
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker'
import moment from 'moment'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import CloseIcon from '@mui/icons-material/Close'
import BreadcrumbsArrowRight from '../../../../common-images/app-logo/ArrowRight'
import Loader from '../../../../components/Loader'
import Pagination from '../../../../components/Pagination'
import TelemetryChart from './telemetry/telemetry_chart'
import { useGatewayIotLogs } from '../../../../hook/useGatewayLogs'
import { pcSettingByGateway } from '../../../../redux/pcsettingbyGateway/pcsettingbyGateway'
import { useNavigate } from 'react-router'
import './deviceTab.scss'
import { ngxCsv } from 'ngx-csv/ngx-csv'
import { useSelector } from 'react-redux'
import { CustomersRootState } from '../../../../redux/customerStatistics/types'
import CachedIcon from '@mui/icons-material/Cached'
import ChangePassword from './ChangePassword'
import SessionTimeOutModal from '../../../../components/SessionTimeOutModal'
import { useSessionContex } from '../../../../SessionContex'
import { getColorCode } from '../../../../utils/helper'

const { RangePicker } = DatePicker

dayjs.extend(utc)

const DeviceTab = ({
  customerinfo,
  handleSubmit,
  onSubmit,
  fetchingRouterSettings,
  handleShowPassword,
  showPassword,
  editSSID,
  routerConfig,
  handleSSIDEditInfo,
  valuesChanged,
  hasErrors,
  register,
  errors,
  inputClassName,
  editPASSWORD,
  handlePasswordEditInfo
}: any) => {
  const navigate = useNavigate()
  const { openModal, setOpenModal } = useSessionContex()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isTelemetryModalOpen, setIsTelemetryModalOpen] = useState(false)
  const [shownLogs, setshownLogs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isCyberLimitFound, setIsCyberLimitFound] = useState(false)
  const [pcLimitCount, setPcLimitCount] = useState(0)
  const [isPCLoading, setIsPCLoading] = useState(0)
  const fromDate = moment().subtract(3, 'hours')
  const tillDate = moment()
  const [dateValue, setDateValue] = useState<any>(null)
  const [topicFilter, setTopicFilter] = useState<any>('')
  const [allLogs, setAllLogs] = useState<any[]>([])
  const [pageLoader, setPageLoader] = useState(true)
  const [refresh, isRefresh] = useState(false)
  const gw_uid = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)

  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const onChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string
  ) => {
    if (value === null) {
      setDateValue(null)
    } else {
      setDateValue(dateString)
    }
  }

  const {
    data: modalData,
    isLoading,
    refetch,
    isFetching
  } = useGatewayIotLogs(
    moment(dateValue === null || dateValue[0] === '' ? fromDate : dateValue[0])
      .utc()
      .format('YYYYMMDDTHH:mm:ss'),

    dateValue === null || dateValue[1] === ''
      ? moment(tillDate).utc().format('YYYYMMDDTHH:mm:ss')
      : moment(dateValue[1]).utc().format('YYYYMMDDTHH:mm:ss'),
    currentPage - 1,
    topicFilter,
    50,
    gw_uid
  )
  useEffect(() => {
    if (modalData === 401) {
      !openModal && setOpenModal(true)
    }
  }, [modalData])

  useEffect(() => {
    // Update all logs when new data is fetched
    if (isModalOpen) {
      if (refresh) {
        setAllLogs(modalData)
        setTimeout(() => {
          setPageLoader(false)
        }, 1000)
      } else {
        currentPage === 1
          ? setAllLogs(modalData)
          : modalData && modalData.length !== 0 && setAllLogs((prevLogs: any) => [...prevLogs, ...modalData])
        setTimeout(() => {
          setPageLoader(false)
        }, 1000)
      }
    } else {
      setAllLogs([])
    }
  }, [modalData, isModalOpen, refresh])

  const handleCancel = () => {
    setDateValue(null)
    setTopicFilter('')
    setIsModalOpen(false)
  }

  const handleTelemetryCancel = () => {
    setIsTelemetryModalOpen(false)
  }

  const togglecomment = (id: any) => {
    setshownLogs((prevshowncomments: any) => ({
      ...prevshowncomments,
      [id]: !prevshowncomments[id]
    }))
  }

  useEffect(() => {
    setIsCyberLimitFound(false)
    setIsPCLoading(0)
    setPcLimitCount(0)
    const getPcSettingByGateway = async () => {
      if (customerinfo) {
        const result = await pcSettingByGateway(customerinfo?.gw_uid)
        let pcLimitcountData = 0
        setIsPCLoading(1)
        setPcLimitCount(0)
        if (result && result.pc_settings) {
          const macAddresses = Object.keys(result.pc_settings)
          for (let index = 0; index < macAddresses.length; index++) {
            const deviceInfo = result.pc_settings[macAddresses[index]]
            if (
              deviceInfo.blocked_categories ||
              deviceInfo.blocked_domains ||
              deviceInfo.blocked_internet ||
              deviceInfo.limited_internet
            ) {
              setIsCyberLimitFound(true)
              pcLimitcountData++
              setPcLimitCount(pcLimitcountData)
            }
          }
        } else {
          setIsPCLoading(2)
        }
      } else {
        setIsPCLoading(2)
      }
    }
    if (customerinfo === 401) {
      !openModal && setOpenModal(true)
    } else {
      getPcSettingByGateway()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerinfo])

  const onPaginatedSearch = (e: any) => {
    setCurrentPage(prevPage => prevPage + 50)
  }

  useEffect(() => {
    // Refetch when FilterData changes

    // setPageLoader(true)
    if (!gw_uid) return
    refetch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, isModalOpen])

  const handleInputChange = (e: any) => {
    setTopicFilter(e.target.value)
  }
  const disabledDate = (current: any) => {
    // Get the current date
    // const today = new Date()

    // Disable dates that are after today
    // return current && current > today
    // console.log('====', current > today)
    // return current > today
    // var oneDay = 1 * 24 * 60 * 60 * 1000
    return current && current.valueOf() > dayjs()
  }

  const downloadLogs = () => {
    const csvLogs = allLogs.map((obj: any) => {
      obj.payload = JSON.stringify(obj.payload)
      return obj
    })

    new ngxCsv(csvLogs, 'Xunison_logs_' + new Date().getTime(), {
      title: 'Xunison',
      headers: ['msgid', 'clientid', 'timestamp', 'qos', 'topic', 'payload']
    })
  }

  return (
    <>
      <div className='border-top'>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Account</label>
          <label className='info-desc-cls'>{customerinfo && customerinfo?.email}</label>
          <label className='info-logo-cls'></label>
        </div>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>First Connection</label>
          <label className='info-desc-cls'>
            {(customerinfo &&
              customerinfo?.user_first_connection_dt &&
              dayjs
                .tz(dayjs.utc(customerinfo?.user_first_connection_dt), timeZone)
                .format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm:ss`)) ||
              '-'}
          </label>
          <label className='info-logo-cls'></label>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='d-flex info-div align-items-center' style={{ padding: '6px 0px 0px 20px' }}>
            <label className='info-name-cls'>SSID</label>
            {fetchingRouterSettings ? (
              <>
                <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
                <label className='info-logo-cls'></label>
              </>
            ) : (
              <>
                {customerinfo?.is_online ? (
                  <label className='info-desc-cls d-flex justify-content-between'>
                    {customerinfo?.dev_mesh_role === 'CAP' ? (
                      <input
                        type='text'
                        disabled={!editSSID}
                        defaultValue={routerConfig?.SSID}
                        {...register('ssid', {
                          required: 'Username is required.',
                          minLength: {
                            value: 1,
                            message: 'SSID must be at least 1 character long.'
                          },
                          maxLength: {
                            value: 32,
                            message: 'SSID must not exceed 32 characters.'
                          },
                          pattern: {
                            value: /^[A-Za-z0-9_-]*$/,
                            message: 'SSID can only contain letters, numbers, underscores, and dashes.'
                          }
                        })}
                        className='ssidinput form-control '
                      />
                    ) : (
                      <label className='info-desc-cls'>{routerConfig?.SSID}</label>
                    )}
                    {customerinfo?.dev_mesh_role === 'CAP' && (
                      <Edit2
                        size={16}
                        onClick={handleSSIDEditInfo}
                        color={editSSID ? '#282828' : '#6B7A9F'}
                        className='mt-2'
                      />
                    )}
                    {valuesChanged && !hasErrors ? (
                      <button type='submit' className='applybtn ms-2'>
                        Apply
                      </button>
                    ) : hasErrors ? (
                      <span
                        data-tooltip-id='alert-type'
                        data-tooltip-html={`<div style='color:#FFFFFF'>${
                          errors?.password?.message || errors?.ssid?.message
                        }</div>`}
                        className=' d-flex  align-items-center ms-1'
                      >
                        <Tooltip
                          title={`This SSID can consist of up to 32 alphanumeric, case-sensitive, characters. The first character cannot be!,#,or: character. he +,],/,”,TAB, and trailing spaces are invalid characters for SSIDs.`}
                        >
                          <InfoCircle size='18' color='#FF8A65' />
                        </Tooltip>
                      </span>
                    ) : null}
                  </label>
                ) : (
                  <label className='info-desc-cls py-2'>NA</label>
                )}
              </>
            )}
          </div>

          <div className='d-flex info-div align-items-center' style={{ padding: '6px 0px 0px 20px' }}>
            <label className='info-name-cls'>Password</label>
            {fetchingRouterSettings ? (
              <>
                <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
                <label className='info-logo-cls'></label>
              </>
            ) : (
              <>
                {customerinfo?.is_online ? (
                  <label className='info-desc-cls d-flex justify-content-between position-relative'>
                    {customerinfo?.dev_mesh_role === 'CAP' ? (
                      <>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          // disabled={!editPASSWORD}
                          disabled
                          className='ssidinput form-control'
                          defaultValue={routerConfig?.PASSWORD}
                          {...register('password', {
                            required: 'Password is required.',
                            minLength: {
                              value: 8,
                              message: 'Password must be at least 8 characters long.'
                            },
                            maxLength: {
                              value: 63,
                              message: 'Password must not exceed 63 characters.'
                            }
                          })}
                        />

                        {/* <div className='iconContainer'>
                          {!showPassword ? (
                            <Eye size='16' color='#6B7A9F' onClick={handleShowPassword} />
                          ) : (
                            <EyeSlash size='16' color='#6B7A9F' onClick={handleShowPassword} />
                          )}
                        </div> */}
                      </>
                    ) : (
                      <>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          disabled={true}
                          className={`${inputClassName} form-control`}
                          defaultValue={routerConfig?.PASSWORD}
                        />
                        {/* <div className='iconContainer'>
                          {!showPassword ? (
                            <Eye size='16' color='#6B7A9F' className='mt-2' onClick={handleShowPassword} />
                          ) : (
                            <EyeSlash size='16' color='#6B7A9F' className='mt-2' onClick={handleShowPassword} />
                          )}
                        </div> */}
                      </>
                    )}
                    {customerinfo?.dev_mesh_role === 'CAP' && (
                      <Edit2
                        size={16}
                        color={editPASSWORD ? '#282828' : '#6B7A9F'}
                        className='mt-2'
                        onClick={handlePasswordEditInfo}
                      />
                    )}
                  </label>
                ) : (
                  <label className='info-desc-cls py-2'>NA</label>
                )}
              </>
            )}
          </div>
        </form>
        {editPASSWORD && (
          <ChangePassword
            isOpen={editPASSWORD}
            onClose={handlePasswordEditInfo}
            onSubmit={onSubmit}
            title={'Reset Password'}
            passwordValue={routerConfig?.PASSWORD}
            valuesChanged={valuesChanged}
          />
        )}
        {process.env.REACT_APP_MODE !== 'hitron' && (
          <div className='d-flex info-div'>
            <label className='info-name-cls'>OTT</label>
            <label className='info-desc-cls'>{customerinfo?.ott_serial ? customerinfo.ott_serial : '--'}</label>
            <label></label>
          </div>
        )}
        {/* <div className='d-flex info-div'>
          <label className='info-name-cls'>Serial Number</label>
          <label className='info-desc-cls'>{customerinfo?.gw_uid.replace(/[^x]/g, 'x')}</label>
          <label></label>
        </div> */}
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Device</label>
          <label className='info-desc-cls'>{customerinfo?.model}</label>
          <label></label>
        </div>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Gateway UID</label>
          <label className='info-desc-cls'>{customerinfo?.gw_uid}</label>
          <label></label>
        </div>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>5G</label>
          <label className='info-desc-cls'>
            {customerinfo ? (
              process.env.REACT_APP_MODE !== 'hitron' ? (
                (customerinfo?.model === 'D50' || customerinfo?.model === 'D60') &&
                customerinfo?.lte_module !== 'Not installed' ? (
                  'YES'
                ) : (
                  'NO'
                )
              ) : customerinfo?.lte_module === '' || customerinfo?.lte_module === 'Not installed' ? (
                'NO'
              ) : (
                'YES'
              )
            ) : (
              <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
            )}
          </label>
          <label></label>
        </div>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>WAN MAC</label>
          <label className='info-desc-cls'>{customerinfo?.wan_mac}</label>
          <label></label>
        </div>
        {process.env.REACT_APP_MODE !== 'hitron' && (
          <div className='d-flex info-div'>
            <label className='info-name-cls'>OTT MAC</label>
            <label className='info-desc-cls'>--</label>
            <label></label>
          </div>
        )}
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Mesh Role</label>
          {/* {fetchingRouterSettings ? (
            <>
              <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
              <label></label>
            </>
          ) : ( */}
          <>
            <label className='info-desc-cls'>
              {
                // routerConfig
                //   ? routerConfig?.MESH_ROLE === 'CAP'
                //     ? 'Master'
                //     : 'Satellite'
                //   :
                customerinfo?.dev_mesh_role === 'CAP' ? 'Master' : 'Satellite'
              }
            </label>
            <label></label>
          </>
          {/* )} */}
        </div>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Gateway Logs</label>
          <label className='info-desc-cls'>Available</label>
          <label className='info-logo-cls'>
            <Eye
              size='16'
              color='#6B7A9F'
              className='cursor-pointer'
              onClick={() => {
                setAllLogs([])
                setIsModalOpen(true)
              }}
            />
          </label>
        </div>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Telemetry</label>
          <label className='info-desc-cls'>Available</label>
          <label className='info-logo-cls'>
            <Eye
              size='16'
              color='#6B7A9F'
              className='cursor-pointer'
              onClick={() => {
                setIsTelemetryModalOpen(true)
              }}
            />
          </label>
        </div>
        <div className='d-flex info-div'>
          <label className='info-name-cls'>Parental Control</label>
          {fetchingRouterSettings ? (
            <>
              <Skeleton variant='text' className='info-desc-cls' animation='wave' width={200} height={18} />
              <label></label>
            </>
          ) : isCyberLimitFound ? (
            <>
              <label className='info-desc-cls'>Active ({pcLimitCount} Devices)</label>
              <label
                className='info-logo-cls'
                onClick={() => navigate(`/dashboard/${customerinfo?.gw_uid}?email=${customerinfo?.email}`)}
              >
                <BreadcrumbsArrowRight />
              </label>
            </>
          ) : (
            (isPCLoading === 1 && <label className='info-desc-cls'>Not Active</label>) ||
            (isPCLoading === 0 && <i className='fa fa-refresh fa-spin fs-18'></i>) ||
            (isPCLoading === 2 && <label className='info-desc-cls'>Unknown</label>)
          )}
        </div>
      </div>

      <Modal
        open={isModalOpen}
        footer={null}
        closable={false}
        width={800}
        wrapClassName={'authorize_gateway_wrapper'}
        centered={true}
      >
        <div className='authorize_gateway_body_wrapper device-tab'>
          <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
            <h3>
              Gateway Logs{' '}
              <CachedIcon
                className='ml-3'
                onClick={() => {
                  // setPageLoader(true)
                  isRefresh(true)
                  if (!gw_uid) return
                  refetch()
                  setTimeout(() => {
                    isRefresh(false)
                  }, 1000)
                }}
              />
            </h3>
            <CloseIcon
              className='cursor-pointer'
              onClick={() => {
                handleCancel()
              }}
            />
          </div>
          <div className='d-flex align-center flex-wrap row-gap-1 mb-3'>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
            <input type='text' value={topicFilter} onChange={handleInputChange} placeholder='Topic Filter' />
            <RangePicker
              // defaultValue={[dayjs().subtract(3, 'hour'), dayjs()]}
              showTime={{ format: 'HH:mm A' }}
              format={`${process.env.REACT_APP_DATE_FORMAT} HH:mm`}
              onChange={onChange}
              disabledDate={disabledDate}
              value={dateValue !== null ? [dayjs(dateValue[0]), dayjs(dateValue[1])] : dateValue}
            />
            <Button
              type='submit'
              className={`ms-1 ${process.env.REACT_APP_MODE === 'hitron' ? 'submit-btn-hitron' : 'submit-btn'}`}
              onClick={async () => {
                // await setAllLogs([])
                // setPageLoader(true)
                await setCurrentPage(1)
                if (!gw_uid) return
                refetch()
              }}
            >
              Apply
            </Button>
            <Button
              onClick={downloadLogs}
              className='ms-1 download-btn'
              sx={{
                padding: '5px 16px',
                color: getColorCode('redToYellow'),
                border: `1px solid ${getColorCode('redToYellow')} !important`,
                borderRadius: '5px'
              }}
            >
              Download Log File
            </Button>
            {/* </LocalizationProvider> */}
          </div>
          <div className='authorize_gateway_body logs_antd'>
            {isFetching ? (
              <Loader />
            ) : allLogs?.length !== 0 ? (
              allLogs?.map((activity: any, index: any) => (
                <div key={index} className='log-details-cls pt-2 pe-2 ps-3 pb-2 mb-2 align-items-center'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-grid'>
                      <label>
                        <span className='logs-header-cls'>Time: </span>{' '}
                        {moment
                          .utc(activity.timestamp)
                          .local()
                          .format(`${process.env.REACT_APP_DATE_FORMAT}, h:mm:ss A`)}
                        (msgid:
                        {activity?.msgid})
                      </label>
                      <label>
                        <span className='logs-header-cls'>Client Id: </span>
                        {activity?.clientid}
                      </label>
                      <label>
                        <span className='logs-header-cls'>Topic: </span>
                        {activity?.topic}
                      </label>
                    </div>
                    <div>
                      <span onClick={() => togglecomment(activity.msgid)}>
                        {shownLogs[activity.msgid] ? (
                          <ArrowUp2 size='20' color='#e84e6a' />
                        ) : (
                          <ArrowDown2 size='20' color='#1abc9c' />
                        )}
                      </span>
                    </div>
                  </div>
                  {shownLogs[activity.msgid] && (
                    <span className='pre-text-cls'>{JSON.stringify(activity?.payload, null, 2)}</span>
                  )}
                </div>
              ))
            ) : (
              <div className='no-data-found text-center pt-4' style={{ color: getColorCode('redToBlue') }}>
                No data found
              </div>
            )}
            {!isFetching && modalData?.length > 0 && allLogs?.length > 0 && (
              <div className='text-center justify-center d-flex'>
                <button type='button' className='load_more_btn' onClick={onPaginatedSearch}>
                  Load More
                  <span className='ml-2'>
                    <ArrowDown2 size='15' color='#0070f0' />
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>

      {/* Telemetry history */}
      <Modal
        open={isTelemetryModalOpen}
        footer={null}
        closable={false}
        width={1000}
        wrapClassName={'authorize_gateway_wrapper'}
        centered={true}
      >
        <div className='authorize_gateway_body_wrapper telemetry-chart-cls'>
          {/* <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3'>
            <h3>
              Telemetry History <CachedIcon className='ml-3' onClick={() => setRefresh(true)} />
            </h3>
            <CloseIcon
              className='cursor-pointer'
              onClick={() => {
                handleTelemetryCancel()
              }}
            />
          </div> */}
          <div>
            <TelemetryChart handleTelemetryCancel={handleTelemetryCancel} />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DeviceTab
