import React, { FC } from 'react'
import * as Slider from '@radix-ui/react-slider'
import './styles.scss'
import { getColorCode } from '../../utils/helper'

interface SliderProps {
  defaultValue: number
  maxValue: number
  stepSize: number
  onChange: (value: number) => void
}

const SliderInput: FC<SliderProps> = ({ defaultValue, maxValue, stepSize, onChange }) => {
  const handleSliderChange = (values: number[]) => {
    onChange(values[0])
  }

  return (
    <form>
      <Slider.Root
        className='SliderRoot'
        defaultValue={[defaultValue]}
        max={maxValue}
        step={stepSize}
        // onValueChange={handleSliderChange} // Use the onValueChange event
        onValueCommit={handleSliderChange}
      >
        <Slider.Track className='SliderTrack'>
          <Slider.Range className='SliderRange' style={{ backgroundColor: getColorCode('infoBlue') }} />
        </Slider.Track>
        <Slider.Thumb className='SliderThumb' aria-label='Volume' />
      </Slider.Root>
    </form>
  )
}

export default SliderInput
