// import moment from 'moment/moment'
import moment from 'moment'
import dayjs from 'dayjs'
import Loader from '../../../components/Loader'
import TablePagination from '@mui/material/TablePagination'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDeviceControlFailLogs, useDeviceControlLogs } from '../../../hook/device-control'
import { useSessionContex } from '../../../SessionContex'
import { getColorCode } from '../../../utils/helper'
const TableData = ({ headerTable, customerinfo, selected, leftArray, dataFilter, totalCount, recallApi }: any) => {
  // const [isLoading, setIsLoading] = useState(true)
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const { openModal, setOpenModal } = useSessionContex()
  const chartfromDate = dayjs.tz(dayjs.utc().subtract(dataFilter, 'hours'), timeZone).format('YYYYMMDDTHH:mm:ss')
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [successData, setSuccessData] = useState([])
  const [failData, setFailData] = useState([])

  const {
    data: logData,
    isFetching: logIsFetching,
    refetch: logRefetch,
    isSuccess
  }: any = useDeviceControlLogs(
    page * limit,
    customerinfo?.gw_uid,
    limit,
    chartfromDate,
    leftArray[selected].eventForAPI
  )
  const {
    data: logFailData,
    isFetching: logFailIsFetching,
    refetch: logFailRefetch,
    isSuccess: failSucess
  }: any = useDeviceControlFailLogs(
    page * limit,
    customerinfo?.gw_uid,
    limit,
    // dayjs.tz(dayjs.utc(chartfromDate).subtract(22, 'hours'), timeZone).format('YYYYMMDDTHH:mm:ss'),
    chartfromDate,
    leftArray[selected].eventForAPI
  )

  useEffect(() => {
    logRefetch()
    logFailRefetch()
  }, [])

  useEffect(() => {
    if (recallApi) {
      logRefetch()
      logFailRefetch()
    }
  }, [recallApi])

  useEffect(() => {
    if (logData === 401) {
      !openModal && setOpenModal(true)
    }
    if (isSuccess) {
      setSuccessData(logData)
    } else {
      setSuccessData([])
    }
  }, [logData, isSuccess])

  useEffect(() => {
    if (logFailData === 401) {
      !openModal && setOpenModal(true)
    }
    if (failSucess) {
      setFailData(logFailData)
    } else {
      setFailData([])
    }
  }, [logFailData, failSucess])
  useEffect(() => {
    logRefetch()
    logFailRefetch()
  }, [page, limit, customerinfo])
  useEffect(() => {
    if (page === 0 || limit === 10) {
      logRefetch()
      logFailRefetch()
    } else {
      setPage(0)
      setLimit(10)
    }
  }, [selected, dataFilter])
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value))
    setPage(0)
  }

  return (
    <div className='w-full'>
      {logIsFetching || logFailIsFetching ? (
        <div className='d-flex w-full justify-center aline-center'>
          <Loader />
        </div>
      ) : (
        <table className='w-full mt-5'>
          <thead>
            <tr>
              {headerTable?.map((obj: any, key: any) => {
                return <td key={key}>{obj}</td>
              })}
            </tr>
          </thead>
          <tbody className='w-full'>
            {successData?.length > 0 ? (
              <>
                {successData?.map((item: any, index: any) => {
                  const status =
                    failData && failData.length !== 0 && failData.find((obj: any) => obj.callid === item.callid)
                  return (
                    <tr key={`admin_logs${index}`}>
                      <td>
                        {
                          moment.utc(item?.timestamp).tz(timeZone).format(`${process.env.REACT_APP_DATE_FORMAT} - LT`)
                          // moment
                          //   .tz(moment(item?.timestamp), timeZone)
                          //   .format(` ${process.env.REACT_APP_DATE_FORMAT} - LT`)
                        }
                      </td>
                      <td>{item?.event}</td>
                      <td>{item?.email}</td>
                      <td>{customerinfo?.model}</td>
                      <td>{item?.gw_uid}</td>
                      <td style={{ color: `${status ? getColorCode('redToRed') : getColorCode('Online')}` }}>
                        {status ? 'Failed' : 'Success'}
                      </td>
                    </tr>
                  )
                })}
              </>
            ) : (
              <tr className='empty-row'>
                <td colSpan={6} style={{ textAlign: 'center', color: getColorCode('redToBlue') }}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {successData && successData?.length > 0 && (
        <TablePagination
          className='customers_footer'
          sx={{ marginRight: 0 }}
          rowsPerPageOptions={[6, 10, 20, 50]}
          component='div'
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}
export default TableData
