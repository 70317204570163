import Box from '@mui/material/Box'

import './styles.scss'
import { ShieldTick, Shield, ShieldSecurity, ShieldSearch } from 'iconsax-react'
import { convertScannedTrafficToReadableUnits, getColorCode } from '../../../utils/helper'
import SecurityScore from '../security-score'
import SecurityCard from '../security-card'
import TrafficScanned from '../traffic-scanned'
import ThreatsBlocked from '../threats-blocked'
import {
  useCyberStatusForAdBlock,
  useCyberStatusForSafeSearch,
  useCyberStatusForUser,
  useCyberStatusForUserOff,
  useRouterStats
} from '../../../hook/cyber-security/useCyberSecurity'
import { FC, useEffect } from 'react'
import Loader from '../../Loader/index'
import { Grid } from '@mui/material'
import { notifyError } from '../../Toaster'
import { useSessionContex } from '../../../SessionContex'

interface Props {
  email: string
  mac: string
}

const SecurityOverview: FC<Props> = ({ email, mac }) => {
  const { data: onData, isLoading: onIsLoading, refetch: refetchON } = useCyberStatusForUser(email)
  // const { mutate }: any = useCyberStatusForUserOff()
  const { openModal, setOpenModal } = useSessionContex()

  const { mutate: OffMutate } = useCyberStatusForUserOff()
  const { mutate: adBlockMutate } = useCyberStatusForAdBlock()
  const { mutate: safeSearchMutate } = useCyberStatusForSafeSearch()

  const {
    data: routerStats,
    isLoading: RouterStatsFetching,
    refetch: routerStatsRefeching
  } = useRouterStats(email, mac)
  useEffect(() => {
    routerStatsRefeching()
  }, [])
  // console.log('==================', onData)
  useEffect(() => {
    if (onData === 401) {
      !openModal && setOpenModal(true)
    }
  }, [onData])
  useEffect(() => {
    if (routerStats === 401) {
      !openModal && setOpenModal(true)
    }
  }, [routerStats])
  const onFamilyShiel = async (checked: any, title: any) => {
    if (title === 'Family Shield') {
      if (!checked) {
        await OffMutate(email)
        setTimeout(() => {
          refetchON()
        }, 1000)
      } else {
        notifyError('Enabling access denied')
      }
    }
    if (title === 'Ad Blocker / Phishing Protection') {
      if (!checked) {
        await adBlockMutate(email)
        setTimeout(() => {
          refetchON()
        }, 1000)
      } else {
        notifyError('Enabling access denied')
      }
    }
    if (title === 'Safe Search') {
      if (!checked) {
        await safeSearchMutate(email)
        setTimeout(() => {
          refetchON()
        }, 1000)
      } else {
        notifyError('Enabling access denied')
      }
    }
  }
  return (
    <div style={{ width: '100%' }} className='overflow-y-auto pb-20 security_overview_wrapper'>
      <Box className={'security_overview'}>
        <p className={'title'}>Security Overview</p>

        {/* <div className="grid grid-cols-1 md:grid-cols-4 gap-x-[32px] flex-wrap"> */}
        <div className='grid grid-cols-1 md:grid-cols-4 gap-x-[32px] flex-wrap security_overview_main_block'>
          {!onIsLoading && onData ? (
            <>
              {/* <SecurityCard
                icon={<ShieldTick size={41.66} />}
                title={'Security System'}
                activeColor={getColorCode('Closed')}
                isChecked={(data?.safe_search && data?.adblock && data?.family_shield) || false}
              /> */}

              <SecurityCard
                icon={<Shield size={41.66} />}
                title={'Family Shield'}
                activeColor={getColorCode('Info')}
                isChecked={onData?.family_shield}
                onChange={onIsLoading ? () => null : onFamilyShiel}
              />

              <SecurityCard
                icon={<ShieldSecurity size={41.66} />}
                title={'Ad Blocker / Phishing Protection'}
                activeColor={getColorCode('Info')}
                isChecked={onData?.adblock}
                onChange={onIsLoading ? () => null : onFamilyShiel}
              />

              <SecurityCard
                icon={<ShieldSearch size={41.66} />}
                title={'Safe Search'}
                activeColor={getColorCode('Info')}
                isChecked={onData?.safe_search}
                onChange={onIsLoading ? () => null : onFamilyShiel}
              />
            </>
          ) : (
            <Box marginLeft={2.5} paddingBottom={4} color={getColorCode('redToBlue')}>
              Status data not found
            </Box>
          )}
        </div>
        {!!RouterStatsFetching && <Loader />}
      </Box>

      <Grid container spacing={2} className='security_overview_inner'>
        {routerStats ? (
          <>
            <Grid item style={{ paddingLeft: '0' }} xs={6} md={4}>
              <SecurityScore
                title={'Security Score'}
                score={routerStats?.stats?.security_score || 0}
                score_suggestions={routerStats?.stats?.score_suggestions as any}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TrafficScanned
                trafficScanned={convertScannedTrafficToReadableUnits(routerStats?.stats?.total_traffic_scanned || 0)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <ThreatsBlocked email={email} mac={mac} count={routerStats?.stats} />
            </Grid>
          </>
        ) : (
          <Box marginLeft={2.5} paddingBottom={4} color={getColorCode('redToBlue')}>
            Router Stats data not found
          </Box>
        )}
      </Grid>
    </div>
  )
}

export default SecurityOverview
