import React, { useState } from 'react'
import { LoginCurve } from 'iconsax-react'
import { Modal } from 'antd'
import { useNavigate } from 'react-router'
import { useAuth } from '../../../contexts/authContext'
import { getColorCode } from '../../../utils/helper'

const LogOutBtn = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()
  const { logout } = useAuth()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handlelogout = () => {
    logout()
    navigate('/login')
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <div
        className={`log_out_btn${process.env.REACT_APP_MODE === 'hitron' ? '_hitron' : ''}`}
        onClick={showModal}
        style={{
          color: getColorCode('redToBlue'),
          border: `2px solid ${getColorCode('redToBlue')}`
        }}
      >
        <LoginCurve size={28} />
      </div>
      <Modal
        open={isModalOpen}
        footer={null}
        closable={false}
        width={600}
        wrapClassName={'log_out_wrapper'}
        centered={true}
      >
        <div className=''>
          <div className='log_out_title'>
            <h3>Logout</h3>
          </div>
          <div className='log_out_body_wrapper'>
            <div className='log_out_body'>
              <h3 className='conformation_text'>
                Have you successfully resolved the customer’s issue and addressed the pending ticket?
              </h3>
              <p className='commit_text'>Afterward, please proceed to log out.</p>
            </div>
            <div className='log_out_footer'>
              <button
                type='button'
                onClick={handleCancel}
                className='cancel_btn'
                style={{
                  color: getColorCode('cancelBtnText'),
                  background: getColorCode('cancelBtnBg'),
                  border: `2px solid ${getColorCode('cancelBtnText')}`
                }}
              >
                Cancel
              </button>
              <button
                type='button'
                className='logout_btn'
                onClick={handlelogout}
                style={{
                  color: getColorCode('logOutBtnText'),
                  background: getColorCode('logOutBtnBg'),
                  border: `2px solid ${getColorCode('logOutBtnText')}`
                }}
              >
                Logout Anyway
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default LogOutBtn
