import React from 'react'

const ServiceImage = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_59_360)">
      <path d="M21.7789 11.0691L17.2172 15.5158L18.2945 21.7963C18.3413 22.071 18.2285 22.3485 18.003 22.5126C17.8756 22.6056 17.724 22.6524 17.5724 22.6524C17.4559 22.6524 17.3388 22.6246 17.2318 22.5682L11.5914 19.603L5.95164 22.5675C5.70557 22.6979 5.40604 22.6766 5.18048 22.5118C4.95492 22.3478 4.84214 22.0702 4.88901 21.7956L5.96628 15.5151L1.40382 11.0691C1.20462 10.8743 1.13212 10.5828 1.21854 10.3184C1.30495 10.054 1.53417 9.85997 1.81027 9.81969L8.11496 8.90427L10.9345 3.19057C11.1813 2.69038 12.0015 2.69038 12.2483 3.19057L15.0678 8.90427L21.3725 9.81969C21.6486 9.85997 21.8778 10.0533 21.9642 10.3184C22.0506 10.5835 21.9781 10.8735 21.7789 11.0691Z" fill="#636363"/>
      </g>
      <defs>
      <clipPath id="clip0_59_360">
      <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
    </svg>
  )
}
export default ServiceImage
