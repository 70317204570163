import { useForm } from 'react-hook-form'
import { usePinVerification } from '../../../hook/parental-control/useParental'
import { FC, useState } from 'react'
import './styles.scss'
import { Formik } from 'formik'
import { Button, Snackbar } from '@mui/material'
import { SnackBarProps } from '../../Device-table/Provisioning'
import { getColorCode } from '../../../utils/helper'
import { useSessionContex } from '../../../SessionContex'
import MuiAlert from '@mui/material/Alert'
import * as Yup from 'yup'

interface Props {
  onPcVerified: () => void
}
function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}
const VerifyPin: FC<Props> = ({ onPcVerified }) => {
  const { openModal, setOpenModal } = useSessionContex()

  // const {
  //   register,
  //   handleSubmit,
  //   setError,
  //   getValues,
  //   formState: { errors }
  // } = useForm()
  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed',
    severity: 'success'
  })
  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const verifyPinMutation = usePinVerification()

  const resetPasswordSchema = Yup.object().shape({
    pincode: Yup.string()
      .required('Please enter pin')
      .matches(/^[0-9]+$/, 'Please enter numerical value')
  })
  // const onSubmit = async (data: any) => {
  //   setIsSubmitting(true)
  //   verifyPinMutation.mutate(data, {
  //     onSuccess: (data: any) => {
  //       sessionStorage.setItem('isPCverified', 'true')
  //       onPcVerified()
  //       setIsSubmitting(false)
  //     },
  //     onError: (error: any) => {
  //       setIsSubmitting(false)
  //       setError('pincode', {
  //         type: 'manual',
  //         message: 'invalid pin code'
  //       })
  //     }
  //   })
  // }

  // const pincodeValue = getValues('pincode')
  // const isButtonDisabled = pincodeValue?.length > 6
  // console.log('=========', isButtonDisabled)
  return (
    <div className='mt-[128px] flex items-center justify-center font-sf-medium verifypin-wrapper'>
      <div className='p-4 shadow-lg rounded-md min-w-[389px] bg-white verifypin-wrapper-main'>
        {/* <form onSubmit={onSubmit}>
          <div className='py-3 verifypin-form-inner'>
            <h1>Verify PIN</h1>
            <input
              type='password'
              className='p-3 rounded-[10px] w-full border-[1px] border-layout-neutral mt-4 verifypin-form-input'
              placeholder='Please enter your PIN'
              {...register('pincode', {
                required: 'please enter your pincode',
                maxLength: {
                  value: 6,
                  message: 'You have to enter 6 digits'
                }
              })}
              maxLength={6}
              minLength={6}
             
            />
            {errors.pincode && (
              <span className='text-alerts-critical errorText'>{errors.pincode.message as string}</span>
            )}
          </div>

          <div className='verify-btn'>
            <button
              type='submit'
              // disabled={isButtonDisabled}
              disabled
              className='px-4 py-2 rounded-[10px] bg-alerts-closed hover:shadow-lg  text-white mt-6 w-full button '
            >
              Verify
            </button>
          </div>
        </form> */}
        <Formik
          initialValues={{ pincode: '' }}
          validationSchema={resetPasswordSchema}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true)
            verifyPinMutation.mutate(values, {
              onSuccess: (data: any) => {
                sessionStorage.setItem('isPCverified', 'true')
                onPcVerified()
                actions.setSubmitting(false)
              },
              onError: (error: any) => {
                actions.setSubmitting(false)
                if (error.response.status === 401) {
                  !openModal && setOpenModal(true)
                } else {
                  setSnackBarInfo({
                    isOpen: true,
                    message: 'Invalid pin code',
                    type: 'redToRed',
                    severity: 'error'
                  })
                }
              }
            })
          }}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <div className='py-3 verifypin-form-inner'>
                <h1>Verify PIN</h1>
                <input
                  type='password'
                  className='p-3 rounded-[10px] w-full border-[1px] border-layout-neutral mt-4 verifypin-form-input'
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.pincode}
                  name='pincode'
                  placeholder='Please enter verify pin'
                  maxLength={6}
                  minLength={6}
                />
                {props.errors.pincode && <span className='text-alerts-critical errorText'>{props.errors.pincode}</span>}
              </div>
              <div className='verify-btn'>
                <Button
                  type='submit'
                  disabled={props.values.pincode?.length < 6}
                  className={`px-4 py-2 rounded-[10px] hover:shadow-lg  text-white mt-6 w-full button ${
                    props.values.pincode?.length < 6 ? 'cursor-default' : 'cursor-pointer'
                  } `}
                  sx={{
                    background: `${getColorCode('greenBlue')}`,
                    '&:hover': {
                      backgroundColor: `${getColorCode('greenBlue')}`
                    }
                  }}
                >
                  Verify
                </Button>
              </div>
            </form>
          )}
        </Formik>
        {snackBarInfo.isOpen && (
          <Snackbar
            open={snackBarInfo.isOpen}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={3000}
            onClose={() =>
              setSnackBarInfo({
                isOpen: false,
                message: snackBarInfo.message,
                type: snackBarInfo.type,
                severity: 'success'
              })
            }
            sx={{ backgroundColor: 'transparent' }}
          >
            <div>
              <Alert
                severity={snackBarInfo.severity}
                sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
              >
                {snackBarInfo.message}
              </Alert>
            </div>
          </Snackbar>
        )}
      </div>
    </div>
  )
}

export default VerifyPin
