import React from "react";

interface InternetConnectionProps {
  width?: string;
  height?: string;
}

const InternetConnection: React.FC<InternetConnectionProps> = ({width, height}) => {
  return (
    <svg width={width ? width : '18px'} height={height ? height : '19px'} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 0.5C4.04441 0.5 0 4.54469 0 9.5C0 14.4556 4.04469 18.5 9 18.5C13.9556 18.5 18 14.4553 18 9.5C18 4.54441 13.9553 0.5 9 0.5ZM6.3154 2.02191C5.69791 2.80699 5.24918 3.77762 4.93868 4.71875H2.65855C3.58186 3.49721 4.84928 2.54986 6.3154 2.02191ZM1.98464 5.76992H4.64027C4.4017 6.76966 4.26087 7.85493 4.22705 8.97266H1.07262C1.14834 7.8228 1.46964 6.73556 1.98464 5.76992ZM1.98464 13.2266C1.46964 12.2609 1.14834 11.1772 1.07262 10.0273H4.22705C4.26087 11.1451 4.4017 12.2268 4.64027 13.2266H1.98464ZM2.65855 14.2812H4.93864C5.24907 15.2221 5.69781 16.1929 6.3154 16.9781C4.84928 16.4501 3.58186 15.5028 2.65855 14.2812ZM8.47266 17.3558C7.29524 16.96 6.48345 15.4484 6.05468 14.2812H8.47266V17.3558ZM8.47266 13.2266H5.72643C5.47014 12.241 5.31826 11.1564 5.28216 10.0273H8.47266V13.2266ZM8.47266 8.97266H5.28216C5.3183 7.84358 5.47017 6.75904 5.72643 5.77344H8.47266V8.97266ZM8.47266 4.71875H6.05468C6.48341 3.5516 7.29524 2.03998 8.47266 1.6442V4.71875ZM16.0154 5.77344C16.5304 6.73907 16.8517 7.8228 16.9274 8.97266H13.773C13.7391 7.85493 13.5983 6.77318 13.3597 5.77344H16.0154ZM15.3414 4.71875H13.0614C12.7509 3.77786 12.3022 2.80709 11.6846 2.02191C13.1507 2.54986 14.4181 3.49721 15.3414 4.71875ZM9.52734 1.6442C10.7048 2.03998 11.5166 3.55156 11.9453 4.71875H9.52734V1.6442ZM9.52734 5.77344H12.2736C12.5299 6.75904 12.6817 7.84358 12.7178 8.97266H9.52734V5.77344ZM9.52734 10.0238H12.7178C12.6817 11.1529 12.5298 12.241 12.2736 13.2266H9.52734V10.0238ZM9.52734 17.3558V14.2777H11.9453C11.5166 15.4449 10.7048 16.96 9.52734 17.3558ZM11.6846 16.9781C12.3021 16.193 12.7508 15.2188 13.0614 14.2777H15.3414C14.4181 15.4993 13.1507 16.4501 11.6846 16.9781ZM16.0154 13.2266H13.3597C13.5983 12.2268 13.7391 11.1416 13.773 10.0238H16.9274C16.8517 11.1737 16.5304 12.2609 16.0154 13.2266Z" fill="black"/>
    </svg>
  );
};

export default InternetConnection;
