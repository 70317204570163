import React, { createContext, useState, ReactNode } from 'react'

interface Breadcrumb {
  label: string
  href: string
}

interface BreadcrumbContextType {
  breadcrumbData: Breadcrumb[]
  updateBreadcrumbData: (newBreadcrumbData: Breadcrumb[]) => void
  filters: BreadcrumbFilter[] | null
  updateFilters: (newFilters: BreadcrumbFilter[]) => void
}

export const BreadcrumbContext = createContext<BreadcrumbContextType>({
  breadcrumbData: [
    {
      label: 'Home',
      href: '/'
    }
  ],
  updateBreadcrumbData: () => {},
  filters: [],
  updateFilters: () => {}
})

interface BreadcrumbProviderProps {
  children: ReactNode
}

interface BreadcrumbFilter {
  label: string
  filterKey: string
  options: string[]
  onChange?: (deviceId: number) => void
  useCache?: boolean
}

export const BreadcrumbProvider = ({ children }: BreadcrumbProviderProps) => {
  const [breadcrumbData, setBreadcrumbData] = useState<Breadcrumb[]>([
    {
      label: 'Home',
      href: '/'
    }
  ])

  const updateBreadcrumbData = (newBreadcrumbData: Breadcrumb[]) => {
    setBreadcrumbData(newBreadcrumbData)
  }

  const [filters, setFilters] = useState<BreadcrumbFilter[]>([])

  const updateFilters = (newFilters: BreadcrumbFilter[]) => {
    setFilters(newFilters)
  }

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbData, updateBreadcrumbData, filters, updateFilters }}>
      {children}
    </BreadcrumbContext.Provider>
  )
}
