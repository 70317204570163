import { ArrowDown, ArrowDown2, ArrowUp2 } from 'iconsax-react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from './dropdown-menu'
import { transformToCamelCase } from '../../../utils/helper'
import DownSideArrow from '../../../common-images/app-logo/downside-arrow'

interface DropDownProps {
  disable?: any
  options: any
  filterKey: string
  label?: string
  onClick?: any
  activeOption?: any
  tags?: any
  preventActive?: boolean
}

const DropDown = ({
  disable,
  options,
  filterKey,
  label,
  onClick = () => {},
  activeOption,
  tags,
  preventActive = false
}: DropDownProps) => {
  const navigate = useNavigate()
  const [activeValue, setActiveValue] = useState<string>('')

  useEffect(() => {
    setActiveValue(label || activeOption.label)
  }, [label || activeOption])

  const handleClick = (val: any) => {
    onClick(filterKey, val)
    setActiveValue(val.label)
    updateStatusQueryParam(val)
  }

  const updateStatusQueryParam = (newStatus: any) => {
    navigate(`/dashboard/${newStatus.Id}`)
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className='dropdown-btn flex items-center' disabled={disable}>
        {activeValue}
        <span className='down-arrow'>
          <DownSideArrow />
        </span>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='drop-down' aria-disabled={disable}>
        {options.map((option: any, index: number) => {
          return (
            <DropdownMenuItem
              className='drop-down-option'
              key={`option_${index}_${option}`}
              onClick={() => handleClick(option)}
              // disabled={disable}
            >
              {option.label}
              <div className='d-flex justify-center align-center'>{tags?.length > 0 && tags[index]}</div>
            </DropdownMenuItem>
          )
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default DropDown
