import { DeviceState, DeviceAction } from './types'

const initialState: DeviceState = {
  data: [],
  countLoading: false,
  activeTab: 0,
  tableLoading: false,
  error: null,
  totalData: [],
  deviceCount: [
    {
      all_white_label_count: 0,
      all_devices_count: 0,
      online_devices_count: 0,
      offline_devices_count: 0,
      not_registered_devices_count: 0,
      update_required_devices_count: 0
    }
  ],
  userGateways: [],
  filterLoading: false,
  activeDeviceMacId: null
}

const deviceStatisticsReducer = (state = initialState, action: DeviceAction): DeviceState => {
  switch (action.type) {
    case 'SET_DEVICE_ACTIVE_TAB_REQUEST':
      return {
        ...state,
        activeTab: action.payload,
        error: null
      }
    case 'SET_DEVICE_MAC_ID_REQUEST':
      return {
        ...state,
        activeDeviceMacId: action.payload,
        error: null
      }
    case 'GET_DEVICE_STATISTICS_REQUEST':
      return {
        ...state,
        data: [],
        tableLoading: true,
        error: null
      }
    case 'GET_DEVICE_STATISTICS_SUCCESS':
      return {
        ...state,
        data: action.payload,
        tableLoading: false,
        filterLoading: false,
        error: null
      }
    case 'GET_DEVICE_STATISTICS_COUNT_REQUEST':
      return {
        ...state,
        totalData: [],
        error: null
      }
    case 'GET_DEVICE_STATISTICS_COUNT_SUCCESS':
      return {
        ...state,
        totalData: action.payload,
        error: null
      }
    case 'GET_DEVICE_STATISTICS_FILTER_REQUEST':
      return {
        ...state,
        filterLoading: true,
        error: null
      }
    case 'GET_DEVICE_COUNT_REQUEST':
      return {
        ...state,
        countLoading: true,
        deviceCount: [
          {
            all_white_label_count: 0,
            all_devices_count: 0,
            online_devices_count: 0,
            offline_devices_count: 0,
            not_registered_devices_count: 0,
            update_required_devices_count: 0
          }
        ],
        error: null
      }
    case 'GET_DEVICE_COUNT_SUCCESS':
      return {
        ...state,
        deviceCount: action.payload,
        countLoading: false,
        error: null
      }
    case 'GET_ACCOUNT_GET_WAY_INFO_SUCCESS':
      return {
        ...state,
        activeDeviceMacId: null,
        userGateways: action.payload
      }
    case 'GET_DEVICE_STATISTICS_FAILURE':
      return {
        ...state,
        tableLoading: false,
        countLoading: false,
        error: action.error,
        filterLoading: false
      }
    default:
      return state
  }
}

export default deviceStatisticsReducer
