import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addGatewayNote, deleteGatewayNote, updateGatewayNote } from '../../../../hook/addGatewayNote'
import { useLocation } from 'react-router-dom'
import { Divider, Button, Snackbar, Menu, MenuItem } from '@mui/material'
import { fetchGatewayNotes } from '../../../../redux/customer/fetgatewayNotes'
import Pagination from '../../../../components/Pagination'
import moment from 'moment'
import Loader from '../../../../components/Loader'
import { SnackBarProps } from '../../../../components/Device-table/Provisioning'
import { getColorCode } from '../../../../utils/helper'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MuiAlert from '@mui/material/Alert'
import { Modal } from 'antd'
import CloseIcon from '@mui/icons-material/Close'
import { useSessionContex } from '../../../../SessionContex'

function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const CustomertNotes = () => {
  const location = useLocation()
  const { openModal, setOpenModal } = useSessionContex()

  const gwUid = location.pathname.split('/')[2]
  const [notesList, setNotesList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setCount] = useState(1)
  const [isLoadingdata, setIsLoadingData] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editId, setEditId] = useState('')
  const [selectedNotes, setSelectedNotes] = useState<any>({ note: '', id: '' })
  const [isDelete, setIsDelete] = useState(false)

  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: '',
    type: 'Closed'
  })
  const notesPerPage = 4

  const validationSchema = Yup.object().shape({
    note: Yup.string().trim().required('Please enter a note')
  })

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const addNote = addGatewayNote()
  const updateMutate = updateGatewayNote()
  const { mutate: deleteMutate } = deleteGatewayNote()
  const formik = useFormik({
    initialValues: {
      note: ''
    },
    validationSchema,
    onSubmit: values => {
      onSubmit(values)
    }
  })

  useEffect(() => {
    setIsLoadingData(true)
    getNotesList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gwUid, currentPage])

  const getNotesList = async () => {
    setIsLoadingData(true)
    try {
      const getListData = await fetchGatewayNotes({ gwUid: gwUid })
      setCount(getListData.length)
      const startIndex = (currentPage - 1) * notesPerPage
      const endIndex = startIndex + notesPerPage
      const paginatedList = getListData.slice(startIndex, endIndex)
      // console.log('================', currentPage, paginatedList.length)
      if (currentPage > 1 && paginatedList.length === 0) {
        setCurrentPage(currentPage - 1)
      }
      setNotesList(paginatedList)
      setIsLoadingData(false)
    } catch (error) {
      setIsLoadingData(true)
    }
  }

  const onSubmit = async (values: any) => {
    setIsLoadingData(true)

    // try {
    if (isEdit) {
      const notesdata = {
        gw_uid: gwUid,
        note: values.note,
        id: editId
      }
      updateMutate.mutate(notesdata, {
        onSuccess: (data: any) => {
          if (data === 401) {
            !openModal && setOpenModal(true)
          }
          setSnackBarInfo({
            isOpen: true,
            message: 'Notes Updated Succesfully.',
            type: 'Closed'
          })
          setSelectedNotes({ note: '', id: '' })
          getNotesList()
        },
        onError: (error: any) => {
          setSnackBarInfo({
            isOpen: true,
            message: `Failed to ${isEdit ? 'update' : 'add'} note.`,
            type: 'redToRed'
          })
          getNotesList()
        }
      })
    } else {
      const notesdata = {
        gw_uid: gwUid,
        note: values.note
      }
      addNote.mutate(notesdata, {
        onSuccess: (data: any) => {
          if (data === 401) {
            !openModal && setOpenModal(true)
          }
          setSnackBarInfo({
            isOpen: true,
            message: 'Notes Added Succesfully.',
            type: 'Closed'
          })
          getNotesList()
        },
        onError: (error: any) => {
          setSnackBarInfo({
            isOpen: true,
            message: `Failed to ${isEdit ? 'update' : 'add'} note.`,
            type: 'redToRed'
          })
          getNotesList()
        }
      })
    }

    setEditId('')
    // getNotesList()

    setIsEdit(false)
    // } catch (error) {
    // setSnackBarInfo({
    //   isOpen: true,
    //   message: `Failed to ${isEdit ? 'update' : 'add'} note.`,
    //  type: 'redToRed'
    // })
    // }
    formik.resetForm()
  }

  const handleEditClick = () => {
    handleClose()
    setIsEdit(true)
    formik.setFieldValue('note', selectedNotes?.note)
    setEditId(selectedNotes?.id)
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>, notes: any) => {
    setAnchorEl(event.currentTarget)
    setSelectedNotes(notes)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDeleteClick = () => {
    handleClose()
    formik.setFieldValue('note', '')
    setIsDelete(true)
  }
  const deleteNotes = async () => {
    try {
      await deleteMutate(selectedNotes.id)
      setSelectedNotes({ note: '', id: '' })
      setSnackBarInfo({
        isOpen: true,
        message: 'Notes deleted Succesfully.',
        type: 'Closed'
      })
      setIsDelete(false)
    } catch (error) {
      setSnackBarInfo({
        isOpen: true,
        message: `Failed to delete note.`,
        type: 'redToRed'
      })
    }
    setTimeout(() => {
      getNotesList()
    }, 1000)
  }
  return (
    <>
      {/* <Formik
        initialValues={{
          note: editingNote !== null && editingNote !== '' ? editingNote : initialValues.note
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => ( */}
      <form className='m-3' onSubmit={formik.handleSubmit} noValidate>
        <div className='form-group'>
          <div className='input-group'>
            {/* <Field
              as='textarea'
              className='form-control note-area-cls'
              placeholder='Write a note'
              id='note'
              name='note'
              value={formik.values.note}
            /> */}
            <textarea
              id='note'
              name='note'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.note}
              className='form-control note-area-cls'
            />
            <div className='input-group-append ms-2 mt-4'>
              <button type='submit' className={`btn btn-primary  `} style={{ background: getColorCode('blueBlue') }}>
                {isEdit ? 'Update' : 'Submit'}
              </button>
            </div>
          </div>
          <div style={{ height: '18px' }}>
            {formik.errors.note && formik.touched.note && (
              <div style={{ color: getColorCode('redToBlue') }}>{formik.errors.note}</div>
              // <ErrorMessage name='note' component='div' className='text-danger' />
            )}
          </div>
        </div>
      </form>
      {/* )}
      </Formik> */}
      <Snackbar
        open={snackBarInfo.isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={() =>
          setSnackBarInfo({
            isOpen: false,
            message: snackBarInfo.message,
            type: snackBarInfo.type
          })
        }
        sx={{ backgroundColor: 'transparent' }}
      >
        <div>
          <Alert severity='success' sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}>
            {snackBarInfo.message}
          </Alert>
        </div>
      </Snackbar>
      <div className='note-height-cls m-3'>
        {isLoadingdata && <Loader />}
        {!isLoadingdata && notesList?.length > 0
          ? notesList?.map((notes: any, index: any) => {
              return (
                <div className='notes-list-cls mb-3' key={notes.id}>
                  <div className='d-flex justify-content-between align-items-center pe-4 ps-4'>
                    <div className='pt-2 mt-1'>
                      <label className='notes-title-cls'>Agent: {notes.author}</label>
                      <p className='notes-desc-cls'>
                        {notes?.note.length > 50 ? notes?.note.substring(0, 50) + '...' : notes?.note}
                      </p>
                    </div>
                    <div>
                      <label className='notes-date-cls pe-4'>
                        {moment.utc(notes.timestamp).local().format(`hh:mm a - ${process.env.REACT_APP_DATE_FORMAT}`)}
                      </label>
                      <Button
                        variant='contained'
                        size='medium'
                        className={`notes-edit-btn `}
                        id='demo-customized-button'
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        disableElevation
                        onClick={event => {
                          handleClick(event, notes)
                        }}
                        endIcon={<KeyboardArrowDownIcon />}
                        sx={{
                          background: `${getColorCode('blueBlue')}!important`
                        }}
                      >
                        Edit
                      </Button>

                      <Menu
                        anchorEl={anchorEl}
                        id='demo-customized-menu'
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1
                            },
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0
                            }
                          }
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                        <MenuItem
                          onClick={handleEditClick}
                          // disableRipple
                        >
                          <EditIcon />
                          Edit
                        </MenuItem>
                        <Divider sx={{ marginTop: '4px !important', marginBottom: '4px !important' }} />
                        <MenuItem onClick={handleDeleteClick} disableRipple>
                          <DeleteIcon />
                          Delete
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                </div>
              )
            })
          : !isLoadingdata &&
            notesList?.length === 0 && (
              <div className='no-data-found text-center pt-4' style={{ color: getColorCode('redToBlue') }}>
                No data found
              </div>
            )}
      </div>
      <div className='fixed-pagination-container'>
        {notesList?.length > 0 && (
          <Pagination
            current={currentPage}
            onePage={4}
            totalPages={totalCount}
            handleSetCurrentCount={(newPage: any) => {
              setCurrentPage(newPage)
            }}
          />
        )}
      </div>

      <Modal
        open={isDelete}
        footer={null}
        closable={false}
        // width={300}
        wrapClassName={'delete_notes_wrapper'}
        centered={true}
      >
        <div className='delete_notes_body_wrapper device-tab'>
          <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
            <h3>Delete Notes</h3>
            <CloseIcon
              className='cursor-pointer'
              onClick={() => {
                setIsDelete(false)
              }}
            />
          </div>
          <div className='mb-2 row-gap-1 text-center'>
            {/* <div>
              <h2 style={{ fontSize: '1.25rem' }}>Are you sure?</h2>
            </div> */}
            <div className='mb-3 mt-1 text-lg'>Are you sure you want to Delete the note?</div>
            <div>
              <Button
                onClick={() => deleteNotes()}
                variant='contained'
                size='medium'
                sx={{ marginRight: '10px', background: getColorCode('redToBlue') }}
                // color='error'
              >
                Ok
              </Button>
              <Button
                onClick={() => setIsDelete(false)}
                size='medium'
                variant='outlined'
                style={{ color: getColorCode('redToBlue'), borderColor: getColorCode('redToBlue') }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CustomertNotes
