import React from 'react'

const Firmware = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.7266 0H5.27344C2.36094 0 0 2.36094 0 5.27344V14.7266C0 17.6391 2.36094 20 5.27344 20H14.7266C17.6391 20 20 17.6391 20 14.7266V5.27344C20 2.36094 17.6391 0 14.7266 0ZM8.40039 7.20469H4.58984V9.25391H8.05195V10.9055H4.58984V14.4531H2.44297V5.54727H8.40039V7.20469ZM15.9973 14.4527H14.2539L13.159 10.5258L12.0578 14.4527H10.3145L8.75469 7.83437H10.7305L11.3848 11.8285L12.4367 7.83437H13.8742L14.9141 11.7367L15.5809 7.83437H17.5566L15.9969 14.4527H15.9973Z" fill="black"/>
    </svg>
  )
}
export default Firmware
