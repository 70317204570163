import React from 'react'

const DownSideArrow = () => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.9695 5.9668L9.59962 10.3135C9.08355 10.8268 8.23907 10.8268 7.723 10.3135L3.35315 5.9668'
        stroke='black'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default DownSideArrow