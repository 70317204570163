import { FC, useEffect } from 'react'
import { useGatewayServices } from '../../../hook/useGatewayServices'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import Loader from '../../Loader'
import { useSelector } from 'react-redux'
import { CustomersRootState } from '../../../redux/customerStatistics/types'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import { useSessionContex } from '../../../SessionContex'

interface DataProps {
  activeGw_Uid: string
  isMaster: boolean
  email: string
}

const CustomerServices = () => {
  const { openModal, setOpenModal } = useSessionContex()

  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const { data: gatewayInfo, isFetching: gatewayFetching, refetch: getwayRefetch }: any = useGatewayInfo(activeGw_Uid) //User device data

  const { data, isLoading, refetch, isFetching } = useGatewayServices(activeGw_Uid)

  useEffect(() => {
    refetch()
    getwayRefetch()
  }, [activeGw_Uid])

  useEffect(() => {
    refetch()
    getwayRefetch()
  }, [])

  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data])

  useEffect(() => {
    if (gatewayInfo === 401) {
      !openModal && setOpenModal(true)
    }
  }, [gatewayInfo])

  const navigate = useNavigate()
  const activeClass: any = `${process.env.REACT_APP_MODE === 'hitron' ? 'hitronbgActive' : 'bgActive'}`
  const inActiveClass: any = `${process.env.REACT_APP_MODE === 'hitron' ? 'hitronbgInActive' : 'bgInActive'}`

  return (
    <div className='service-main-Wrapper'>
      {isFetching || isLoading || gatewayFetching ? (
        <div className='d-flex w-full justify-center aline-center'>
          <Loader />
        </div>
      ) : (
        <>
          <div className='font-sf-regular service-inner-Wrapper d-none'>
            <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block'>
              <span className='font-sf-medium serviceTitle'>Router Platform</span>
              {isLoading ? (
                <span className='service-tag text-white text-[14px] rounded-full px-4 py-0.5 font-sf-light'>
                  Loading...
                </span>
              ) : (
                <span
                  className={`${activeClass} service-tag text-white text-[14px] rounded-full px-4 py-0.5 font-sf-light`}
                >
                  Active
                </span>
              )}
            </div>
            <div className='mx-auto w-full mt-3 service-inner-block service-router-btn'>
              {/* <a
            href={`https://account.xunison.com/customer/router-platform?uid=${activeGw_Uid}`}
            rel='noreferrer'
            target='_blank'
            className='service-button'
          >
            Visit Router Platform
          </a> */}
            </div>
          </div>
          {gatewayInfo?.dev_mesh_role === 'CAP' ? (
            <>
              <div className='font-sf-regular service-inner-Wrapper'>
                <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block'>
                  <span className='font-sf-medium serviceTitle'>Parental Control Service</span>
                  <span
                    className={`service-tag text-white text-[14px] rounded-full ${
                      data?.srv_pc_enabled ? activeClass : inActiveClass
                    } px-4 py-0.5 font-sf-light`}
                  >
                    {data?.srv_pc_enabled ? 'Active' : 'Inactive'}
                  </span>
                </div>

                {data?.srv_pc_enabled && (
                  <div className='mx-auto w-full mt-3 service-inner-block'>
                    <button
                      type='button'
                      onClick={() => navigate(`/dashboard/${activeGw_Uid}?email=${gatewayInfo?.email}`)}
                      className=' text-white w-full font-sf-light px-6 text-[14px] py-2 rounded-md cursor-pointer service-button'
                      style={{
                        background: `${process.env.REACT_APP_MODE === 'hitron' ? '#004c97' : '#6581fa'}`
                      }}
                    >
                      Visit Parental Control
                    </button>
                  </div>
                )}
              </div>

              <div className='font-sf-regular service-inner-Wrapper'>
                <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block'>
                  <span className='font-sf-medium serviceTitle'>Cyber Security</span>
                  <span
                    className={`service-tag text-white text-[14px] rounded-full ${
                      data?.srv_cs_enabled ? activeClass : inActiveClass
                    } px-4 py-0.5 font-sf-light`}
                  >
                    {data?.srv_cs_enabled ? 'Active' : 'Inactive'}
                  </span>
                </div>
                {data?.srv_cs_enabled && (
                  <div className='mx-auto w-full mt-3 service-inner-block'>
                    <button
                      type='button'
                      onClick={() => navigate(`/dashboard/${activeGw_Uid}/${gatewayInfo?.email}`)}
                      className=' text-white w-full font-sf-light px-6 text-[14px] py-2 rounded-md cursor-pointer service-button'
                      style={{
                        background: `${process.env.REACT_APP_MODE === 'hitron' ? '#004c97' : '#6581fa'}`
                      }}
                    >
                      Visit Cyber Security
                    </button>
                  </div>
                )}
              </div>

              {/* <div className='font-sf-regular service-inner-Wrapper'>
                <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block'>
                  <span className='font-sf-medium serviceTitle'>Smart Home</span>
                  <span
                    className={`service-tag text-white text-[14px] rounded-full bgComming px-4 py-0.5 font-sf-light`}
                  >
                    Coming Soon
                  </span>
                </div>
                <div className='mx-auto w-full mt-3 service-inner-block'>
                  <button
                    type='button'
                    // onClick={() => {
                    //   sessionStorage.setItem('isPCverified', 'true')
                    //   navigate(`/smart-home/${activeGw_Uid}?email=${email}`)
                    // }}
                    className=' text-white w-full font-sf-light px-6 text-[14px] py-2 rounded-md cursor-not-allowed service-button notAllow'
                  >

                    Visit Smart Home Platform
                  </button>
                </div>
              </div> */}

              {/* <div className='font-sf-regular service-inner-Wrapper'>
            <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block'>
              <span className='font-sf-medium serviceTitle'>Elderly Care</span>
              <span className={`service-tag text-white text-[14px] rounded-full bgComming bg-amber-300 px-4 py-0.5 font-sf-light`}>
                Coming Soon
              </span>
            </div>
            <div className='mx-auto w-full mt-3 service-inner-block'>
              <button
                  type='button'
                  // onClick={() => {
                  //   sessionStorage.setItem('isPCverified', 'true')
                  //   navigate(`/smart-home/${activeGw_Uid}?email=${email}`)
                  // }}
                  className=' text-white w-full font-sf-light px-6 text-[14px] py-2 rounded-md cursor-not-allowed service-button notAllow'
              >
                {/*                         <button type='button' disabled={true}
                 * /}
                Visit Elderly Care
              </button>
            </div>
          </div>
          <div className='font-sf-regular service-inner-Wrapper'>
            <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block' style={{gap: "0"}}>
              <span className='font-sf-medium serviceTitle'>Energy Management</span>
              <span className={`service-tag text-white text-[14px] rounded-full bgComming bg-amber-300 px-4 py-0.5 font-sf-light `}>
                Coming Soon
              </span>
            </div>
            <div className='mx-auto w-full mt-3 service-inner-block'>
              <button
                  type='button'
                  // onClick={() => {
                  //   sessionStorage.setItem('isPCverified', 'true')
                  //   navigate(`/smart-home/${activeGw_Uid}?email=${email}`)
                  // }}
                  className=' text-white w-full font-sf-light px-6 text-[14px] py-2 rounded-md cursor-not-allowed service-button notAllow'
              >
                {/*                         <button type='button' disabled={true}
                 * /}
                Visit Energy Management
              </button>
            </div>
          </div> */}
            </>
          ) : (
            <div className='font-sf-regular service-inner-Wrapper'>
              <div className='py-3 flex justify-between items-center w-full gap-x-6 service-main-block'>
                <span className='font-sf-medium serviceTitle'>Services are not available for Satellite Devices</span>

                <span
                  className={`service-tag text-white text-[14px] rounded-full bg-alerts-critical ${inActiveClass} px-2 py-0.5 font-sf-light`}
                >
                  -
                </span>
              </div>
              <div className='mx-auto w-full mt-3 service-inner-block'>
                <button
                  type='button'
                  className=' text-white cursor-not-allowed w-full font-sf-light px-6 text-[14px] py-2 rounded-md service-button'
                  style={{
                    background: `${process.env.REACT_APP_MODE === 'hitron' ? '#004c97' : '#6581fa'}`
                  }}
                >
                  Services not available on Satellite Device
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
export default CustomerServices
