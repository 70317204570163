import React, { useEffect, useState } from 'react'
import { CustomersRootState } from '../../../redux/customerStatistics/types'
import { useSelector } from 'react-redux'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import { useConnectedDevices } from '../../../hook/useConnectedDevices'
import SpeedTestCharts from './speed-test-charts'
import Loader from '../../../components/Loader'
import { SnackBarProps } from '../../../components/Device-table/Provisioning'
// import { useAccountGateways } from '../../../hook/useAccountGateways'
import { Snackbar } from '@mui/material'
import { getColorCode } from '../../../utils/helper'
import { handlefingerprint } from '../../../utils/Teame/fingerprinting'
import { useSessionContex } from '../../../SessionContex'
import MuiAlert from '@mui/material/Alert'

function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const SpeedTest = () => {
  const { openModal, setOpenModal } = useSessionContex()
  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const [mqtt, setMqtt] = useState<any>(null)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [connectedToMqtt, setConnectedToMqtt] = useState<boolean>(false)
  const [activeGateway, setActiveGateway] = useState<string | null>(activeGw_Uid)
  const [activeId, setActiveId] = useState<string | null>('')
  const [deviceData, setDeviceData] = useState<any>([])
  const [processingSpeedtestCmd, setProcessingSpeedtestCmd] = useState<boolean>(false)
  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed'
  })

  //   const { toast } = useToast()

  const { data: gatewayInfo, isLoading: gatewayInfoLoading, refetch } = useGatewayInfo(activeGw_Uid)
  // const { data: deviceInfo, isLoading: deviceLoading, refetch: deviceRefetch } = useAccountGateways()
  const userGateways: any = useSelector((state: any) => state.deviceStatistics.userGateways)
  const {
    data,
    isLoading,
    refetch: connectionRefetch,
    isFetching
  } = useConnectedDevices(
    gatewayInfo && gatewayInfo?.dev_mesh_role === 'NonCAP' ? gatewayInfo.parent_gw_uid : activeGw_Uid,
    //  activeGw_Uid,
    new Date().toISOString()
  )

  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data])

  useEffect(() => {
    setIsPageLoading(true)
    // deviceRefetch()
    if (gatewayInfo === 401) {
      !openModal && setOpenModal(true)
    } else {
      const initializeMqtt = async () => {
        const JsMqttClientImpl = (await import('../../../mqtt/index')).JsMqttClientImpl
        setMqtt(new JsMqttClientImpl())
      }
      initializeMqtt()
      connectionRefetch()
      setConnectedToMqtt(false)
      setActiveGateway(activeGw_Uid)
      setTimeout(() => {
        setIsPageLoading(false)
      }, 1500)
    }
    if (activeGw_Uid) {
      refetch()
    }
  }, [activeGw_Uid, gatewayInfo])

  useEffect(() => {
    if (userGateways) {
      setDeviceData(userGateways)
    }
  }, [userGateways])

  useEffect(() => {
    if (gatewayInfoLoading || isLoading) {
      setIsPageLoading(true)
    } else {
      setTimeout(() => {
        setIsPageLoading(false)
      }, 1500)
    }
  }, [gatewayInfoLoading, isLoading])
  // const connectMqttFun = () => {
  //   mqtt
  //     ?.connect()
  //     .then(() => {
  //       setConnectedToMqtt(true)

  //       setSnackBarInfo({
  //         isOpen: true,
  //         message: 'connected to Router',
  //         type: 'chartPurpleBlue'
  //       })
  //     })
  //     .catch((err: any) => {
  //       mqtt.disconnect()
  //     })
  // }
  // useEffect(() => {
  //   setTimeout(() => {
  //     connectMqttFun()
  //   }, 600000)
  // }, [])

  const handleActiveGateway = (gwuid: string) => {
    setProcessingSpeedtestCmd(true)

    setActiveGateway(gwuid)
  }

  // mqtt

  const speedtest = JSON.stringify({
    cmd: 'speedtest',
    response_topic: 'speedtest'
  })

  async function sendCommand(message: any, topic: string) {
    await mqtt.sendMessage({ message, topic })
  }
  // const message = JSON.stringify({
  //   cmd: 'getsysconfig',
  //   callid: 3,
  //   response_topic: 'resp',
  //   op: 'wizard'
  // })
  // mqtt
  const handleSubscribe = (gwuid2: string) => {
    if (!activeGw_Uid) return
    setTimeout(() => {
      setProcessingSpeedtestCmd(true)
    }, 1000)
    let topic = `/router/${activeGw_Uid}/command`
    let topic2 = `/router/${activeGw_Uid}/resp`
    let topic3 = `/router/${activeGw_Uid}/site_survey`
    if (gwuid2 !== activeGw_Uid) {
      topic = `/router/${gwuid2}/command`
      topic2 = `/router/${gwuid2}/resp`
      topic3 = `/router/${gwuid2}/site_survey`

      mqtt.subscribeToTopics([topic2, topic, topic3])
      // mqtt.sendMessage({ message, topic })
    }
    sendCommand(speedtest, topic)

    // setTimeout(() => {
    //   setProcessingSpeedtestCmd(false)
    // }, 2000)
    setSnackBarInfo({
      isOpen: true,
      message: 'Speed test command has been sent to the router',
      subMessage: 'Please revisit /refresh the page after 3 minutes to see the results',
      type: 'Closed'
    })
    mqtt.subscribeToTopics([topic2, topic, topic3])
    // mqtt.sendMessage({ message, topic })
  }
  const handleMqttStrem = (gwuid2: string) => {
    setActiveId(gwuid2)
    // let topic = `/router/${activeGw_Uid}/command`
    // if (gwuid2 !== activeGw_Uid) {
    //   topic = `/router/${gwuid2}/command`

    //   mqtt.subscribeToTopics([topic])
    // }
    // sendCommand(speedtest, topic)

    // setProcessingSpeedtestCmd(false)

    //new
    if (!connectedToMqtt) {
      mqtt
        ?.connect()
        .then(() => {
          setConnectedToMqtt(true)

          // setSnackBarInfo({
          //   isOpen: true,
          //   message: 'Speed test command has been sent to the router',
          //   subMessage:
          //     'the router will report the results shortly, please visit this page again after sometime to see the results',
          //   type: 'Closed'
          // })

          handleSubscribe(gwuid2)
        })
        .catch((err: any) => {
          mqtt.disconnect()
        })
    } else {
      handleSubscribe(gwuid2)
    }
    // toast({
    //   variant: 'success',
    //   title: 'Speed test command has been sent to the router',
    //   description:
    //     'the router will report the results shortly, please visit this page again after sometime to see the results'
    // })
  }

  return (
    <div className='speedTestWrapper'>
      {isFetching ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className='deviceListWrapper'>
            {data &&
              data.length > 0 &&
              data
                ?.filter((item: any) => item.mesh_role !== null)
                .sort((a: any, b: any) => {
                  if (a.mesh_role === 'master' && b.mesh_role !== 'master') {
                    return -1
                  } else if (a.mesh_role !== 'master' && b.mesh_role === 'master') {
                    return 1
                  } else {
                    return 0
                  }
                })
                .map((item: any, index: number) => {
                  const data1 = deviceData?.filter((items: any) => items.gw_uid === item.gw_uid)
                  const folder: any = process.env.REACT_APP_TITLE

                  const fingerprintPayload = [
                    item?.os_name,
                    item?.device_name,
                    item?.fingerbank_device_name,
                    item?.os_parent_name,
                    item?.manufacturer_name
                    // item?.model
                  ]
                  const image = item?.model?.toLowerCase()?.includes('aria')
                    ? 'aria'
                    : handlefingerprint(fingerprintPayload)

                  return (
                    <div
                      key={`active_router_${index}`}
                      className='deviceWrapper'
                      onClick={() => (activeGateway === item.gw_uid ? null : handleActiveGateway(item.gw_uid))}
                    >
                      <div
                        className={`device-box bg-white py-6 px-2 relative rounded-[10px] shadow-md border-2 text-center `}
                        style={{
                          border: `${
                            activeGateway === item.gw_uid
                              ? `2px solid ${getColorCode('redToBlue')}`
                              : '2px solid #e3e3e3'
                          }`
                        }}
                      >
                        <div
                          style={{
                            minHeight: '156px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <img
                            src={`/images/network-map-${
                              folder.toLowerCase() !== 'xunison' ? 'hitron-images' : 'images'
                            }/${image === 'unknown' ? 'd50-home' : image}.png`}
                            // src='/images/network-map-images/d50-home.png'
                            className='device_image'
                            alt=''
                          />
                        </div>
                        <span
                          className={`device_title mt-auto px-2`}
                          title={`${item.ssid || item.title || '-'}`}
                          style={{ color: getColorCode('redToBlue') }}
                        >
                          {item.ssid || item.title || '-'}
                        </span>
                        <span
                          className={`device_type absolute top-2 right-1 px-2 py-1 rounded-full text-white font-sf-light text-xs`}
                          style={{
                            backgroundColor: `${
                              item.mesh_role === 'master' ? getColorCode('greenToGreen') : getColorCode('blueBlue')
                            }`
                          }}
                        >
                          {item.mesh_role === 'master' ? 'M' : 'S'}
                        </span>
                      </div>

                      {data1.length !== 0 ? (
                        // data1.length === 0 ? (
                        //   <button
                        //     type='button'
                        //     disabled
                        //     className='px-3 mt-2  device_picker disable disabled:bg-alerts-critical  py-1 text-white bg-alerts-closed rounded-md'
                        //     onClick={() => handleMqttStrem(item.gw_uid)}
                        //   >
                        //     Not register
                        //   </button>
                        // ) :
                        data1[0]?.is_online ? (
                          <button
                            type='button'
                            // disabled={item.gw_uid === activeId}
                            disabled={processingSpeedtestCmd}
                            className={`  py-1 px-3 mt-2 rounded-md device_picker`}
                            style={{
                              background: getColorCode('greenBlue'),
                              color: `${activeGateway === item.gw_uid ? getColorCode('blackLightGreen') : '#fff'}`
                            }}
                            onClick={() => handleMqttStrem(item.gw_uid)}
                          >
                            {isFetching && activeGateway === item.gw_uid ? 'Running...' : 'Run Speed Test'}
                          </button>
                        ) : (
                          <button
                            type='button'
                            disabled={true}
                            className='px-3 mt-2  device_picker disable  py-1 text-white  rounded-md'
                            style={{
                              background: getColorCode('redToGray')
                            }}
                          >
                            Device Offline
                          </button>
                        )
                      ) : (
                        <button
                          type='button'
                          disabled={processingSpeedtestCmd}
                          className='px-3 mt-2  device_picker disable  py-1 text-white rounded-md'
                          style={{
                            background: getColorCode('greenBlue')
                          }}
                          onClick={() => handleMqttStrem(item.gw_uid)}
                        >
                          {isFetching && activeGateway === item.gw_uid ? 'Running...' : 'Run Speed Test'}
                        </button>
                      )}
                    </div>
                  )
                })}
          </div>
          <div className='SpeedTestWrapper '>
            {activeGateway && data !== undefined && (
              <SpeedTestCharts
                gwuid={activeGateway}
                setProcessingSpeedtestCmd={setProcessingSpeedtestCmd}
                processingSpeedtestCmd={processingSpeedtestCmd}
                dataDevice={data}
                setActiveId={setActiveId}
              />
            )}
          </div>
        </>
      )}
      <Snackbar
        open={snackBarInfo.isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={() =>
          setSnackBarInfo({
            isOpen: false,
            message: snackBarInfo.message,
            type: snackBarInfo.type,
            severity: 'success'
          })
        }
        sx={{ backgroundColor: 'transparent' }}
      >
        <div>
          <Alert
            severity={snackBarInfo.severity}
            sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
          >
            {snackBarInfo.message}
            {snackBarInfo.subMessage && <div>{snackBarInfo.subMessage}</div>}
          </Alert>
        </div>
      </Snackbar>
    </div>
  )
}

export default SpeedTest
