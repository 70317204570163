import { FC, useEffect, useState } from 'react'
import DownloadSpeedChart from './download-speed-chart'
import UploadSpeedChart from './upload-speed-chart'
import SpeedTestHistoryChart from './speed-test-history-chart'
import moment from 'moment'
import { useSpeedtest } from '../../../hook/useSpeedtest'
import Loader from '../../../components/Loader'
import { useSessionContex } from '../../../SessionContex'

interface Props {
  gwuid: string
  dataDevice: any
  setProcessingSpeedtestCmd?: any
  processingSpeedtestCmd?: any
  setActiveId?: any
}

const SpeedTestCharts: FC<Props> = ({
  gwuid,
  setProcessingSpeedtestCmd,
  processingSpeedtestCmd,
  dataDevice,
  setActiveId
}) => {
  const [dataFilter, setDataFilter] = useState<'day' | 'isoWeek'>('day')
  const { openModal, setOpenModal } = useSessionContex()

  const [isPageLoader, setIsPageLoader] = useState(true)
  const defaultFilter = moment().startOf(dataFilter).toISOString()
  // const defaultFilter = dataFilter === 'isoWeek' ? '2023-10-01T17:00:00.000Z' : '2023-10-07T17:00:00.000Z'

  const { data, isLoading, refetch } = useSpeedtest(gwuid, defaultFilter)
  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data])
  useEffect(() => {
    gwuid && gwuid !== '' && refetch()
    setTimeout(() => {
      setIsPageLoader(false)
      setProcessingSpeedtestCmd(false)
    }, 1500)
  }, [dataFilter])
  useEffect(() => {
    if (processingSpeedtestCmd && gwuid && gwuid !== '') {
      setIsPageLoader(true)
      gwuid && gwuid !== '' && refetch()
      setTimeout(() => {
        setIsPageLoader(false)
        setProcessingSpeedtestCmd(false)
      }, 1500)
    }
  }, [gwuid, processingSpeedtestCmd])

  const handleDataFilter = (filter: 'day' | 'isoWeek') => {
    if (dataFilter !== filter) {
      setDataFilter(filter)
      setIsPageLoader(true)
      setActiveId('')
    }
  }

  return (
    <div className='flex flex-col'>
      {isPageLoader ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {dataDevice && dataDevice?.length > 0 && (
            <div className='w-full d-flex py-2 justify-end'>
              <span
                onClick={() => handleDataFilter('day')}
                className={
                  dataFilter === 'day'
                    ? `${
                        process.env.REACT_APP_MODE === 'hitron' ? 'hitron-active-filter' : 'active-filter'
                      } cursor-pointer`
                    : `inactive-filter cursor-pointer`
                }
              >
                Today
              </span>
              <span
                onClick={() => handleDataFilter('isoWeek')}
                className={
                  dataFilter === 'isoWeek'
                    ? `${
                        process.env.REACT_APP_MODE === 'hitron' ? 'hitron-active-filter' : 'active-filter'
                      } cursor-pointer`
                    : 'inactive-filter cursor-pointer'
                }
              >
                This Week
              </span>
            </div>
          )}
          {data?.length === 0 && (
            <div className='text-center text-[#6B7A9F] font-sf-regular text-lg animate-pulse py-5'>
              Please use latest firmware to see speed test results / run your first speed test
            </div>
          )}
          {data !== undefined && data.length > 0 && (
            <DownloadSpeedChart
              data={data
                ?.filter((item: any) => !item.speedtest.cmd)
                .map((item: any) => {
                  if (item.speedtest.params && item.speedtest.result === 'OK') {
                    return item.speedtest.params
                  } else {
                    return item.speedtest
                  }
                })}
              categories={data
                ?.filter((item: any) => !item.speedtest.cmd)
                .map((item: any) => {
                  return item.timestamp
                })
                .reverse()}
            />
          )}
          {data !== undefined && data.length > 0 && (
            <UploadSpeedChart
              data={data
                ?.filter((item: any) => !item.speedtest.cmd)
                ?.map((item: any) => {
                  if (item.speedtest.params) {
                    item.speedtest.params.timestamp = item.timestamp
                    return item.speedtest.params
                  } else {
                    return item.speedtest
                  }
                })}
              categories={data
                ?.filter((item: any) => !item.speedtest.cmd)
                .map((item: any) => {
                  return item.timestamp
                })
                .reverse()}
            />
          )}
          {data?.length > 0 && data !== undefined && (
            <SpeedTestHistoryChart
              data={data
                ?.filter((item: any) => !item.speedtest.cmd)
                ?.map((item: any) => {
                  if (item.speedtest.params) {
                    // item.speedtest.timestamp = item.timestamp
                    return item.speedtest.params
                  } else {
                    item.speedtest.timestamp = item.timestamp
                    return item.speedtest
                  }
                })
                .reverse()}
              dataFilter={dataFilter}
            />
          )}
        </>
      )}
    </div>
  )
}

export default SpeedTestCharts
