import { useQuery } from '@tanstack/react-query'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'

export const useEnvironmentHealth = ({
  dev_mesh_role,
  parent_gw_uid,
  wan_mac,
  gw_uid
}: any): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const gwuid = gw_uid || sessionStorage.getItem('gwuid')

  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['', gwuid],
    async () => {
      const endpoint =
        dev_mesh_role === 'CAP'
          ? `/rpc/webapp_wifi_env_health?_gw_uid=${gwuid}&_mesh_role=${dev_mesh_role}`
          : `/rpc/webapp_wifi_env_health?_gw_uid=${parent_gw_uid}&_mesh_role=${dev_mesh_role}&_mac_satellite=${wan_mac}`
      if (!!dev_mesh_role) {
        try {
          const response = await xcpemSec.get<any[]>(endpoint)

          return response.data
        } catch (error: any) {
          if (error?.response?.status === 401) {
            return error?.response?.status
          } else {
            notifyError(`Wifi env health: ${error?.response?.data?.message || error?.message}`)
          }

          // throw new Error('Failed to fetch gateway info')
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
