import { Modal } from 'antd'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../../common-images/app-logo/LoadingSpinner/LoadingSpinner'
import { setProvisionEmail } from '../../redux/dashboard/dashboardCount'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { DashboardRootState } from '../../redux/dashboard/types'
import { ThunkDispatch } from 'redux-thunk'
import { RootState } from '../../redux/deviceStatistics/types'
import { AnyAction } from 'redux'
import { Snackbar } from '@mui/material'
import { getColorCode } from '../../utils/helper'
import MuiAlert from '@mui/material/Alert'

export interface SnackBarProps {
  isOpen: boolean
  message: string | any
  subMessage?: string
  type: 'redToRed' | 'Warning' | 'chartPurpleBlue' | 'Closed' | 'Success'
  severity?: 'error' | 'success'
}
function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}
const Provisioning = ({ isModalOpen, setIsModalOpen, provistionId, customerRefetch, refetch }: any) => {
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch()
  const initialValues = { email: '' }
  const validationSchema = Yup.object({
    email: Yup.string().email('Please enter a valid email').required('Please Enter Registered Email')
  })
  const showingProvisionModel = useSelector((state: DashboardRootState) => state.dashboardCount.showingProvisionModel)
  const showingProvisionModelError = useSelector((state: DashboardRootState) => state.dashboardCount.error)
  const showingProvisionModelSuccess = useSelector(
    (state: DashboardRootState) => state.dashboardCount.provisionSuccessMessage
  )

  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed',
    severity: 'success'
  })

  useEffect(() => {
    if (!!showingProvisionModelError) {
      setSnackBarInfo({
        isOpen: true,
        message: showingProvisionModelError,
        type: 'redToRed',
        severity: 'error'
      })
    }
  }, [showingProvisionModelError])

  useEffect(() => {
    if (!!showingProvisionModelSuccess) {
      setSnackBarInfo({
        isOpen: true,
        message: 'Provision account successfully',
        type: 'Closed',
        severity: 'success'
      })
      dispatch({ type: 'SET_PROVISION_EMAIL_RESET' })
      setTimeout(() => {
        setIsModalOpen(false)
        customerRefetch && customerRefetch()
        refetch && refetch()
      }, 2000)
    }
  }, [showingProvisionModelSuccess])

  // useEffect(() => {
  //   dispatch({ type: 'SET_PROVISION_EMAIL_RESET' })
  // }, [isModalOpen])

  const handleCancel = () => {
    dispatch({ type: 'SET_PROVISION_EMAIL_RESET' })

    setIsModalOpen(false)
    // customerRefetch && customerRefetch()
    setSnackBarInfo({
      isOpen: false,
      message: snackBarInfo.message,
      type: snackBarInfo.type,
      severity: 'success'
    })
  }

  const handleSubmit = (item: any, resetForm: any) => {
    if (!!item) {
      dispatch(setProvisionEmail({ params: { _email: item.email, _gw_uid: provistionId } }))
      resetForm()
    }
  }

  return (
    <>
      <Modal
        open={isModalOpen}
        footer={null}
        closable={false}
        width={600}
        wrapClassName={'authorize_gateway_wrapper'}
        centered={true}
      >
        <div className='authorize_gateway_body_wrapper'>
          <div className='authorize_gateway_title'>
            <h3>Authorize this gateway to a user</h3>
          </div>
          <div className='authorize_gateway_body'>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ resetForm }) => (
                <Form>
                  <div>
                    <label className='email_label' htmlFor='email'>
                      Email
                    </label>
                    <Field className='email_input' type='email' id='email' name='email' />
                    <ErrorMessage
                      name='email'
                      component='div'
                      className={`${process.env.REACT_APP_MODE === 'hitron' ? 'HitronErrorMessage' : 'ErrorMessage'}`}
                    />
                  </div>
                  <div className='authorize_gateway_footer'>
                    <button
                      type='button'
                      onClick={() => {
                        handleCancel()
                        resetForm()
                      }}
                      className='cancel_btn'
                      style={{
                        color: getColorCode('cancelBtnText'),
                        background: getColorCode('cancelBtnBg'),
                        border: `2px solid ${getColorCode('cancelBtnText')}`
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='authorize_gateway_btn'
                      style={{
                        color: getColorCode('logOutBtnText'),
                        background: getColorCode('logOutBtnBg'),
                        border: `2px solid ${getColorCode('logOutBtnText')}`
                      }}
                    >
                      {showingProvisionModel ? (
                        <div className='w-full d-flex justify-center'>
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <>Continue</>
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
      <Snackbar
        open={snackBarInfo.isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={() =>
          setSnackBarInfo({
            isOpen: false,
            message: snackBarInfo.message,
            type: snackBarInfo.type,
            severity: 'success'
          })
        }
        sx={{ backgroundColor: 'transparent' }}
      >
        <div>
          <Alert
            severity={snackBarInfo.severity}
            sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
          >
            {snackBarInfo.message}
          </Alert>
        </div>
      </Snackbar>
    </>
  )
}

export default Provisioning
