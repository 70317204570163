import { useMutation } from '@tanstack/react-query'
import xcpemSec from '../axiosInstances/xcpemSec'

const host = `https://${process.env.REACT_APP_IOTCORE}`
const baseUrl = `${host}`
export const deleteAccount = () => {
  const deleteRequest = async (payload: any) => {
    try {
      const response = await xcpemSec.delete(`${baseUrl}/api/v3/account`, { data: payload })
      return response.data
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        throw new Error('Failed to delete resource')
      }
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(deleteRequest)

  return { mutate, isLoading, error }
}
