import { Spin } from 'antd'
import React from 'react'

const Loader = () => {
  return (
    <div
      className={`loading_wrapper ${
        process.env.REACT_APP_MODE === 'hitron' ? 'loading-hitron-wrapper' : 'loading-local-wrapper'
      }`}
    >
      <Spin tip='Loading' style={{ color: process.env.REACT_APP_MODE === 'hitron' ? '#004C97' : '#FF8181' }}>
        <div className='content' />
      </Spin>
    </div>
  )
}

export default Loader
