import { useQuery } from '@tanstack/react-query'
import xcpemReadOnlySec from '../axiosInstances/xcpemReadOnlySec'
import { GatewayStats } from '../models/Xcpem'
import { notifyError } from '../components/Toaster'

export const useGatewayStats = (
  from: string,
  till: string,
  activeGw_Uid?: string
): {
  data: GatewayStats[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['gatewaylogs'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : sessionStorage.getItem('gwuid')

      if (!gwuid) return undefined

      const endpoint = `/rpc/webapp_gw_stats_history?_gw_uid=${gwuid}&_timestamp_from=${from}&_timestamp_till=${till}`
      try {
        const response = await xcpemReadOnlySec.get<GatewayStats[] | any>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError('Failed to fetch gateway statistics')

          throw new Error('Failed to fetch gateway statistics')
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 1
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useGatewayStatsChart = (
  from: string,
  till: string,
  activeGw_Uid?: string
): {
  data: GatewayStats[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['gatewaylogsChart'],
    async () => {
      const gwuid = !!activeGw_Uid ? activeGw_Uid : sessionStorage.getItem('gwuid')

      if (!gwuid) return undefined

      const endpoint = `/rpc/webapp_gw_stats_history?_gw_uid=${gwuid}&_timestamp_from=${from}&_timestamp_till=${till}`
      try {
        const response = await xcpemReadOnlySec.get<GatewayStats[] | any>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Stats history chart: ${error?.response?.data?.message || error?.message}`)
        }
        // throw new Error('Failed to fetch gateway statistics')
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 1
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
