import { useParams } from 'react-router-dom'
import ParentalLayout from '../../../Layouts/parentalLayout/parental-layout'
import { useContext, useEffect, useState } from 'react'
import { BreadcrumbContext } from '../../../contexts/breadcrumbData'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import DevicesList from '../../../components/parental-components/devices-list/devices-list'
import CyberTable from '../../../components/cyber-security/cyber-table/cyber-table'
import { useSessionContex } from '../../../SessionContex'

const CyberThreats = () => {
  const { updateBreadcrumbData, updateFilters } = useContext(BreadcrumbContext)
  const { openModal, setOpenModal } = useSessionContex()

  const { id: serialNumber } = useParams()
  // @ts-ignore
  const { data: gatewayInfo } = useGatewayInfo(serialNumber as any)
  useEffect(() => {
    if (gatewayInfo === 401) {
      !openModal && setOpenModal(true)
    }
  }, [gatewayInfo])
  // useEffect(() => {

  //     if(serialNumber && gatewayInfo){
  //         const newBreadcrumbData = [
  //             {label: 'Home', href: `/dashboard`},
  //             {label: 'Customers', href: `/dashboard/${gatewayInfo.gw_uid}`},
  //             {label: gatewayInfo?.email || '-', href: `/dashboard/${gatewayInfo?.gw_uid}`},
  //             {label: 'Cyber Security', href: `/dashboard/${gatewayInfo?.gw_uid}/${gatewayInfo?.email}`},
  //             {label: 'Threats', href: `/dashboard/${gatewayInfo?.gw_uid}/${gatewayInfo?.email}`}
  //         ]
  //         const filters: any = []
  //         updateFilters(filters)
  //         updateBreadcrumbData(newBreadcrumbData)
  //     }
  // }, [serialNumber,gatewayInfo, updateBreadcrumbData, updateFilters])

  const [filteringDevice, setFilteringDevice] = useState<number | '*'>('*')

  const onDeviceChange = (deviceId: any) => {
    setFilteringDevice(deviceId)
  }

  return (
    <ParentalLayout serialNumber={serialNumber as any} useSidebar='Cyber'>
      <div className=''>
        {gatewayInfo && (
          <DevicesList email={gatewayInfo.email} onChange={onDeviceChange} useMac={gatewayInfo.wan_mac} />
        )}

        {gatewayInfo && (
          <CyberTable email={gatewayInfo.email} mac={gatewayInfo.wan_mac} filteringDevice={filteringDevice} />
        )}
      </div>
    </ParentalLayout>
  )
}

export default CyberThreats
