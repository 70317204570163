/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import CustomeTabs from '../../../../components/CustomeTabs'
import { getColorCode } from '../../../../utils/helper'
import DeviceTab from './deviceTab'
import CustomerTab from './customerTab'
import SessionTimeOutModal from '../../../../components/SessionTimeOutModal'
import { useSessionContex } from '../../../../SessionContex'
import { useSendMail } from '../../../../hook/device-control'
interface SnackBarProps {
  isOpen: boolean
  message: string
  type: 'redToRed' | 'Warning' | 'chartPurpleBlue' | 'Closed'
}

function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

interface CustomerDeviceInfoProps {
  customerinfo: any // Add a type annotation for the fillColor prop
  // setWanConnection: any
  // setFetchingRouterSettings: any
  // fetchingRouterSettings: any
  wanMqtt: any
  setWanMqtt: any
}
interface RouterConfig {
  SSID: string
  PASSWORD: string
  MESH_ROLE: string
}

const CustomerDeviceInfo: React.FC<CustomerDeviceInfoProps> = ({
  // fetchingRouterSettings,
  // setFetchingRouterSettings,
  // setWanConnection,
  customerinfo,
  wanMqtt,
  setWanMqtt
}) => {
  const { openModal, setOpenModal } = useSessionContex()
  const sentMailMutation = useSendMail()
  const [fetchingRouterSettings, setFetchingRouterSettings] = useState<boolean>(true)
  const [connectedToMqtt, setConnectedToMqtt] = useState<boolean>(false)
  const [editSSID, setEditSSID] = useState<boolean>(false)
  const [editPASSWORD, setEditPASSWORD] = useState<boolean>(false)
  const [defaultValues, setDefaultValues] = useState<any>()
  const [valuesChanged, setValuesChanged] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isApplyingConfig, setIsApplyingConfig] = useState<boolean>(false)
  const [country, setCountry] = useState(null)
  //handle form submits
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useForm({ mode: 'onChange' })

  const watchFields = watch(['ssid', 'password'])

  const id = customerinfo?.gw_uid

  const [mqtt, setMqtt] = useState<any>(null)
  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed'
  })
  const initializeMqtt = async () => {
    const JsMqttClientImpl = (await import('../../../../mqtt')).JsMqttClientImpl
    setMqtt(new JsMqttClientImpl())
  }
  useEffect(() => {
    if (customerinfo === 401) {
      !openModal && setOpenModal(true)
    }
  }, [customerinfo])
  useEffect(() => {
    if (!wanMqtt) {
      initializeMqtt()
    } else {
      setFetchingRouterSettings(true)
    }
  }, [id, wanMqtt])
  useEffect(() => {
    setFetchingRouterSettings(true)
  }, [wanMqtt])
  const topic = `/router/${id}/command`
  const topic2 = `/router/${id}/resp`
  const topic3 = `/router/${id}/site_survey`

  const [routerConfig, setRouterConfig] = useState<RouterConfig>()
  const message = JSON.stringify({
    cmd: 'getsysconfig',
    callid: 3,
    response_topic: 'resp',
    op: 'wizard',
    params: ['WLAN_SSID_2G', 'WLAN_password_2G', 'WLAN_SSID_5G', 'WLAN_password_5G', 'mesh_role', 'timezone_name']
  })

  // generate payload for mqtt ( to change ssid , password )
  const generatePayload = (config: any) => {
    return {
      WLAN_SSID_2G: config.ssid,
      WLAN_SSID_5G: config.ssid,
      WLAN_password_2G: config.password,
      WLAN_password_5G: config.password
    }
  }
  async function sendCommand(message: any, topic: string) {
    if (connectedToMqtt) {
      await mqtt.sendMessage({ message, topic })
    }
  }

  // mqtt
  useEffect(() => {
    setConnectedToMqtt(false)
    setFetchingRouterSettings(true)

    if (customerinfo && customerinfo?.is_online && !connectedToMqtt && !wanMqtt) {
      mqtt
        ?.connect()
        .then(() => {
          setConnectedToMqtt(true)

          setSnackBarInfo({
            isOpen: true,
            message: 'connected to Router',
            type: 'chartPurpleBlue'
          })
          mqtt.subscribeToTopics([topic2, topic, topic3])
          mqtt.sendMessage({ message, topic })
        })
        .catch((err: any) => {
          mqtt.disconnect()
          setSnackBarInfo({
            isOpen: true,
            message: 'Not connected to Router',
            type: 'redToRed'
          })

          setTimeout(() => {
            initializeMqtt()
          }, 500)
        })
    } else if (customerinfo && !customerinfo?.is_online) {
      setFetchingRouterSettings(false)
      // setWanConnection('NA')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mqtt, id, wanMqtt])

  useEffect(() => {
    if (mqtt && !wanMqtt) {
      mqtt?.addMessageListener((message: any) => {
        const res = JSON.parse(message)
        console.log('==========', res)

        if (res.name === 'sysconfig') {
          if (res.callid === 3) {
            const { WLAN_SSID_5G: SSID, WLAN_password_5G: PASSWORD, mesh_role: MESH_ROLE } = res.params
            setSnackBarInfo({
              isOpen: true,
              message: 'Fetched router configuration successfully',
              type: 'Closed'
            })
            setRouterConfig({ SSID: SSID, PASSWORD: PASSWORD, MESH_ROLE: MESH_ROLE })
            setFetchingRouterSettings(false)
            setCountry(res.params.timezone_name)
            // setWanConnection(
            //   res?.params?.access_method === 'ethernet'
            //     ? `WAN${res?.params?.cell_data?.sim_status === 'READY' ? ' with LTE Backup' : ''}`
            //     : res?.params?.access_method === 'lte'
            //     ? `LTE ${res?.params?.cell_data?.connection_label || ''}`
            //     : '-'
            // )
            // mqtt.disconnect()
            // setConnectedToMqtt(false)
          }
          if (res.callid === 356 && res.result === 'OK') {
            setSnackBarInfo({
              isOpen: true,
              message: 'Applied new config successfully',
              type: 'Closed'
            })
          }
        }
      })
    }
  }, [mqtt, id, wanMqtt])
  // mqtt

  const [routerNewConfig, setRouterNewConfig] = useState(null)
  const handleRouterConfigChange = (data: any) => {
    setRouterNewConfig(null)
    setFetchingRouterSettings(true)
    const mailPayload: any = {
      email: sessionStorage.getItem('email'),
      password: data.password
    }
    sentMailMutation.mutate(
      { mailPayload },
      {
        onSuccess: (data: any) => {
          console.log('============data', data)

          // setSnackBarInfo({
          //   isOpen: true,
          //   message: ``,
          //   type: 'Closed',
          //   severity: 'success'
          // })

          // setRecallApi(true)
          // setTimeout(() => {
          //   setRecallApi(false)
          // }, 1000)

          // gatewayRefetch()
          // closeModal()
        },
        onError: (error: any) => {
          console.log('============error', error)

          // setSnackBarInfo({
          //   isOpen: true,
          //   message: error?.message,
          //   type: 'redToRed',
          //   severity: 'error'
          // })
        }
      }
    )
    setSnackBarInfo({
      isOpen: true,
      message: 'Applying new config',
      type: 'chartPurpleBlue'
    })

    const routerConfigPayload = JSON.stringify({
      cmd: 'setsysconfig',
      callid: 356,
      response_topic: 'resp',
      params: generatePayload(data)
    })
    setIsApplyingConfig(false)
    sendCommand(routerConfigPayload, topic)

    setTimeout(() => {
      sendCommand(message, topic)
    }, 1000)
  }

  useEffect(() => {
    setValue('ssid', routerConfig?.SSID)
    setValue('password', routerConfig?.PASSWORD)

    setDefaultValues({
      ssid: routerConfig?.SSID,
      password: routerConfig?.PASSWORD
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerConfig])

  //
  useEffect(() => {
    // Compare form values with the default values to determine if there are changes
    const currentValues = getValues()
    let valuesChanged = false
    if (!isApplyingConfig) {
      valuesChanged = JSON.stringify(currentValues) !== JSON.stringify(defaultValues)
    }

    setValuesChanged(valuesChanged)
  }, [defaultValues, watchFields])

  const onSubmit = (data: any) => {
    setIsApplyingConfig(true)
    setEditSSID(false)
    setEditPASSWORD(false)
    setShowPassword(false)
    setValuesChanged(false)
    handleRouterConfigChange(data)
  }

  //handle form submits

  const handleSSIDEditInfo = () => {
    setEditSSID(!editSSID)
  }
  const handlePasswordEditInfo = () => {
    setEditPASSWORD(!editPASSWORD)
  }
  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const hasErrors = Object.keys(errors).length > 0
  const inputClassName = classNames('ssidinput', {
    // eslint-disable-next-line no-useless-computed-key
    ['error']: false
  })
  const tabsList = [
    {
      label: 'Device',
      tab: (
        <>
          <DeviceTab
            customerinfo={customerinfo}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            fetchingRouterSettings={fetchingRouterSettings}
            handleShowPassword={handleShowPassword}
            showPassword={showPassword}
            editSSID={editSSID}
            routerConfig={routerConfig}
            handleSSIDEditInfo={handleSSIDEditInfo}
            valuesChanged={valuesChanged}
            hasErrors={hasErrors}
            register={register}
            errors={errors}
            inputClassName={inputClassName}
            editPASSWORD={editPASSWORD}
            handlePasswordEditInfo={handlePasswordEditInfo}
          />
        </>
      )
    }
    // {
    //   label: 'Customer',
    //   tab: (
    //     <>
    //       <CustomerTab region={country} fetchingRouterSettings={fetchingRouterSettings} />
    //     </>
    //   )
    // }
  ]

  return (
    <>
      <Card className='info-card-cls w-full'>
        <CardHeader className='info-header-cls' title='Device & Customer Information' />
        <CustomeTabs
          tabsList={tabsList}
          snackbar={
            <Snackbar
              open={snackBarInfo.isOpen}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              autoHideDuration={3000}
              onClose={() =>
                setSnackBarInfo({
                  isOpen: false,
                  message: snackBarInfo.message,
                  type: snackBarInfo.type
                })
              }
              sx={{ backgroundColor: 'transparent' }}
            >
              <div>
                <Alert
                  severity='success'
                  sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
                >
                  {snackBarInfo.message}
                </Alert>
              </div>
            </Snackbar>
          }
        />
      </Card>
    </>
  )
}

export default CustomerDeviceInfo
