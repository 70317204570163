import * as React from 'react'
import './styles.scss'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import { styled } from '@mui/material'
import TablePagination from '@mui/material/TablePagination'
import { ChangeEvent, useEffect, useState } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import { Column } from '../../models/CustomerTable'
import { Customer } from '../../models/Xcpem'
import { CustomTable, CustomTableHeaderRow, CustomTableRow } from './customers-tables'
import { Empty, Spin } from 'antd'
import { useScreenWidth } from '../../hook/useScreenWidth'
import EmptyData from '../../common-images/app-logo/customer-images/EmptyData'
import { CustomersRootState } from '../../redux/customerStatistics/types'
import NoDataFound from '../NoDataFound'
import Loader from '../Loader'
import SessionTimeOutModal from '../SessionTimeOutModal'
import { getColorCode } from '../../utils/helper'

const StyledTableCell = styled(TableCell)`
  && {
    font-size: 16px;
    background: transparent;
    color: #282828 !important;
    font-weight: 500;
    padding: 9px 16px;
  }
`

const CustomerTable = ({ filteringValue, customersStats, customersStatsLoading }: any) => {
  const dispatch: ThunkDispatch<CustomersRootState, unknown, AnyAction> = useDispatch()
  const screenWidth = useScreenWidth()

  // pagination
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [customers, setCustomers] = useState<Customer[]>([])

  const [width, setWidth] = useState<any>({
    small: '150px',
    normal: '200px',
    regular: '250px',
    large: '300px',
    extraLarge: '350px'
  })

  useEffect(() => {
    if (!screenWidth) return
    if (screenWidth > 2880) {
      setWidth({
        small: '210px',
        normal: '260px',
        regular: '400px',
        large: '450px',
        extraLarge: '500px'
      })
    } else if (screenWidth > 1780) {
      setWidth({
        small: `${(screenWidth * 10) / 100}px`,
        normal: `${(screenWidth * 10) / 100}px`,
        regular: `${(screenWidth * 14) / 100}px`,
        large: `${(screenWidth * 15) / 100}px`,
        extraLarge: `${(screenWidth * 20) / 100}px`
      })
    } else {
      setWidth({
        small: '150px',
        normal: '200px',
        regular: '250px',
        large: '300px',
        extraLarge: '350px'
      })
    }
  }, [screenWidth])

  const columns: any = [
    {
      id: 'white_label',
      label: 'Customer name',
      minWidth: width.extraLarge,
      align: 'left'
    },
    {
      id: '	customer_address',
      label: 'Customer Address',
      minWidth: width.large,
      align: 'left'
    },
    {
      id: 'customer_contact_person',
      label: 'Contact Person',
      minWidth: width.regular,
      align: 'left'
    },
    {
      id: 'customer_email',
      label: 'Email',
      minWidth: width.extraLarge,
      align: 'left'
    },
    {
      id: 'customer_phone',
      label: 'Phone Number',
      minWidth: width.regular,
      align: 'left'
    },
    {
      id: '_',
      label: 'Total number of devices',
      minWidth: width.regular,
      align: 'left'
    }
  ]

  useEffect(() => {
    setOffset(0)
    setLimit(10)
    setCurrentPage(0)
  }, [])

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage)
    const newOffset = newPage * limit
    setOffset(newOffset)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value))
    setCurrentPage(0)
  }

  const EmptyMessage = (filterValue: string) => {
    switch (filterValue) {
      case 'AllDevices':
        return 'There are No Devices that are registered at the momentd'
      case 'AllCustomer':
        return 'There are No Customers / MSOs registered at the moment'
      case 'Online':
        return 'There are No Online devices at the moment'
      case 'Offline':
        return 'There are No Offline devices at the moment'
      case 'notRegistered':
        return 'There are No non registered devices at the moment'
      case 'needUpdate':
        return 'There are No devices which requires FW updates at the moment'
      default:
        return 'No Data found'
    }
  }

  useEffect(() => {
    if (!!customersStats && customersStats.length > 0) {
      const currentPageData = customersStats.slice(offset, offset + limit)
      setCustomers(currentPageData)
    }
  }, [customersStats, offset, limit])

  return (
    <div style={{ overflow: 'hidden', width: '100%' }}>
      <TableContainer
        sx={{
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          overflowX: 'auto',

          '&::-webkit-scrollbar': {
            width: 0,
            height: 0
          }
        }}
      >
        <CustomTable className='table-wrapper' stickyHeader aria-label='sticky table'>
          {customersStatsLoading ? (
            <Loader />
          ) : !!customersStats && customersStats.length !== 0 ? (
            <>
              <TableHead>
                <CustomTableHeaderRow sx={{ borderRadius: 15, height: '60px' }}>
                  {columns.map((column: Column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        fontWeight: '600',
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        background: 'transparent'
                        // textTransform: 'uppercase'
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </CustomTableHeaderRow>
              </TableHead>
            </>
          ) : (
            <div
              className='d-flex justify-center items-center text-lg mt-2'
              style={{ color: getColorCode('redToBlue') }}
            >
              {/* <NoDataFound image={<EmptyData />} message={EmptyMessage(filteringValue)} /> */}

              {EmptyMessage(filteringValue)}
            </div>
          )}
          <TableBody>
            {!customersStatsLoading &&
              !!customers &&
              customers.length !== 0 &&
              customers.map((device: any, index: number) => {
                const colorCode = getColorCode('orangeBlue')
                return (
                  <CustomTableRow styledcolor={colorCode} key={`device_row${index}`}>
                    <StyledTableCell>{device.white_label || '-'}</StyledTableCell>
                    <StyledTableCell>{device.customer_address || '-'}</StyledTableCell>
                    <StyledTableCell>{device.customer_contact_person || '-'}</StyledTableCell>
                    <StyledTableCell>{device.customer_email || '-'}</StyledTableCell>
                    <StyledTableCell> {device.customer_phone || '-'}</StyledTableCell>
                    <StyledTableCell>{'-'}</StyledTableCell>
                  </CustomTableRow>
                )
              })}
          </TableBody>
        </CustomTable>
      </TableContainer>

      {!customersStatsLoading && !!customersStats && customersStats.length !== 0 && (
        <TablePagination
          className='customers_footer'
          sx={{ marginRight: 0 }}
          rowsPerPageOptions={[6, 10, 20, 50]}
          component='div'
          count={!!customersStats ? customersStats.length : 0}
          rowsPerPage={limit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}

export default CustomerTable
