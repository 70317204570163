import { WebMonitoring, TimeSupervision, ConnectedDevices, SocialSupervision } from '../../models/Xcpem'
import { useMutation, useQuery } from '@tanstack/react-query'
import xcpemCyber from '../../axiosInstances/xcpemCyber'
import { notifyError, notifySuccess } from '../../components/Toaster'
import xcpemSec from '../../axiosInstances/xcpemSec'
import { cyberApiUrl } from '../../app-config'

interface VerifyPinProps {
  pincode: string
}

export const useDeletePCSetting = () => {
  const deleteRequest = async (deviceId?: string) => {
    try {
      const email: any = sessionStorage.getItem('email')
      await xcpemSec.delete(
        `${cyberApiUrl}/api/v1/panel/pc_settings?email=${encodeURIComponent(email)}&device_id=${deviceId}`
      )
      notifySuccess('Successfully removed PC settings')
      // return response.data
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        notifyError(`PC Setting: ${error?.response?.data?.message || error?.message}`)
      }
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error, isSuccess } = useMutation(deleteRequest)

  return { mutate, isLoading, error, isSuccess }
}

export const useWebMonitoring = (
  from: string,
  email: string,
  deviceFilter?: any
): {
  data: any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['web-monitoring'],
    async () => {
      const endpoint = `/api/v1/panel/supervision/web?from_date=${from}&email=${encodeURIComponent(email)}${
        deviceFilter && deviceFilter !== '*' ? `&device_id=${deviceFilter}` : ''
      }`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Web monitoring: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useTimeSupervision = (
  from: string,
  email: string,
  deviceFilter?: any
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['time-monitoring'],
    async () => {
      const endpoint = `/api/v1/panel/supervision/time?from_date=${from}&email=${encodeURIComponent(email)}${
        deviceFilter && deviceFilter !== '*' ? `&device_id=${deviceFilter}` : ''
      }`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Time monitoring: ${error?.response?.data?.message || error?.message}`)
          throw new Error('Failed to fetch web monitoring data')
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useSocialSupervision = (
  from: string,
  email: string,
  deviceFilter?: any
): {
  data: any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['social-monitoring'],
    async () => {
      if (from && email) {
        const endpoint = `/api/v1/panel/supervision/social?from_date=${from}&email=${encodeURIComponent(email)}${
          deviceFilter && deviceFilter !== '*' ? `&device_id=${deviceFilter}` : ''
        }`
        try {
          const response = await xcpemCyber.get<any>(endpoint)
          return response.data || undefined
        } catch (error: any) {
          if (error?.response?.status === 401) {
            return error?.response?.status
          } else {
            notifyError(`Social monitoring: ${error?.response?.data?.message || error?.message}`)
            throw new Error('Failed to fetch social monitoring data')
          }
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useParentalConnectedDevices = (
  from: string,
  email: string | null
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['pcconnecteddevices'],
    async () => {
      if (email === null) return undefined

      const endpoint = `/api/v2/panel/devices?from_date=${from}&email=${encodeURIComponent(email)}`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`PC connected devices: ${error?.response?.data?.message || error?.message}`)
          throw new Error('Failed to fetch connected devices')
        }
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useGeneralizedSupervision = (
  from: string,
  email: string,
  type: 'web' | 'time' | 'social' | 'devices'
): {
  data: WebMonitoring | TimeSupervision | ConnectedDevices | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['generalized-supervision'],
    async () => {
      const endpointType = type !== 'devices' ? `supervision/${type}` : `devices`

      const endpoint = `/api/v1/panel/${endpointType}?from_date=${from}&email=${encodeURIComponent(email)}`
      try {
        const response = await xcpemCyber.get<WebMonitoring | TimeSupervision | ConnectedDevices>(endpoint)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Supervision: ${error?.response?.data?.message || error?.message}`)
          throw new Error(`Failed to fetch ${type}`)
        }
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useDeviceSupervisionActive = (
  device_list: number
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['is-device-monitoring-enabled'],
    async () => {
      const endpoint = `/api/v1/parental_control/devices/monitor?device_list=${device_list}`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Devices monitor:${error?.response?.data?.message || error?.message}`)

          throw new Error(`Failed to fetch device monitoring status`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useTurnOffSafeSearch = (): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['turn-off-safe-search'],
    async () => {
      const email = sessionStorage.getItem('email')

      if (!email) return undefined

      const url = `/api/v1/panel/family_shield/safe_search?email=${encodeURIComponent(email)}`

      try {
        const response = await xcpemCyber.get<any>(url)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Safe search: ${error?.response?.data?.message || error?.message}`)

          throw new Error(`Failed to update services`)
        }
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

// verify parental PIN

const verifyParentalPin = async ({ pincode }: any) => {
  const body = new URLSearchParams()
  body.append('pincode', pincode)
  const pin_verificaiton_url = `/api/v1/parental_control/pin/verify`
  const response = await xcpemCyber.post(pin_verificaiton_url, body)
  return response.data
}

export const usePinVerification = () => {
  return useMutation(verifyParentalPin)
}
