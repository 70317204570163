import { CyberStatus, RouterStats } from '../../models/Xcpem'
import { useMutation, useQuery } from '@tanstack/react-query'
import xcpemCyber from '../../axiosInstances/xcpemCyber'
import { notifyError, notifySuccess } from '../../components/Toaster'
// import axios from 'axios'

export const useCyberStatusForUser = (
  email: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cyber-security-services-status'],
    async () => {
      const endpoint = `/api/v1/panel/family_shield/all?email=${encodeURIComponent(email)}`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Family_shield: ${error?.response?.data?.message || error?.message}`)

          throw new Error('Failed to fetch cyber status for user')
        }
      }
    },
    {
      retry: 1
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useCyberStatusForUserOff = () => {
  const deleteRequest = async (email: any) => {
    const endpoint = `https://${process.env.REACT_APP_CYBER}/api/v1/panel/family_shield/all?email=${encodeURIComponent(
      email
    )}`
    try {
      const response: any = await xcpemCyber.delete<any>(endpoint)
      notifySuccess(response?.data?.message || 'Successfully removed setting for user')
      // return response.data
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        notifyError(`Status Off: ${error?.response?.data?.message || error?.message}`)
      }
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(deleteRequest)

  return { mutate, isLoading, error }
}
export const useCyberStatusForAdBlock = () => {
  const deleteRequest = async (email: any) => {
    const endpoint = `https://${
      process.env.REACT_APP_CYBER
    }/api/v1/panel/family_shield/adblock?email=${encodeURIComponent(email)}`
    try {
      const response: any = await xcpemCyber.delete<any>(endpoint)
      notifySuccess(response?.data?.message || 'Successfully removed setting for user')

      // return response.data
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        notifyError(`Ad block: ${error?.response?.data?.message || error?.message}`)
      }
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(deleteRequest)

  return { mutate, isLoading, error }
}
export const useCyberStatusForSafeSearch = () => {
  const deleteRequest = async (email: any) => {
    const endpoint = `https://${
      process.env.REACT_APP_CYBER
    }/api/v1/panel/family_shield/safe_search?email=${encodeURIComponent(email)}`
    try {
      const response: any = await xcpemCyber.delete<any>(endpoint)

      notifySuccess(response?.data?.message || 'Successfully removed setting for user')
      // return response.data
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        notifyError(`Safe search: ${error?.response?.data?.message || error?.message}`)
      }
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(deleteRequest)

  return { mutate, isLoading, error }
}

export const useCyberSendReportRequest = () => {
  const putRequest = async ({ email, device_id, payload }: any) => {
    const endpoint = `https://${
      process.env.REACT_APP_CYBER
    }/api/v1/panel/sendreport?device_id=${device_id}&email=${encodeURIComponent(email)}`
    try {
      const response: any = await xcpemCyber.put<any>(endpoint, payload)

      return response.data
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        notifyError(`Safe search: ${error?.response?.data?.message || error?.message}`)
      }
    }
  }

  const { mutate, isLoading, error, isSuccess } = useMutation(putRequest)

  return { mutate, isLoading, error, isSuccess }
}

export const useCyberThreats = (
  email: string,
  mac: string,
  deviceId?: any,
  limit?: number,
  currentPage?: any
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['cyberThreats'],
    async () => {
      const endpoint = `/api/v1/panel/threats?email=${encodeURIComponent(email)}&router_macid=${mac}${
        deviceId && deviceId !== '*' ? `&device_id=${deviceId}` : ''
      }&page=${currentPage + 1}`
      const limitResults = limit ? `&offset=${limit}` : ''

      try {
        const response = await xcpemCyber.get<any>(endpoint + limitResults)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Cyber threats: ${error?.response?.data?.message || error?.message}`)

          throw new Error('Failed to fetch cyber threats for user')
        }
      }
    },
    { cacheTime: 0, retry: 1 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useRouterStats = (
  email: string,
  mac: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['router-stats'],
    async () => {
      const endpoint = `/api/v1/panel/routerstats?router_macid=${mac}&email=${encodeURIComponent(email)}`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Router stats: ${error?.response?.data?.message || error?.message}`)

          throw new Error('Failed to fetch router statistics for user')
        }
      }
    },
    {
      cacheTime: 0,
      retry: 1
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

// Enable safesearch  https://${process.env.REACT_APP_CYBER}/api/v1/parental_control/family_shield/safe_search
// Enable family shield  https://${process.env.REACT_APP_CYBER}/api/v1/parental_control/family_shield/all
// Enable adblock https://${process.env.REACT_APP_CYBER}/api/v1/parental_control/family_shield/adblock
// PUT for enabling , DELETE for disabling
