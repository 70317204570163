import React from "react";

const AppLogo = () => {
  return (
    <svg
      width="286"
      height="43"
      viewBox="0 0 286 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M155.47 38.5389C152.877 38.5389 150.767 36.5 150.767 33.9954V8.23535C150.767 5.73076 152.877 3.69189 155.47 3.69189C158.062 3.69189 160.172 5.73076 160.172 8.23535V33.9954C160.172 36.5 158.073 38.5389 155.47 38.5389Z"
        fill="#091554"
      />
      <path
        d="M171.944 31.1386C173.015 29.6173 175.071 29.1722 176.774 29.9898C178.842 30.9834 181.262 31.5423 183.629 31.5423C186.157 31.5423 188.117 30.3521 188.117 28.6754C188.117 23.5731 171.119 25.3946 171.119 14.4862C171.119 7.98671 177.631 3.92969 184.572 3.92969C187.078 3.92969 189.713 4.43681 191.963 5.18198C194.426 5.9996 195.455 8.82502 194.041 10.9363C192.991 12.4991 190.956 13.089 189.167 12.3956C187.967 11.9299 186.65 11.6297 185.3 11.6297C182.698 11.6297 180.523 12.613 180.523 14.3517C180.523 18.7606 197.522 17.0115 197.522 28.7479C197.522 35.2474 190.934 39.232 183.554 39.232C180.052 39.232 176.399 38.3937 173.09 36.8205C171.055 35.858 170.358 33.3845 171.633 31.5733L171.944 31.1386Z"
        fill="#091554"
      />
      <path
        d="M223.882 3.92969C234.518 3.92969 242.551 11.8264 242.551 21.6171C242.551 31.4077 234.518 39.232 223.882 39.232C213.246 39.232 205.212 31.4077 205.212 21.6171C205.212 11.8264 213.246 3.92969 223.882 3.92969ZM223.882 30.8489C229.377 30.8489 233.147 26.7297 233.147 21.6171C233.147 16.5044 229.387 12.3232 223.882 12.3232C218.387 12.3232 214.617 16.5147 214.617 21.6171C214.617 26.7194 218.376 30.8489 223.882 30.8489Z"
        fill="#091554"
      />
      <path
        d="M281.476 8.46304C279.356 6.27928 275.414 3.69189 268.687 3.69189C261.95 3.69189 258.019 6.28963 255.898 8.46304C251.453 13.0272 251.442 19.0506 251.496 20.3236V33.9437C251.496 36.4793 253.617 38.5285 256.241 38.5285C258.865 38.5285 260.986 36.4793 260.986 33.9437V20.2201L260.975 19.9407C260.975 19.9097 260.9 16.722 262.807 14.7556C264.028 13.5033 265.999 12.872 268.687 12.872C271.344 12.872 273.304 13.4929 274.525 14.7142C276.506 16.7013 276.399 19.9407 276.399 19.9407L276.389 33.9437C276.389 36.4793 278.509 38.5285 281.134 38.5285C283.758 38.5285 285.879 36.4793 285.879 33.9437V20.3236C285.943 19.0506 285.921 13.0272 281.476 8.46304Z"
        fill="#091554"
      />
      <path
        d="M66.3845 33.8398C68.5053 36.0236 72.4471 38.611 79.1737 38.611C85.9003 38.611 89.8421 36.0132 91.9629 33.8398C96.408 29.2757 96.4188 23.2523 96.3652 21.9793V8.35926C96.3652 5.82362 94.2444 3.77441 91.6201 3.77441C88.9959 3.77441 86.8751 5.82362 86.8751 8.35926V22.0828L86.8858 22.3622C86.8858 22.3932 86.9608 25.5809 85.0542 27.5473C83.8331 28.7996 81.8622 29.4309 79.1737 29.4309C76.5173 29.4309 74.5572 28.81 73.3361 27.5887C71.3545 25.6016 71.4616 22.3622 71.4616 22.3622L71.4723 8.35926C71.4723 5.82362 69.3515 3.77441 66.7273 3.77441C64.103 3.77441 61.9822 5.82362 61.9822 8.35926V21.9793C61.9286 23.2523 61.9394 29.2757 66.3845 33.8398Z"
        fill="#091554"
      />
      <path
        d="M136.126 8.46304C134.005 6.27928 130.063 3.69189 123.337 3.69189C116.61 3.69189 112.668 6.28963 110.548 8.46304C106.102 13.0272 106.092 19.0506 106.145 20.3236V33.9437C106.145 36.4793 108.266 38.5285 110.89 38.5285C113.515 38.5285 115.635 36.4793 115.635 33.9437V20.2201L115.625 19.9407C115.625 19.9097 115.55 16.722 117.456 14.7556C118.677 13.5033 120.648 12.872 123.337 12.872C125.993 12.872 127.953 13.4929 129.174 14.7142C131.156 16.7013 131.049 19.9407 131.049 19.9407L131.038 33.9437C131.038 36.4793 133.159 38.5285 135.783 38.5285C138.407 38.5285 140.528 36.4793 140.528 33.9437V20.3236C140.582 19.0506 140.571 13.0272 136.126 8.46304Z"
        fill="#091554"
      />
      <path
        d="M46.6656 34.5959C46.9655 35.0099 47.4368 35.2273 47.9188 35.2273C48.2187 35.2273 48.5294 35.1341 48.7971 34.9582C53.4565 31.7912 56.2415 26.6165 56.2415 21.1105C56.2415 15.6046 53.4565 10.4299 48.7971 7.26291C48.1009 6.79718 47.1476 6.96277 46.6656 7.62514C46.1836 8.28751 46.3443 9.21896 47.0405 9.68469C50.8858 12.3031 53.1887 16.5671 53.1887 21.1105C53.1887 25.654 50.8858 29.918 47.0405 32.5364C46.3443 33.0021 46.1836 33.9232 46.6656 34.5959Z"
        fill="#FF6650"
      />
      <path
        d="M43.0131 30.4871C42.4668 30.4871 41.9313 30.1973 41.6528 29.6902C41.2564 28.9658 41.5456 28.0757 42.2954 27.6928C44.8447 26.4094 46.4193 23.8842 46.4193 21.1105C46.4193 18.3368 44.834 15.8116 42.2954 14.5282C41.5456 14.1453 41.2564 13.2552 41.6528 12.5308C42.0491 11.8063 42.9702 11.5269 43.72 11.9098C47.2762 13.7106 49.4934 17.2398 49.4934 21.1208C49.4934 25.0019 47.2869 28.5311 43.72 30.3319C43.4951 30.4354 43.2487 30.4871 43.0131 30.4871Z"
        fill="#FF6650"
      />
      <path
        d="M38.7393 24.8881C40.8985 24.8881 42.6489 23.1969 42.6489 21.1106C42.6489 19.0243 40.8985 17.333 38.7393 17.333C36.5801 17.333 34.8297 19.0243 34.8297 21.1106C34.8297 23.1969 36.5801 24.8881 38.7393 24.8881Z"
        fill="#FF6650"
      />
      <path
        d="M25.7041 21.1933L38.354 7.37662C39.9714 5.4102 39.6394 2.55373 37.615 0.990947C35.5798 -0.571835 32.6235 -0.250998 31.0062 1.70507L19.6952 13.9072L8.38415 1.71542C6.76676 -0.250999 3.81048 -0.571834 1.77535 1.0013C-0.259769 2.56408 -0.591815 5.42055 1.03628 7.38697L13.6862 21.2036L1.02557 34.8443C-0.591816 36.8107 -0.259768 39.6672 1.76464 41.23C3.79977 42.7928 6.75605 42.4719 8.37344 40.5159L19.6952 28.4897L31.0062 40.5159C32.6235 42.4823 35.5798 42.8031 37.615 41.23C39.6501 39.6672 39.9821 36.8107 38.354 34.8443L25.7041 21.1933Z"
        fill="#091554"
      />
    </svg>
  );
};

export default AppLogo;
