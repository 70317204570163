import { DeviceInformationState, DeviceInformationAction } from './types'

const initialState: DeviceInformationState = {
  data: null,
  loading: false,
  error: null
}

const deviceInformationReducer = (state = initialState, action: DeviceInformationAction): DeviceInformationState => {
  switch (action.type) {
    case 'GET_DEVICE_INFORMATION_REQUEST':
      return {
        ...state,
        loading: true,
        error: null
      }
    case 'GET_DEVICE_INFORMATION_SUCCESS':
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null
      }
    case 'GET_DEVICE_INFORMATION_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default deviceInformationReducer
