import axios from 'axios'
import { notifyError } from '../../components/Toaster'

// const URL = 'https://${process.env.REACT_APP_CYBER}/api/v2/panel/devices?from_date=2023-06-21T17%3A00%3A00.000Z&email='
const URL = `https://${process.env.REACT_APP_CYBER}/api/v1/panel/`

export const pcSettingByGateway = async (gw_uid: any) => {
  const access_token = sessionStorage.getItem('access_token')
  try {
    const response = await axios.get(`${URL}pc_settings?gw_uid=${gw_uid}`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
    return response.data
  } catch (error: any) {
    if (error?.response?.status === 401) {
      return error?.response?.status
      // localStorage.clear()
      // sessionStorage.clear()
      // window.location.href = '/#/login'
    } else {
      notifyError(`PC Settings: ${error?.response?.data?.message || error?.message}`)
    }
    return null
  }
}
