import { Progress } from 'antd'
import { useEffect, useState } from 'react'
import './styles.scss'
import { getColorCode } from '../../../utils/helper'

interface Props {
  value: number
  title: string
  percentage: number
  animate?: boolean
  onClick?: any
  active?: any
}

const WebSuperVisionTile = ({ value, title, percentage, animate, onClick, active }: Props) => {
  const greenColor: any = getColorCode('greenDarkPink')
  const redColor: any = getColorCode('redCyan')

  return (
    <div className='web-supervision-till-wrapper' onClick={onClick}>
      <Progress
        type='circle'
        size={100}
        strokeColor={active === title ? redColor : greenColor}
        className={`position-relative ${onClick ? 'cursor-pointer' : ''}`}
        percent={percentage}
        format={() => (
          <div className='inner-circle'>
            <div
              className={`${animate ? 'animate-pulse' : ''} web-supervision-circle`}
              style={{ backgroundColor: `${active === title ? greenColor : redColor}` }}
            >
              {value >= 1000 ? (value / 1000).toFixed(1) + 'K' : value}
            </div>
          </div>
        )}
      />
      {/* <CircularProgress
        value={percentage}
        thickness="4px"
        size="100px"
        color="#A3D696"
        className="relative"
      >
        <div className="absolute top-0 left-0 right-0 bottom-0  flex flex-col justify-center items-center gap-y-[15px]">
          <div className={`${animate ? 'animate-pulse' : ''} web-supervision-circle rounded-full bg-alerts-critical tex-white`}>
            {value >= 1000 ? (value /1000).toFixed(1) + "K" : value}
          </div>
        </div>
      </CircularProgress> */}
      <span className={`web-supervision-till-title`}>{title}</span>
    </div>
  )
}

export default WebSuperVisionTile
