import React, { createContext, useContext, useState, ReactNode } from 'react'

interface SessionContexProps {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

const SessionContex = createContext<SessionContexProps | undefined>(undefined)

export const SessionContexProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  return <SessionContex.Provider value={{ openModal, setOpenModal }}>{children}</SessionContex.Provider>
}

export const useSessionContex = (): SessionContexProps => {
  const context = useContext(SessionContex)
  if (!context) {
    throw new Error('SessionContex must be used within an SessionContexProvider')
  }
  return context
}
