/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import GroupImage from '../../../common-images/app-logo/customer-images/GroupImage'
import WavesImage from '../../../common-images/app-logo/customer-images/wavesImage'
import OnlineOfflineImage from '../../../common-images/onlineOfflineImage'
// import Alerts from '../../../common-images/app-logo/customer-images/Alerts'
import Devices from '../../../common-images/app-logo/customer-images/Devices'
import Firmware from '../../../common-images/app-logo/customer-images/Firmware'
import LastSeenOnline from '../../../common-images/app-logo/customer-images/lastSeenOnline'
import MasterUnit from '../../../common-images/app-logo/customer-images/MasterUnit'
import ReStatus from '../../../common-images/app-logo/customer-images/ReStatus'
import Satellites from '../../../common-images/app-logo/customer-images/Satellites'
import Services from '../../../common-images/app-logo/customer-images/Services'
import UpTime from '../../../common-images/app-logo/customer-images/upTime'
import PieCharts from '../../../components/Charts/pieChart'
import { fetchGatewayCount } from '../../../redux/fetchConnectedDevice/fectchConnectedDevice'
import { useGatewayServices } from '../../../hook/useGatewayServices'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import { useSetelliteAccount } from '../../../hook/useSetelliteAccount'
import { useSelector } from 'react-redux'
import { CustomersRootState } from '../../../redux/customerStatistics/types'
import moment from 'moment'
import { Tooltip } from 'antd'
import { useConnectedDevices } from '../../../hook/useConnectedDevices'
import { Skeleton } from '@mui/material'
import { useSessionContex } from '../../../SessionContex'
import { useWifiQoE } from '../../../hook/useWifiQoE'
import { getColorCode } from '../../../utils/helper'

function getNextVersion(version: any) {
  if (typeof version === 'string') {
    const stringValue = version.toString()
    const parts = stringValue.split('.')
    parts[2] = (parseInt(parts[2]) + 1).toString()
    const incrementedValue = parts.join('.')
    return incrementedValue
  } else {
    return version
  }
}

const CustometDeviceStatus: any = ({
  gatewayInfo,
  gatewayFeaching,
  gatewayRefetch,
  wanMqtt,
  setWanMqtt
}: // wanConnection,
// fetchingRouterSettings
any) => {
  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const { openModal, setOpenModal } = useSessionContex()

  const formattedTimestamp = moment.utc().format('YYYYMMDDTHH:mm:ss')
  const timestamp = moment.utc(formattedTimestamp).toISOString()
  const [wanConnection, setWanConnection] = useState('')
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  const [isLo, setIsLo] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const { data: gatewayInfo, isFetching: gatewayFeaching, refetch: gatewayRefetch }: any = useGatewayInfo(activeGw_Uid) //User de
  const {
    data: networkMapData,
    refetch: refetchNetworkMap,
    isFetching: deviceFeatching
  } = useConnectedDevices(activeGw_Uid, timestamp)

  const {
    data: QoEData,
    refetch: refetchQoE,
    isFetching: featchingQoE
  } = useWifiQoE({
    dev_mesh_role: gatewayInfo?.dev_mesh_role,
    parent_gw_uid: gatewayInfo?.parent_gw_uid,
    wan_mac: gatewayInfo?.wan_mac,
    gw_uid: activeGw_Uid
  })

  const { data: services, refetch, isFetching } = useGatewayServices(activeGw_Uid)

  const {
    data: seteliteData,
    refetch: seteliteDataRefetch,
    isFetching: seteliteFeatching
  } = useSetelliteAccount(gatewayInfo?.email, gatewayInfo?.dev_mesh_role)

  const [result, setResult] = useState('0d 0h 0m')
  const [isOnline, setIsOnline] = useState(false)
  const [activeAndOnlineDevices, setActiveAndOnlineDevices] = useState<string>('')
  const [servicesCounts, setServicesCounts] = useState('0')
  const [setelliteCounts, setSetelliteCounts] = useState('')
  const [activeSetellite, setActiveSetellite] = useState(0)
  const [totalSetellite, setTotalSetellite] = useState(0)
  const [mqtt, setMqtt] = useState<any>(null)
  const [QoE, setQoE] = useState<any>('N/A')
  const [QoEId, setQoEId] = useState<any>(0)
  const [connectedToMqtt, setConnectedToMqtt] = useState<boolean>(false)
  const [fetchingRouterSettings, setFetchingRouterSettings] = useState<boolean>(true)

  // const currentUTCTimestamp= currentISOTime()
  const topic = `/router/${
    gatewayInfo?.dev_mesh_role === 'CAP' ? gatewayInfo?.gw_uid : gatewayInfo?.parent_gw_uid
  }/command`
  const topic2 = `/router/${
    gatewayInfo?.dev_mesh_role === 'CAP' ? gatewayInfo?.gw_uid : gatewayInfo?.parent_gw_uid
  }/resp`
  const topic3 = `/router/${
    gatewayInfo?.dev_mesh_role === 'CAP' ? gatewayInfo?.gw_uid : gatewayInfo?.parent_gw_uid
  }/site_survey`

  const message = JSON.stringify({
    cmd: 'getsysconfig',
    callid: 3,
    response_topic: 'resp',
    op: 'wizard',
    params: ['cell_data', 'access_method']
  })
  const initializeMqtt = async () => {
    if (!activeGw_Uid) return
    const JsMqttClientImpl = (await import('../../../mqtt')).JsMqttClientImpl
    setMqtt(new JsMqttClientImpl())
  }
  const id = gatewayInfo?.gw_uid
  useEffect(() => {
    setConnectedToMqtt(false)
    setFetchingRouterSettings(true)
    setWanConnection('')
    setWanMqtt(true)
    if (gatewayInfo && gatewayInfo?.is_online && !connectedToMqtt) {
      mqtt
        ?.connect()
        .then(() => {
          setConnectedToMqtt(true)
          mqtt.subscribeToTopics([topic2, topic, topic3])
          mqtt.sendMessage({ message, topic })
        })
        .catch((err: any) => {
          mqtt.disconnect()
          setWanMqtt(false)
          setTimeout(() => {
            initializeMqtt()
          }, 500)
        })
    } else if (gatewayInfo && !gatewayInfo?.is_online) {
      setFetchingRouterSettings(false)
      setWanConnection('N/A')
    }
    if (gatewayInfo && gatewayInfo?.is_online && connectedToMqtt) {
      setTimeout(() => {
        mqtt.sendMessage({ message, topic })
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mqtt, id])

  useEffect(() => {
    // console.log('=======resM', mqtt)

    if (mqtt) {
      mqtt?.addMessageListener((message: any) => {
        const res = JSON.parse(message)
        if (res && res?.result === 'OK') {
          if (res && res?.params && res?.params?.access_method) {
            setTimeout(() => {
              setWanConnection(
                res?.params?.access_method === 'ethernet'
                  ? `WAN${res?.params?.cell_data?.sim_status === 'READY' ? ' with LTE Backup' : ''}`
                  : res?.params?.access_method === 'lte'
                  ? `LTE ${res?.params?.cell_data?.connection_label || ''}`
                  : '-'
              )
              setFetchingRouterSettings(false)
              mqtt.disconnect()
              setWanMqtt(false)
              setConnectedToMqtt(false)
            }, 1000)
          }
        }
      })
    }
  }, [mqtt, id])

  useEffect(() => {
    setIsLo(true)
    if (activeGw_Uid !== '') {
      // gatewayRefetch()
      refetch()
      refetchNetworkMap()
      setServicesCounts('0')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGw_Uid])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps

    setLastSeen(gatewayInfo?.last_seen)
    const intervalId = setInterval(() => {
      setLastSeen(gatewayInfo?.last_seen)
    }, 1 * 60 * 1000) // 2 minutes in milliseconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId)
  }, [gatewayInfo])

  const convertSecToDay = (n: number) => {
    if (n > 0) {
      const day = Math.floor(n / (24 * 3600))

      n = n % (24 * 3600)
      const hour = Math.floor(n / 3600)

      n %= 3600
      const minutes = Math.floor(n / 60)
      setResult(`${day || 0}d ${hour || 0}h ${minutes || 0}m`)
    } else {
      setResult(``)
    }
  }
  const setLastSeen = (targetTime: number) => {
    const localTimeObj = new Date()
    // const currentTime = moment(localTimeObj).utc().format('YYYY-MM-DD HH:mm')
    const lastseenDate = moment(targetTime).format('YYYY-MM-DD HH:mm')
    const preTime = moment(localTimeObj).subtract(2, 'minute').utc().format('YYYY-MM-DD HH:mm')
    const nextTime = moment(localTimeObj).add(2, 'minute').utc().format('YYYY-MM-DD HH:mm')
    // if (currentTime === lastseenDate) {
    if (preTime <= lastseenDate && nextTime >= lastseenDate) {
      setIsOnline(true)
    } else {
      setIsOnline(false)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setelliteCount()

    // setTimeout(() => {
    //   setIsLo(false)
    // }, 1000)
  }, [seteliteData])

  useEffect(() => {
    if (activeGw_Uid !== '') {
      servicesCount()
    }
    // refetch()
    // setIsLo(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, activeGw_Uid])

  useEffect(() => {
    getDeviceCount()
  }, [networkMapData])

  useEffect(() => {
    // getDeviceCount()
    if (gatewayInfo === 401) {
      !openModal && setOpenModal(true)
    } else if (!!activeGw_Uid) {
      convertSecToDay(gatewayInfo?.uptime)
      seteliteDataRefetch()
      refetchQoE()
      setTimeout(() => {
        initializeMqtt()
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayInfo, activeGw_Uid])

  useEffect(() => {
    if (!gatewayFeaching && !deviceFeatching && !isFetching && !seteliteFeatching && !featchingQoE) {
      setTimeout(() => {
        activeGw_Uid !== '' && setIsLo(false)
      }, 1500)
    }
  }, [gatewayFeaching, deviceFeatching, featchingQoE, isFetching, seteliteFeatching])

  const setelliteCount = () => {
    if (!activeGw_Uid) return
    if (!!seteliteData && seteliteData.length > 0) {
      // const allSetellite = seteliteData.length
      const allSetellite = seteliteData.filter((filterdata: any) => filterdata.parent_gw_uid === activeGw_Uid)
      const activeSetellite = allSetellite.filter((filterdata: any) => filterdata.is_online === true).length
      setSetelliteCounts(`${activeSetellite}/${allSetellite?.length}`)
      setActiveSetellite(activeSetellite)
      setTotalSetellite(allSetellite?.length)
    } else {
      setSetelliteCounts(`0/0`)
    }
  }

  const servicesCount = () => {
    if (!!services) {
      const srvProperties = Object.keys(services).filter(key => key.startsWith('srv_'))
      const srvPropertiesCount = srvProperties.length
      const trueSrvPropertiesCount = Object.keys(services).filter(
        key => key.startsWith('srv_') && services[key] === true
      ).length

      setServicesCounts(`${trueSrvPropertiesCount}/${srvPropertiesCount}`)
    } else {
      setServicesCounts(`0/0`)
    }
  }
  const getDeviceCount = async () => {
    // const formattedTimestamp = moment.utc().subtract(1, 'days').format('YYYYMMDDTHH:mm:ss')
    // const timestamp = moment.utc(formattedTimestamp).toISOString()

    // if (gatewayInfo?.email) {
    //   const result = await fetchGatewayCount(gatewayInfo?.email, '2023-10-30T18:30:00.000Z')

    //   if (result !== null) {
    //     const filteredObjects = result.devices
    //     const connectedDevicesCount = filteredObjects.length
    //     const activeDevice = filteredObjects.filter((filterdata: any) => filterdata.is_active === true).length
    //     setActiveAndOnlineDevices(`${activeDevice}/${connectedDevicesCount} Online`)
    //   } else {
    //     setActiveAndOnlineDevices(`0/0 Online`)
    //   }
    // }

    if (gatewayInfo?.email) {
      if (networkMapData) {
        const connectedDevicesCount = networkMapData?.length
        const activeDevice = networkMapData?.filter((obj: any) => obj.client_conn_type !== null)?.length
        setActiveAndOnlineDevices(`${activeDevice}/${connectedDevicesCount}`)
      } else {
        setActiveAndOnlineDevices(`0/0`)
      }
    }
  }

  useEffect(() => {
    if (gatewayInfo && gatewayInfo?.is_online) {
      if (QoEData !== null && QoEData) {
        if (QoEData >= 2.5) {
          setQoEId(4)
          setQoE('Excellent')
        } else if (QoEData >= 2 && QoEData < 2.5) {
          setQoEId(3)
          setQoE('Good')
        } else if (QoEData >= 1.5 && QoEData < 2) {
          setQoEId(2)
          setQoE('Fair')
        } else if (QoEData < 1.5) {
          setQoEId(1)
          setQoE('Poor')
        } else {
          setQoEId(0)
          setQoE('N/A')
        }
      } else {
        setQoEId(0)
        setQoE('N/A')
      }
    } else {
      setQoEId(0)
      setQoE('N/A')
    }
  }, [QoEData])
  return (
    <div className='overview-tab-cls me-3'>
      {/* <Tooltip placement='top' title='Coming Soon'> */}
      <Card className='overview-tab d-flex items-center justify-center'>
        <CardHeader
          avatar={<GroupImage />}
          action={
            isLo ? (
              <Skeleton variant='circular' width={'100%'} />
            ) : (
              <div className='d-flex items-center justify-center' style={{ marginTop: '10px' }}>
                {[1, 2, 3, 4].map((obj: any, key: any) => (
                  <div
                    key={key}
                    style={{
                      margin: '3px'
                    }}
                  >
                    <OnlineOfflineImage
                      fillColor={
                        obj <= QoEId
                          ? QoEId === 0
                            ? getColorCode('darkGrayToGray')
                            : QoEId > 2
                            ? getColorCode('Online')
                            : getColorCode('yellowToYellow')
                          : getColorCode('darkGrayToGray')
                      }
                    />
                  </div>
                ))}
              </div>
            )
          }
          title='QoE Score'
          subheader={isLo ? <Skeleton variant='text' width={'100%'} /> : `${QoE || '-'}`}
          className='qoe-action-box ps-4 w-full'
        />
      </Card>
      {/* </Tooltip> */}
      <Card className='overview-card all-other-box mt-3 d-flex'>
        <CardHeader
          avatar={<UpTime />}
          action={
            isLo ? (
              <Skeleton variant='circular' width={'100%'} />
            ) : gatewayInfo?.is_online === true ? (
              <OnlineOfflineImage fillColor={getColorCode('greenToCyan')} />
            ) : (
              <OnlineOfflineImage fillColor={getColorCode('redToCyan')} />
            )
          }
          title='Up Time'
          subheader={isLo ? <Skeleton variant='text' width={'80%'} /> : result}
          className='ps-4 status-box pe-4'
        />
      </Card>
      <Card className='overview-card all-other-box mt-3 d-flex'>
        <CardHeader
          avatar={<LastSeenOnline />}
          action={
            isLo ? (
              <Skeleton variant='circular' width={'100%'} />
            ) : gatewayInfo?.is_online === true ? (
              <OnlineOfflineImage fillColor={getColorCode('greenToCyan')} />
            ) : (
              <OnlineOfflineImage fillColor={getColorCode('redToCyan')} />
            )
          }
          title={`Last seen Online`}
          subheader={
            isLo ? (
              <Skeleton variant='text' width={'80%'} />
            ) : (
              `${moment
                .tz(moment.utc(gatewayInfo?.last_seen), timeZone)
                .format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm`)}`
            )
          }
          className='ps-4 status-box pe-4'
        />
      </Card>
      <Card className='overview-card all-other-box mt-3 d-flex'>
        <CardHeader
          avatar={<MasterUnit />}
          action={
            isLo ? (
              <Skeleton variant='circular' width={'100%'} />
            ) : gatewayInfo?.is_online === true ? (
              // && gatewayInfo?.dev_mesh_role === 'CAP'
              <OnlineOfflineImage fillColor={getColorCode('greenToGreen')} />
            ) : (
              <OnlineOfflineImage fillColor={getColorCode('redToGray')} />
            )
          }
          title='Master Unit'
          subheader={
            isLo ? (
              <Skeleton variant='text' width={'80%'} />
            ) : gatewayInfo?.is_online === true ? (
              //  && gatewayInfo?.dev_mesh_role === 'CAP'
              'Online'
            ) : (
              'Offline'
            )
          }
          className='ps-4 status-box pe-4'
        />
      </Card>
      <Card className='overview-card all-other-box mt-3 d-flex'>
        <CardHeader
          avatar={<Satellites />}
          action={
            // gatewayInfo?.is_online === true && gatewayInfo?.dev_mesh_role === 'NonCAP' ? (
            isLo ? (
              <Skeleton variant='circular' width={'100%'} />
            ) : totalSetellite !== 0 && activeSetellite === totalSetellite ? (
              <OnlineOfflineImage fillColor={getColorCode('greenToGreen')} />
            ) : (
              <OnlineOfflineImage fillColor={getColorCode('redToGray')} />
            )
          }
          title='Satellites'
          subheader={isLo ? <Skeleton variant='text' width={'80%'} /> : setelliteCounts}
          className='ps-4 status-box pe-4'
        />
      </Card>
      {/* <Tooltip placement='top' title='Coming Soon'> */}
      <Card className='overview-card all-other-box mt-3 d-flex'>
        <CardHeader
          avatar={<ReStatus />}
          action={
            fetchingRouterSettings ? (
              <Skeleton variant='circular' width={'100%'} />
            ) : (
              <OnlineOfflineImage
                fillColor={gatewayInfo?.is_online === true ? getColorCode('greenToCyan') : getColorCode('redToCyan')}
              />
            )
          }
          // title='RE Status'
          title='Primary Connection'
          subheader={fetchingRouterSettings ? <Skeleton variant='text' width={'80%'} /> : wanConnection}
          className='ps-4 status-box pe-4'
          // className='ps-4 status-box pe-4 coming-soon-cls'
        />
      </Card>
      {/* </Tooltip> */}
      <Card className='overview-card all-other-box mt-3 d-flex'>
        <CardHeader
          avatar={<Devices />}
          action={
            isLo ? (
              <Skeleton variant='circular' width={'100%'} />
            ) : (
              <OnlineOfflineImage
                fillColor={gatewayInfo?.is_online === true ? getColorCode('greenToCyan') : getColorCode('redToCyan')}
              />
            )
          }
          title='Devices'
          subheader={isLo ? <Skeleton variant='text' width={'80%'} /> : activeAndOnlineDevices}
          className='ps-4 status-box pe-4'
        />
      </Card>
      <Card className='overview-card all-other-box mt-3 d-flex'>
        <CardHeader
          avatar={<Services />}
          action={<OnlineOfflineImage fillColor={getColorCode('yellowToCyan')} />}
          title='Services'
          subheader={isLo ? <Skeleton variant='text' width={'80%'} /> : servicesCounts}
          className='ps-4 status-box pe-4'
        />
      </Card>
      <Card className='overview-card all-other-box mt-3 d-flex'>
        <CardHeader
          avatar={<Firmware />}
          action={
            isLo ? (
              <Skeleton variant='circular' width={'100%'} />
            ) : (
              <OnlineOfflineImage
                fillColor={
                  gatewayInfo?.up_to_date === true ? getColorCode('greenToGreen') : getColorCode('redToYellow')
                }
              />
            )
          }
          title='Firmware'
          subheader={
            isLo ? (
              <Skeleton variant='text' width={'80%'} />
            ) : gatewayInfo?.up_to_date === true ? (
              gatewayInfo?.fw_version
            ) : (
              `${gatewayInfo?.fw_version} / ${getNextVersion(gatewayInfo?.fw_version)}`
            )
          }
          className='ps-4 status-box pe-4'
        />
      </Card>

      <Card className='overview-card mt-3 '>
        <div className='mt-2'>
          <PieCharts gwuid={gatewayInfo?.gw_uid} />
        </div>
      </Card>
    </div>
  )
}

export default CustometDeviceStatus
