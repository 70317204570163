import React, { useEffect, useState } from 'react'
// import { useLocation } from 'react-router'
import CustomersActivities from './Activity'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import CustometDeviceStatus from './custometDeviceStatus'
import CustomerDeviceInfo from './Informations'
import SystemOverviewDetails from './System Overview'
import { useSelector } from 'react-redux'
import { CustomersRootState } from '../../../redux/customerStatistics/types'
import { useSessionContex } from '../../../SessionContex'

const CustomersOverview = () => {
  // const location = useLocation()
  const { openModal, setOpenModal } = useSessionContex()
  const [wanMqtt, setWanMqtt] = useState(true)
  // const [fetchingRouterSettings, setFetchingRouterSettings] = useState<boolean>(true)

  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  // const [gwUid, setGwUid] = useState<string>(activeGw_Uid)
  const {
    data: gatewayInfo,
    isFetching: gatewayFeaching,
    // isLoading,
    // error,
    refetch: gatewayRefetch
  }: any = useGatewayInfo(activeGw_Uid) //User device data

  // useEffect(() => {
  //   gatewayRefetch()
  // }, [])
  useEffect(() => {
    if (gatewayInfo === 401) {
      !openModal && setOpenModal(true)
    }
  }, [gatewayInfo])

  useEffect(() => {
    // if (activeGw_Uid && !!location.pathname && location.pathname.split('/')[2] !== activeGw_Uid) {
    // setGwUid(location.pathname.split('/')[2])
    // if (activeGw_Uid) {
    // setGwUid(activeGw_Uid)
    gatewayRefetch()
    // }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGw_Uid])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  return (
    <div className='d-flex '>
      <CustometDeviceStatus
        gatewayInfo={gatewayInfo}
        gatewayFeaching={gatewayFeaching}
        gatewayRefetch={gatewayRefetch}
        wanMqtt={wanMqtt}
        setWanMqtt={setWanMqtt}
        // wanConnection={wanConnection}
        // fetchingRouterSettings={fetchingRouterSettings}
      />
      <SystemOverviewDetails
        gatewayInfo={gatewayInfo}
        gatewayFeaching={gatewayFeaching}
        gatewayRefetch={gatewayRefetch}
      />
      <div className='right_parts'>
        <CustomerDeviceInfo
          // setFetchingRouterSettings={setFetchingRouterSettings}
          // fetchingRouterSettings={fetchingRouterSettings}
          customerinfo={gatewayInfo}
          wanMqtt={wanMqtt}
          setWanMqtt={setWanMqtt}
          // setWanConnection={setWanConnection}
        />
        <div className='mt-3 w-full'>
          <CustomersActivities />
        </div>
      </div>
    </div>
  )
}

export default CustomersOverview
