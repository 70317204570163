import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { handleNetworkHealthMapFingerPrinting } from '../../../utils/fingerprinting'
import { FormControlLabel, Switch } from '@mui/material'
import { ArrowRotateRight } from 'iconsax-react'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
// import { CustomersRootState } from '../../../redux/customerStatistics/types'
import { classifyWifiBand, getColorCode, getRadialCoordinates, getRouterNameByModel } from '../../../utils/helper'
import { useConnectedDevices } from '../../../hook/useConnectedDevices'
// import Drawer from './drawer'
import { BandClassifier, CustomNode } from './custom-node'
import NetworkMapPopover from '../../../components/popover/popover'
import SliderInput from '../../../components/slider/slider'
// import { PanZoom } from 'react-easy-panzoom'
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch'
import { useSessionContex } from '../../../SessionContex'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'

const statusIcon = type => {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.4666 4.472C15.3697 6.03633 15.3697 7.96367 14.4666 9.528L13.4226 11.3362C12.5194 12.9005 10.8503 13.8642 9.04397 13.8642L6.95603 13.8642C5.1497 13.8642 3.48058 12.9005 2.57741 11.3362L1.53345 9.528C0.630279 7.96367 0.630279 6.03633 1.53345 4.472L2.57741 2.6638C3.48058 1.09946 5.1497 0.135796 6.95604 0.135797L9.04397 0.135797C10.8503 0.135797 12.5194 1.09946 13.4226 2.6638L14.4666 4.472Z'
        fill={getColorCode(type)}
        stroke='#6B7A9F'
        stroke-width='0.128'
      />
    </svg>
  )
}

const Topology = ({ links, serialNumber }) => {
  const gwuid = useSelector(state => state.customerStatistics.activeGw_Uid)

  let canvasWidth = 6500
  let canvasHeight = 3600

  const [selectedDateTime, setSelectedDateTime] = useState(new Date().toISOString())
  const { openModal, setOpenModal } = useSessionContex()
  const { refetch: gatewayInfoRef } = useGatewayInfo(gwuid) //User device data

  const {
    data: networkMapData,
    isLoading: networkMapDataLoading,
    refetch: refetchNetworkMap,
    isFetching
    // isFetched
  } = useConnectedDevices(serialNumber || gwuid, selectedDateTime)

  useEffect(() => {
    refetchNetworkMap()
    gatewayInfoRef()
  }, [serialNumber, gwuid, selectedDateTime])

  const [master, setMaster] = useState([])

  const [satellites, setSatellites] = useState([])

  // master node position
  const masterNodeXposition = canvasWidth / 2 + 400
  const masterNodeYposition = canvasHeight / 2 - 100
  // master node position

  useEffect(() => {
    if (networkMapData === 401) {
      !openModal && setOpenModal(true)
    }
    if (!networkMapDataLoading && networkMapData && networkMapData !== 401 && networkMapData !== undefined) {
      // const totalSatellites = networkMapData?.filter(item => item.mesh_role === 'satellite')?.length
      const masterNode =
        networkMapData &&
        networkMapData.length > 0 &&
        networkMapData
          ?.filter(item => item.mesh_role === 'master')
          ?.map(item => {
            const masterClients = networkMapData?.filter(
              item2 => item2.parent === item.mac && item2.mesh_role !== 'satellite'
            )
            const masterClientCoordinates = getRadialCoordinates(
              masterClients?.length,
              masterClients?.length >= 8 ? 825 : 450,
              masterNodeXposition,
              masterNodeYposition,
              masterClients?.length >= 8 ? 15 : 0
            )

            return {
              ...item,
              clients: masterClients?.map((item3, k) => {
                return {
                  ...item3,
                  color:
                    item3.client_conn_type === 'ETHERNET'
                      ? getColorCode('Closed')
                      : getColorCode(item3.signal_strength),
                  x: masterClientCoordinates[k].x,
                  y: masterClientCoordinates[k].y,
                  image: item3?.model?.toLowerCase()?.includes('aria')
                    ? 'aria'
                    : handleNetworkHealthMapFingerPrinting([
                        item3?.os_name,
                        item3?.device_name,
                        item3?.fingerbank_device_name,
                        item3?.os_parent_name,
                        item3?.manufacturer_name
                      ]),
                  frequency: item3.mesh_role === 'satellite' ? 0.4 : classifyWifiBand(item3.channel) === 5 ? 0.3 : 0.8,
                  connection_type: item3.mesh_role === 'satellite' ? 'WIFI' : item3.client_conn_type
                }
              }),
              color: item.client_conn_type === 'ETHERNET' ? getColorCode('Closed') : getColorCode(item.signal_strength),
              x: masterNodeXposition,
              y: masterNodeYposition
            }
          })

      const satelliteCooridnates = [
        {
          x: masterNodeXposition - 2050,
          y: masterNodeYposition + 850
        },
        {
          x: masterNodeXposition - 2050,
          y: masterNodeYposition - 750
        },
        {
          x: masterNodeXposition + 1950,
          y: masterNodeYposition - 750
        },
        {
          x: masterNodeXposition + 2000,
          y: masterNodeYposition + 900
        }
      ]

      const satelliteNodes =
        (networkMapData &&
          networkMapData.length > 0 &&
          networkMapData
            ?.filter(item => item.mesh_role === 'satellite')
            ?.reverse()
            ?.map((item, index) => {
              const totalClients = networkMapData?.filter(item2 => item2.parent === item.mac)?.length

              const clientCooridnates = getRadialCoordinates(
                totalClients,
                totalClients >= 8 ? 700 : 550,
                satelliteCooridnates[index]?.x,
                satelliteCooridnates[index]?.y,
                totalClients >= 8 ? 10 : 0
              )
              return {
                ...item,
                clients: networkMapData
                  ?.filter(item2 => item2.parent === item.mac && item2.mesh_role !== 'satellite')
                  ?.map((item3, k) => {
                    return {
                      ...item3,
                      color:
                        item3.client_conn_type === 'ETHERNET'
                          ? getColorCode('Closed')
                          : getColorCode(item3.signal_strength),
                      x: clientCooridnates[k]?.x,
                      y: clientCooridnates[k]?.y,
                      image: item3?.model?.toLowerCase()?.includes('aria')
                        ? getRouterNameByModel(item3?.model)
                        : handleNetworkHealthMapFingerPrinting([
                            item3?.os_name,
                            item3?.device_name,
                            item3?.fingerbank_device_name,
                            item3?.os_parent_name,
                            item3?.manufacturer_name
                          ]),
                      frequency:
                        item3.mesh_role === 'satellite' ? 0.6 : classifyWifiBand(item3.channel) === 5 ? 0.3 : 0.8,
                      connection_type: item3.mesh_role === 'satellite' ? 'WIFI' : item3.client_conn_type
                    }
                  }),
                connection_type: 'WIFI',
                frequency: 0.3,
                color: getColorCode(item.signal_strength),
                x: satelliteCooridnates[index]?.x,
                y: satelliteCooridnates[index]?.y
              }
            })) ||
        []

      setMaster(masterNode)
      setSatellites(satelliteNodes)
    }
  }, [networkMapData])

  // hooks

  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    context.lineWidth = 3
    context.clearRect(0, 0, canvas.width, canvas.height)

    // // connect satellites and their clients
    satellites?.map(item => {
      item.clients?.forEach(link => {
        context.beginPath()
        context.strokeStyle = link.color || 'black' // Use the provided color prop or default to blue

        const node1X = item.x
        const node1Y = item.y

        const node2X = link.x
        const node2Y = link.y

        if (link.client_conn_type === 'WIFI') {
          // Calculate angle and distance between the two nodes
          const deltaX = node2X - node1X
          const deltaY = node2Y - node1Y
          const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY)
          const angle = Math.atan2(deltaY, deltaX)

          // Move the context to the starting node
          context.translate(node1X, node1Y)

          // Rotate the context to match the angle between the two nodes
          context.rotate(angle)

          // Draw the line between the two nodes with a sine wave
          const frequency = link.frequency / 2 // Adjust this value to change the number of waves
          const amplitude = 15 // Adjust this value to change the wave amplitude
          const stepSize = distance / canvas.width
          let xpos = 0

          context.moveTo(0, 0)

          for (xpos = 0; xpos < distance; xpos += stepSize) {
            const ypos = Math.sin(xpos * frequency) * amplitude
            context.lineTo(xpos, ypos)
          }
        } else {
          context.moveTo(node1X, node1Y)
          context.lineTo(node2X, node2Y)
        }

        context.stroke()

        // Reset the transformation for the next link
        context.setTransform(1, 0, 0, 1, 0, 0)
      })
    })

    // connect master to satellites
    satellites
      ?.filter(item => item.parent === master[0].mac)
      .forEach(link => {
        context.beginPath()
        context.strokeStyle = link.color || 'black' // Use the provided color prop or default to blue

        const node1X = master[0].x
        const node1Y = master[0].y
        const node2X = link.x
        const node2Y = link.y
        if (link.connection_type === 'WIFI') {
          // Calculate angle and distance between the two nodes
          const deltaX = node2X - node1X
          const deltaY = node2Y - node1Y
          const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY)
          const angle = Math.atan2(deltaY, deltaX)

          // Move the context to the starting node
          context.translate(node1X, node1Y)

          // Rotate the context to match the angle between the two nodes
          context.rotate(angle)

          // Draw the line between the two nodes with a sine wave
          const frequency = link.frequency / 3 // Adjust this value to change the number of waves
          const amplitude = 15 // Adjust this value to change the wave amplitude
          const stepSize = distance / canvas.width

          let xpos = 0

          context.moveTo(0, 0)

          for (xpos = 0; xpos < distance; xpos += stepSize) {
            const ypos = Math.sin(xpos * frequency) * amplitude
            context.lineTo(xpos, ypos)
          }
        } else {
          context.moveTo(node1X, node1Y)
          context.lineTo(node2X, node2Y)
        }

        context.stroke()

        // Reset the transformation for the next link
        context.setTransform(1, 0, 0, 1, 0, 0)
      })
    // connect master to satellites

    // connect satellite to satellite
    satellites
      ?.filter(item => item.parent !== master[0].mac)
      ?.forEach(link => {
        if (satellites.find(item => item.mac === link.parent)) {
          context.beginPath()
          context.strokeStyle = link.color || 'black' // Use the provided color prop or default to blue
          const node1X = satellites.find(item => item.mac === link.parent).x
          const node1Y = satellites.find(item => item.mac === link.parent).y
          const node2X = link.x
          const node2Y = link.y

          if (link.connection_type === 'WIFI') {
            // Calculate angle and distance between the two nodes
            const deltaX = node2X - node1X
            const deltaY = node2Y - node1Y
            const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY)
            const angle = Math.atan2(deltaY, deltaX)

            // Move the context to the starting node
            context.translate(node1X, node1Y)

            // Rotate the context to match the angle between the two nodes
            context.rotate(angle)

            // Draw the line between the two nodes with a sine wave
            const frequency = link.frequency / 3 // Adjust this value to change the number of waves
            const amplitude = 15 // Adjust this value to change the wave amplitude
            const stepSize = distance / canvas.width

            let xpos = 0

            context.moveTo(0, 0)

            for (xpos = 0; xpos < distance; xpos += stepSize) {
              const ypos = Math.sin(xpos * frequency) * amplitude
              context.lineTo(xpos, ypos)
            }
          } else {
            context.moveTo(node1X, node1Y)
            context.lineTo(node2X, node2Y)
          }

          context.stroke()

          // Reset the transformation for the next link
          context.setTransform(1, 0, 0, 1, 0, 0)
        }
      })

    // connect master and its clients
    master[0]?.clients?.forEach(link => {
      context.beginPath()
      context.strokeStyle = link.color || 'black' // Use the provided color prop or default to blue
      // const { source, target } = link
      const node1X = master[0].x
      const node1Y = master[0].y
      const node2X = link.x
      const node2Y = link.y

      if (link.connection_type === 'WIFI') {
        // Calculate angle and distance between the two nodes
        const deltaX = node2X - node1X
        const deltaY = node2Y - node1Y
        const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY)
        const angle = Math.atan2(deltaY, deltaX)

        // Move the context to the starting node
        context.translate(node1X, node1Y)

        // Rotate the context to match the angle between the two nodes
        context.rotate(angle)

        // Draw the line between the two nodes with a sine wave
        const frequency = link.frequency / 3 // Adjust this value to change the number of waves
        const amplitude = 15 // Adjust this value to change the wave amplitude
        const stepSize = distance / canvas.width
        let xpos = 0

        context.moveTo(0, 0)

        for (xpos = 0; xpos < distance; xpos += stepSize) {
          const ypos = Math.sin(xpos * frequency) * amplitude
          context.lineTo(xpos, ypos)
        }
      } else {
        context.moveTo(node1X, node1Y)
        context.lineTo(node2X, node2Y)
      }

      context.stroke()

      // Reset the transformation for the next link
      context.setTransform(1, 0, 0, 1, 0, 0)
    })
  }, [master, satellites])

  const [showMac, setShowMac] = useState(false)
  const [showName, setShowName] = useState(true)
  const [showSpeed, setShowSpeed] = useState(false)
  const [showRssi, setShowRssi] = useState(false)
  const [showChannel, setShowChannel] = useState(true)

  const [sliderValue, setSliderValue] = useState(7 * 24)
  // const [originalSliderValue, setOriginalSliderValue] = useState(7 * 24)

  // let enableDataFetch = sliderValue !== originalSliderValue

  const handleSliderChange = value => {
    setSliderValue(value)
    const maxSteps = 7 * 24
    // const hoursAgo = maxSteps - sliderValue
    // setSelectedDateTime(moment().subtract(hoursAgo, 'hours').toISOString())
    setSelectedDateTime(prevSelectedDateTime => {
      const hoursAgo = maxSteps - value
      return moment().subtract(hoursAgo, 'hours').toISOString()
    })
  }

  const calculateTimestamp = () => {
    const maxSteps = 7 * 24
    const hoursAgo = maxSteps - sliderValue
    return moment().subtract(hoursAgo, 'hours').fromNow()
  }

  // dialog handler
  // const [activeTile, setActiveTile] = useState(null)
  const [activeTileInfo, setActiveTileInfo] = useState(null)

  const isDataLive =
    moment(new Date().toISOString()).format(` ${process.env.REACT_APP_DATE_FORMAT}THH:MM`) ===
    moment(selectedDateTime).format(` ${process.env.REACT_APP_DATE_FORMAT}THH:MM`)

  // const handleActiveTileInfo = tileInfo => {
  //   setActiveTileInfo(tileInfo)
  // }

  // handle drawer
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  // const handleDialogClose = () => {
  //   setActiveTileInfo(null)
  //   setIsDrawerOpen(false)
  // }

  // let [scale, setScale] = useState(0.3);

  // const panZoomRef = useRef(null)

  // const handleZoomIn = () => {
  //   if (panZoomRef.current) {
  //     panZoomRef.current.zoomIn(5)
  //   }
  // }

  // const handleZoomOut = () => {
  //   if (panZoomRef.current) {
  //     // panZoomRef.current.zoomOut(0.3);
  //     panZoomRef.current.moveByRatio(0, 0, 5)
  //     panZoomRef.current.reset()
  //     panZoomRef.current.autoCenter()
  //     panZoomRef.current.moveByRatio(0, 0, 5)
  //   }
  // }

  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls()
    return (
      <div className='flex gap-x-2 justify-end'>
        <button
          onClick={() => zoomIn()}
          className={` text-white px-2 py-1 text-sm rounded'`}
          style={{ backgroundColor: getColorCode('greenBlue') }}
        >
          +
        </button>
        <button
          onClick={() => zoomOut()}
          className={` text-white px-2 py-1 text-sm rounded'`}
          style={{ backgroundColor: getColorCode('greenBlue') }}
        >
          -
        </button>
        <button
          onClick={() => resetTransform()}
          className={` text-white px-2 py-1 text-sm rounded'`}
          style={{ backgroundColor: getColorCode('greenBlue') }}
        >
          Reset
        </button>
      </div>
    )
  }

  return (
    <>
      <div className='sticky  flex items-center gap-x-6 justify-start w-full'>
        <div className='flex flex-row gap-x-6 items-center mb-2'>
          <div>
            <NetworkMapPopover title='View'>
              <div className={styles.inline_flex_gap_21}>
                {statusIcon('Closed')}
                <span>Status: Excellent</span>
              </div>
              <div className={styles.inline_flex_gap_21}>
                {statusIcon('Warning')}
                <span>Status: Ok</span>
              </div>
              <div className={styles.inline_flex_gap_21}>
                {statusIcon('Critical')}
                <span>Status: Poor</span>
              </div>
              <div className={styles.inline_flex_gap_16}>
                <svg width='24' height='4' viewBox='0 0 24 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M1 2L2.15449 1.23034C3.13444 0.577038 4.46522 0.930447 4.99193 1.98386L5.23335 2.46671C5.90602 3.81205 7.85892 3.70954 8.38706 2.30118L8.5 2C9.01975 0.613998 10.9802 0.613999 11.5 2V2C12.0198 3.386 13.9802 3.386 14.5 2V2C15.0198 0.613999 16.9802 0.613999 17.5 2V2C18.0198 3.386 19.9802 3.386 20.5 2V2C21.0198 0.613999 22.9802 0.613999 23.5 2V2'
                    stroke='black'
                  />
                </svg>
                <span>2.4GHz</span>
              </div>
              <div className={styles.inline_flex_gap_16}>
                <svg width='20' height='4' viewBox='0 0 20 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M1 3L4 1L7 3L10 1L13 3L16 1L19 3' stroke='black' />
                </svg>
                <span>5GHz</span>
              </div>

              <div className={styles.inline_flex_gap_10}>
                <svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <rect width='29' height='29' rx='11.5' fill='#6B7A9F' />
                  <path
                    d='M21.0996 19.1875C20.1934 19.1875 19.4434 18.9453 18.8496 18.4609C18.2598 17.9727 17.9512 17.3457 17.9238 16.5801H19.5586C19.6094 16.9395 19.7773 17.2305 20.0625 17.4531C20.3516 17.6758 20.7012 17.7871 21.1113 17.7871C21.5723 17.7871 21.9473 17.6426 22.2363 17.3535C22.5293 17.0605 22.6758 16.6836 22.6758 16.2227C22.6758 15.7539 22.5312 15.3711 22.2422 15.0742C21.9531 14.7773 21.5801 14.6289 21.123 14.6289C20.8027 14.6289 20.5117 14.6992 20.25 14.8398C19.9922 14.9766 19.7891 15.166 19.6406 15.4082H18.0586L18.4746 10.5449H23.8535V11.9512H19.8633L19.6699 14.2656H19.7051C19.8809 13.9766 20.1309 13.75 20.4551 13.5859C20.7832 13.4219 21.1602 13.3398 21.5859 13.3398C22.3945 13.3398 23.0586 13.6074 23.5781 14.1426C24.1016 14.6738 24.3633 15.3516 24.3633 16.1758C24.3633 17.0703 24.0605 17.7969 23.4551 18.3555C22.8535 18.9102 22.0684 19.1875 21.0996 19.1875Z'
                    fill='white'
                  />
                  <path
                    d='M5.93616 14.1392C5.68795 14.3874 5.68795 14.7914 5.93616 15.042C6.18437 15.2902 6.58831 15.2902 6.83895 15.042C8.55695 13.324 11.3529 13.324 13.0709 15.042C13.195 15.1661 13.3581 15.2294 13.5211 15.2294C13.6842 15.2294 13.8472 15.1661 13.9713 15.042C14.2195 14.7938 14.2195 14.3898 13.9713 14.1392C11.7569 11.9224 8.15057 11.9224 5.93616 14.1392Z'
                    fill='white'
                  />
                  <path
                    d='M7.66077 15.8589C7.41256 16.1071 7.41256 16.511 7.66077 16.7617C7.90897 17.0099 8.31292 17.0099 8.56356 16.7617C9.33009 15.9927 10.5809 15.9927 11.3474 16.7617C11.4715 16.8858 11.6345 16.9491 11.7976 16.9491C11.9606 16.9491 12.1237 16.8858 12.2478 16.7617C12.496 16.5135 12.496 16.1095 12.2478 15.8613C10.9848 14.5935 8.92614 14.5935 7.66077 15.8589Z'
                    fill='white'
                  />
                  <path
                    d='M9.95473 18.9993C10.4238 18.9993 10.804 18.6191 10.804 18.15C10.804 17.681 10.4238 17.3008 9.95473 17.3008C9.4857 17.3008 9.10547 17.681 9.10547 18.15C9.10547 18.6191 9.4857 18.9993 9.95473 18.9993Z'
                    fill='white'
                  />
                  <path
                    d='M15.7206 12.3835C12.5401 9.20549 7.36663 9.20549 4.18616 12.3835C3.93795 12.6317 3.93795 13.0357 4.18616 13.2863C4.43437 13.5345 4.83831 13.5345 5.08895 13.2863C7.77058 10.6023 12.1361 10.6023 14.8202 13.2863C14.9443 13.4104 15.1073 13.4737 15.2704 13.4737C15.4334 13.4737 15.5965 13.4104 15.7206 13.2863C15.9688 13.0381 15.9688 12.6342 15.7206 12.3835Z'
                    fill='white'
                  />
                </svg>

                <span>Wi-Fi 5</span>
              </div>

              <div className={styles.inline_flex_gap_10}>
                <svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <rect width='29' height='29' rx='11.5' fill='black' />
                  <path
                    d='M21.1465 19.1934C20.5371 19.1934 19.9902 19.0605 19.5059 18.7949C19.0254 18.5254 18.6367 18.1387 18.3398 17.6348C17.8672 16.9121 17.6309 15.9805 17.6309 14.8398C17.6309 13.4453 17.9473 12.3496 18.5801 11.5527C19.2129 10.752 20.0781 10.3516 21.1758 10.3516C21.9805 10.3516 22.6699 10.5684 23.2441 11.002C23.8184 11.4355 24.1641 11.998 24.2812 12.6895H22.541C22.4551 12.4082 22.2871 12.1816 22.0371 12.0098C21.7871 11.8379 21.4961 11.752 21.1641 11.752C20.5703 11.752 20.1074 12.0215 19.7754 12.5605C19.4473 13.0996 19.293 13.8438 19.3125 14.793H19.3477C19.5195 14.375 19.8047 14.041 20.2031 13.791C20.6016 13.541 21.0664 13.416 21.5977 13.416C22.4023 13.416 23.0684 13.6816 23.5957 14.2129C24.127 14.7402 24.3926 15.4043 24.3926 16.2051C24.3926 17.0762 24.0879 17.793 23.4785 18.3555C22.8691 18.9141 22.0918 19.1934 21.1465 19.1934ZM21.1289 17.7812C21.5586 17.7812 21.9219 17.6348 22.2188 17.3418C22.5195 17.0449 22.6699 16.6836 22.6699 16.2578C22.6699 15.8203 22.5234 15.459 22.2305 15.1738C21.9414 14.8887 21.5762 14.7461 21.1348 14.7461C20.6934 14.7461 20.3242 14.8887 20.0273 15.1738C19.7344 15.459 19.5879 15.8145 19.5879 16.2402C19.5879 16.6699 19.7363 17.0352 20.0332 17.3359C20.3301 17.6328 20.6953 17.7812 21.1289 17.7812Z'
                    fill='white'
                  />
                  <path
                    d='M5.93616 14.1392C5.68795 14.3874 5.68795 14.7914 5.93616 15.042C6.18437 15.2902 6.58831 15.2902 6.83895 15.042C8.55695 13.324 11.3529 13.324 13.0709 15.042C13.195 15.1661 13.3581 15.2294 13.5211 15.2294C13.6842 15.2294 13.8472 15.1661 13.9713 15.042C14.2195 14.7938 14.2195 14.3898 13.9713 14.1392C11.7569 11.9224 8.15057 11.9224 5.93616 14.1392Z'
                    fill='white'
                  />
                  <path
                    d='M7.66077 15.8589C7.41256 16.1071 7.41256 16.511 7.66077 16.7617C7.90897 17.0099 8.31292 17.0099 8.56356 16.7617C9.33009 15.9927 10.5809 15.9927 11.3474 16.7617C11.4715 16.8858 11.6345 16.9491 11.7976 16.9491C11.9606 16.9491 12.1237 16.8858 12.2478 16.7617C12.496 16.5135 12.496 16.1095 12.2478 15.8613C10.9848 14.5935 8.92614 14.5935 7.66077 15.8589Z'
                    fill='white'
                  />
                  <path
                    d='M9.95473 18.9993C10.4238 18.9993 10.804 18.6191 10.804 18.15C10.804 17.681 10.4238 17.3008 9.95473 17.3008C9.4857 17.3008 9.10547 17.681 9.10547 18.15C9.10547 18.6191 9.4857 18.9993 9.95473 18.9993Z'
                    fill='white'
                  />
                  <path
                    d='M15.7206 12.3835C12.5401 9.20549 7.36663 9.20549 4.18616 12.3835C3.93795 12.6317 3.93795 13.0357 4.18616 13.2863C4.43437 13.5345 4.83831 13.5345 5.08895 13.2863C7.77058 10.6023 12.1361 10.6023 14.8202 13.2863C14.9443 13.4104 15.1073 13.4737 15.2704 13.4737C15.4334 13.4737 15.5965 13.4104 15.7206 13.2863C15.9688 13.0381 15.9688 12.6342 15.7206 12.3835Z'
                    fill='white'
                  />
                </svg>
                <span>Wi-Fi 6</span>
              </div>

              <FormControlLabel
                control={<Switch checked={!showChannel} onChange={() => setShowChannel(!showChannel)} />}
                label='Hide channel number'
              />
              {/*<FormControlLabel*/}
              {/*    control={<Switch checked={showMac} onChange={() => setShowMac(!showMac)}/>}*/}
              {/*    label="Show backhaul bandwidth(MHz)"*/}
              {/*/>*/}
            </NetworkMapPopover>
          </div>
          <div>
            <NetworkMapPopover title='Overlay'>
              {/*<FormControlLabel*/}
              {/*    control={<Switch checked={showMac} onChange={() => setShowMac(!showMac)}/>}*/}
              {/*    label="24h data usage"*/}
              {/*/>*/}

              {/*<FormControlLabel*/}
              {/*    control={<Switch checked={showMac} onChange={() => setShowMac(!showMac)}/>}*/}
              {/*    label="Current data usage"*/}
              {/*/>*/}
              {/*<FormControlLabel*/}
              {/*    control={<Switch checked={showMac} onChange={() => setShowMac(!showMac)}/>}*/}
              {/*    label="Potential throughput"*/}
              {/*/>*/}

              <FormControlLabel
                control={<Switch checked={showRssi} onChange={() => setShowRssi(!showRssi)} />}
                label='dBm / rssi'
              />

              <FormControlLabel
                control={<Switch checked={showMac} onChange={() => setShowMac(!showMac)} />}
                label='mac'
              />

              <FormControlLabel
                control={<Switch checked={showName} onChange={() => setShowName(!showName)} />}
                label='name'
              />

              <FormControlLabel
                control={<Switch checked={showSpeed} onChange={() => setShowSpeed(!showSpeed)} />}
                label='speed'
              />
            </NetworkMapPopover>
          </div>

          <div>
            <NetworkMapPopover title='Net Vision'>
              <div className='mt-6 flex flex-col gap-y-3'>
                <SliderInput defaultValue={sliderValue} maxValue={7 * 24} stepSize={1} onChange={handleSliderChange} />
                <span>{calculateTimestamp()}</span>

                <span>{moment(selectedDateTime).local().format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm:ss`)}</span>
              </div>

              {/* {isFetching ? (
                <span className='animate-spin mx-auto'>
                  <ArrowRotateRight />
                </span>
              ) : (
                <button
                  type='button'
                  disabled={!enableDataFetch}
                  onClick={refetchNetworkMap}
                  className='bg-alerts-info disabled:bg-gray-600 px-3 py-2 rounded-[10px] text-white font-sf-medium'
                >
                  Load Data
                </button>
              )} */}
            </NetworkMapPopover>
          </div>

          <button
            type='button'
            disabled={isFetching}
            className={`network-map-filter p-2 rounded-md font-sf-medium `}
            style={{
              background: isDataLive ? '#FFFFFF' : getColorCode('redToBlue'),
              color: isDataLive ? '#000000' : '#FFFFFF'
            }}
            onClick={() => {
              setSelectedDateTime(new Date().toISOString())
              setSliderValue(7 * 24)
              refetchNetworkMap()
            }}
          >
            {networkMapDataLoading ? (
              <span className='animate-pulse'>Loading</span>
            ) : (
              <div className='inline-flex items-center gap-x-2'>
                <span
                  className={`inline-flex h-3 w-3 rounded-full  `}
                  style={{ background: getColorCode('greenToGreen') }}
                ></span>

                {isFetching ? (
                  <span className='animate-spin'>
                    {' '}
                    <ArrowRotateRight />{' '}
                  </span>
                ) : (
                  <span> Go Live </span>
                )}
              </div>
            )}
          </button>

          {/*<DeviceInformationDialog*/}
          {/*    isOpen={activeTileInfo !== null}*/}
          {/*    deviceInfo={activeTileInfo}*/}
          {/*    onClose={handleDialogClose}*/}
          {/*/>*/}
        </div>
      </div>

      <div
        style={{ overflowX: 'auto', width: '100%', height: '1200px', position: 'relative' }}
        className='shadow-lg bg-white p-0 m-0'
      >
        <TransformWrapper
          initialScale={0.2}
          minScale={0.2}
          maxScale={1}
          centerZoomedOut={true}
          initialPositionX={0}
          initialPositionY={30}
          smooth={true}
          panning={{
            lockAxisY: false
          }}
          limitToBounds={true}
          disablePadding={true}
        >
          <Controls />
          <TransformComponent>
            <canvas
              ref={canvasRef}
              width={canvasWidth}
              height={canvasHeight}
              style={{ background: 'transparent', padding: '0px!important' }}
            ></canvas>
            {networkMapData?.length === 0 && (
              <CustomNode
                x={canvasWidth / 2}
                y={canvasHeight / 4}
                color={'#000000'}
                image={'d50-home'}
                isMaster={true}
                deviceName={'Smart Home'}
                connectionType={'WIFI'}
                rssi={0}
                speed={0}
                mac={''}
                showSpeed={showSpeed}
                showName={showName}
                showMac={showMac}
                showRssi={showRssi}
              />
            )}
            {master[0]?.clients.map((client, index) => {
              return (
                <BandClassifier
                  sourceX={master[0].x}
                  sourceY={master[0].y}
                  key={`master_clients_band_${index}`}
                  link={client}
                  showchannel={showChannel}
                  mode={client.mode || ''}
                />
              )
            })}

            {satellites?.map((satellite, index) => {
              return satellite.clients.map((client, index) => {
                return (
                  <BandClassifier
                    sourceX={satellite.x}
                    sourceY={satellite.y}
                    key={`satellite_clients_band_${index}`}
                    link={client}
                    showchannel={showChannel}
                    mode={client.mode || ''}
                  />
                )
              })
            })}

            {/*node rendering */}
            {master[0]?.clients.map((client, index) => {
              return (
                <CustomNode
                  key={`master_client_${index}`}
                  x={client.x}
                  y={client.y}
                  color={'#DBDBDB'}
                  image={client.image}
                  isMaster={false}
                  deviceName={client.device_name || client.device_name !== '' ? client.device_name : client.host}
                  connectionType={client.client_conn_type}
                  rssi={client.rssi}
                  speed={client.rate || 0}
                  mac={client.mac}
                  showSpeed={showSpeed}
                  showName={showName}
                  showMac={showMac}
                  showRssi={showRssi}
                  parentX={master[0]?.x}
                  parentY={master[0]?.y}
                  deviceInfo={client}
                  // onClick={handleActiveTileInfo}
                  onClick={() => {}}
                  isActiveTile={activeTileInfo?.mac === client?.mac}
                />
              )
            })}

            {/*satellite client rendering*/}
            {satellites?.map((satellite, index) => {
              return satellite.clients.map((item, k) => {
                return (
                  <CustomNode
                    key={`satellite_client${index}`}
                    x={item.x}
                    y={item.y}
                    color={item.mesh_role === 'satellite' ? '000000' : '#DBDBDB'}
                    image={item.image}
                    isMaster={false}
                    isSatellite={item.mesh_role === 'satellite'}
                    deviceName={item.device_name || item.device_name !== '' ? item.device_name : item.host}
                    connectionType={
                      item.client_conn_type === 'ETHERNET'
                        ? 'ETHERNET'
                        : item.mesh_role === 'satellite'
                        ? 'WIFI'
                        : item.client_conn_type
                    }
                    rssi={item.rssi}
                    speed={item.rate || 0}
                    mac={item.mac}
                    showSpeed={showSpeed}
                    showName={showName}
                    showMac={showMac}
                    showRssi={showRssi}
                    parentX={satellite.x}
                    parentY={satellite.y}
                    deviceInfo={item}
                    // onClick={handleActiveTileInfo}
                    onClick={() => {}}
                    isActiveTile={activeTileInfo?.mac === item?.mac}
                  />
                )
              })
            })}

            {/*master node rendering*/}
            {/* {master?.length !== 0 && (
              <CustomNode
                x={master[0]?.x}
                y={master[0]?.y}
                // color={master[0]?.gw_uid === 'gwdd3b39fbffd5904a4bba59ef64eef998' ? '#DBDBDB' : '000000'}
                color={master[0]?.gw_uid === gwuid ? '#DBDBDB' : '000000'}
                deviceName={master[0]?.ssid || master[0]?.title || master[0]?.device_name || 'No Devices Connected'}
                connectionType={'Wireless'}
                // image={master[0]?.gw_uid === 'gwdd3b39fbffd5904a4bba59ef64eef998' ? 'hitron' : 'd50-home'}
                image={master[0]?.gw_uid === gwuid ? 'hitron' : 'd50-home'}
                isMaster={true}
                showRssi={showRssi}
                showName={showName}
                showMac={showMac}
                showSpeed={showSpeed}
                parentX={master[0]?.x}
                parentY={master[0]?.y}
                isActiveTile={activeTileInfo?.mac === master[0]?.mac}
                deviceInfo={master[0]}
                // onClick={handleActiveTileInfo}
                onClick={() => {}}
              />
            )} */}

            {master?.length !== 0 && (
              <CustomNode
                x={master[0]?.x}
                y={master[0]?.y}
                color={'000000'}
                deviceName={master[0]?.ssid || master[0]?.title || master[0]?.device_name || 'NA'}
                connectionType={'Wireless'}
                image={getRouterNameByModel(master[0]?.model || 'NA')}
                isMaster={true}
                showRssi={showRssi}
                showName={showName}
                showMac={showMac}
                showSpeed={showSpeed}
                parentX={master[0]?.x}
                parentY={master[0]?.y}
                deviceInfo={master[0]}
                isActiveTile={activeTileInfo?.mac === master[0]?.mac}
                // onClick={handleActiveTileInfo}
                onClick={() => {}}
              />
            )}

            {/*satellite node rendering*/}
            {satellites?.map((satellite, index) => {
              return (
                <CustomNode
                  key={`satellite_${index}`}
                  x={satellite?.x}
                  y={satellite?.y}
                  color={'000000'}
                  deviceName={satellite.ssid || satellite.title || satellite.device_name}
                  connectionType={'WIFI'}
                  image={getRouterNameByModel(satellite?.model || 'NA')}
                  rssi={satellite.rssi}
                  speed={satellite.rate || 0}
                  isMaster={false}
                  isSatellite={true}
                  showRssi={showRssi}
                  showName={showName}
                  showMac={showMac}
                  showSpeed={showSpeed}
                  parentX={satellite?.x}
                  parentY={satellite?.y}
                  isActiveTile={activeTileInfo?.mac === satellite?.mac}
                  deviceInfo={satellite}
                  // onClick={handleActiveTileInfo}
                  onClick={() => {}}
                />
              )
            })}
          </TransformComponent>
        </TransformWrapper>
      </div>
      {/* <Drawer isOpen={activeTileInfo !== null} deviceInfo={activeTileInfo} onClose={handleDialogClose}></Drawer> */}
    </>
  )
}

export default Topology
