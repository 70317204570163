import ThreatsTile from '../../cyber-security/threats-tile/threats-tile'
import { FC, useEffect } from 'react'
import { useParentalConnectedDevices } from '../../../hook/parental-control/useParental'
// import { currentISOTime } from '../../../utils/helper'
import './styles.scss'
import moment from 'moment'
import { Box } from '@mui/material'
import SessionTimeOutModal from '../../SessionTimeOutModal'
import { useSessionContex } from '../../../SessionContex'
import { getColorCode } from '../../../utils/helper'

interface Props {
  email: string
  onChange: (deviceId: any) => void
  useMac?: boolean
  useDeviceInfo?: boolean
  reCall?: boolean
  setDisplay?: any
}
const DevicesList: FC<Props> = ({ email, onChange, useMac, useDeviceInfo, reCall = false, setDisplay }) => {
  // const time = currentISOTime()
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const { openModal, setOpenModal } = useSessionContex()

  const time = moment.tz(moment.utc().subtract(7, 'days'), timeZone).toISOString()
  const { data, refetch, isFetching, error } = useParentalConnectedDevices(time, email)

  useEffect(() => {
    refetch()
  }, [])
  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data])
  useEffect(() => {
    refetch()
  }, [email])

  useEffect(() => {
    if (reCall) {
      refetch()
    }
  }, [reCall])

  useEffect(() => {
    if (setDisplay) {
      setDisplay(isFetching)
    }
  }, [isFetching])

  return (
    <>
      <div className='flex gap-x-[30px] overflow-x-auto flex-row mb-[28px] pb-3 deviceList-main-wrapper'>
        {error !== null && (!data || (data && data?.devices && data?.devices?.length === 0)) && (
          <Box
            marginLeft={2.5}
            paddingBottom={4}
            color={getColorCode('redToBlue')}
            justifyContent={'center'}
            display={'flex'}
          >
            Device data not found
          </Box>
        )}
        {data && (
          <ThreatsTile
            devices={data.devices}
            onChange={onChange}
            useMac={useMac}
            useDeviceInfo={useDeviceInfo}
            reCall={reCall}
          />
        )}
      </div>

      <div className='hidden'>
        <table className={'pc-table'}>
          <thead>
            <tr>
              <td>isonline</td>
              <td>IP</td>
              <td>mac</td>
              <td>name</td>
              <td>type</td>
              <td>fingerbank name</td>
              <td>manufacturer name</td>
              <td>os name</td>
              <td>os parent name</td>
              <td>parent_device_type_hierarchy</td>
            </tr>
          </thead>

          <tbody>
            {data &&
              data?.devices &&
              data?.devices?.length > 0 &&
              data?.devices?.map((item: any, index: any) => {
                return (
                  <tr key={`device_${index}`}>
                    <td>{item.is_active}</td>
                    <td>{item.device_ip}</td>

                    <td>{item.device_macid}</td>

                    <td>{item.device_name}</td>

                    <td>{item.device_type}</td>

                    <td>{item.fingerbank_device_name}</td>
                    <td>{item.manufacturer_name}</td>
                    <td>{item.os_name}</td>
                    <td>{item.os_parent_name}</td>

                    <td>{item.parent_device_type_hierarchy.join(',')}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </>
  )
}
export default DevicesList
