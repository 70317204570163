import { useQuery } from '@tanstack/react-query'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'

export const useSpeedtest = (
  gwuid: string,
  from?: string
): {
  data: any[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['speedtest'],
    async () => {
      if (!gwuid || gwuid === '') return undefined
      const queryString = from
        ? `/rpc/speedtest?_gw_uid=${gwuid}&_from=${from}`
        : // `/rpc/speedtest?_gw_uid=gw34a74bbe087d42fcb8eb6d015e77ee89&_from=${from}`
          `/rpc/speedtest?_gw_uid=${gwuid}`

      try {
        const response = await xcpemSec.get<any[]>(queryString)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Speed test: ${error?.response?.data?.message || error?.message}`)
        }

        // throw new Error('Failed to fetch gateway services')
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useBulkSpeedtest = (
  gwuids: string
): {
  data: any[] | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['bulk-speedtest'],
    async () => {
      const queryString = `/rpc/speedtest_arr?_gw_uid=${gwuids}`

      try {
        const response = await xcpemSec.get<any[]>(queryString)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Bulk speed test: ${error?.response?.data?.message || error?.message}`)
        }

        // throw new Error('Failed to fetch gateway services')
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 1
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
