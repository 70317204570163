import { useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Alert } from '../models/Xcpem'
import xcpemReadOnlySec from '../axiosInstances/xcpemReadOnlySec'
import { notifyError } from '../components/Toaster'

export const useAllActiveAlertsForGateway = (
  gw_uid: any
): {
  data: Alert[] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const gwuid: any = !!gw_uid ? gw_uid : sessionStorage.getItem('gwuid')
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['gatewayalerts'],
    async () => {
      // console.log('=====>>>>', gwuid)
      // if (!gwuid) return undefined

      const endpoint = `/webapp_dashboard_alerts_log?gw_uid=ilike.${gwuid}&and=(is_acknowledged.eq.false,is_resolved.eq.false)`
      try {
        const response = await xcpemReadOnlySec.get<Alert[]>(endpoint)
        // console.log('==========', response)
        return response.status === 401 ? response : response.data
      } catch (error: any) {
        // console.log('==========', error?.response)
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Alert log :${error?.response?.data?.message || error?.message}`)
        }
        // throw new Error('Failed to fetch gateway alerts')
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
