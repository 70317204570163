import React from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom'
import LoginPage from './Pages/Login'
import PrivateRoute from './Pages/Login/PrivateRoute'
import Dashboard from './Pages/Dashboard'
import { Provider } from 'react-redux'
import CustomersDashboard from './Pages/Customer/Header'
import { store } from './redux/store'
import DeleteAccount from './Pages/PublicPage/DeleteAccount'

function App() {
  return (
    <>
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <HashRouter>
          <Routes>
            <Route path='/delete_account' element={<DeleteAccount />} />
            <Route path='/login' element={<LoginPage />} />
            <Route element={<PrivateRoute />}>
              <Route path='/' element={<Dashboard />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/dashboard/:id' element={<CustomersDashboard />} />
              <Route path='/dashboard/:id/:email' element={<CustomersDashboard />} />
            </Route>
          </Routes>
        </HashRouter>
        {/* </PersistGate> */}
      </Provider>
    </>
  )
}

export default App
