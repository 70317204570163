import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import styles from './styles.module.scss'
import CardComponent from '../../../components/Cards/general-card/CardComponent'
import CountUp from 'react-countup'
import { Skeleton } from '@mui/material'

interface RoundedBarChartProps {
  data: number
  title: string
  isLoading?: boolean
  style?: any
  className?: string
  Logo?: any
}

const WeeklyRoundedBarChart: React.FC<RoundedBarChartProps> = ({
  data,
  title,
  isLoading,
  style,
  className,
  Logo
}: any) => {
  return (
    <CardComponent
      className={`Alerts-Summary-Card Alerts-Summary-Card-${className}`}
      styles={{ ...style }}
      body={
        <div className={styles.customer_stats_card}>
          <Stack direction='column'>
            <h2 className={styles.card_title}>{title}</h2>
            {isLoading ? (
              <Skeleton variant='rounded' width={'50px'} height={50} />
            ) : (
              <h1 className={styles.card_value}>
                <CountUp start={0} end={data} duration={2} />
              </h1>
            )}
          </Stack>
          <span className={process.env.REACT_APP_MODE === 'hitron' ? styles.hitron_logo : styles.logo}>{Logo}</span>
        </div>
      }
    />
  )
}

export default WeeklyRoundedBarChart
