import React from 'react'

const ReStatus = () => {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4102 0C14.2654 0.000944557 14.123 0.0366627 13.9944 0.103132L10.4818 1.87302C10.029 2.10287 9.84588 2.65454 10.0714 3.10953L11.8413 6.62039C12.3969 7.72317 14.051 6.88969 13.4954 5.7869L12.4903 3.80007C15.7289 4.52265 18.147 7.40424 18.147 10.8705C18.147 14.3004 15.7708 17.1627 12.5861 17.9228C11.2914 18.1481 11.76 20.1052 13.0164 19.7198C17.0211 18.7641 20 15.1555 20 10.8705C20 6.87655 17.4093 3.47931 13.8172 2.26164L14.8206 1.75537C15.6979 1.3213 15.389 0.000174918 14.4102 0ZM0 10.5703C0 14.5641 2.58908 17.9654 6.18096 19.1846L5.17219 19.6927C3.92021 20.1935 4.86117 22.051 6.0056 21.3378L9.51098 19.5751C9.97069 19.3478 10.158 18.7901 9.92859 18.3314L8.15873 14.826C7.99192 14.4817 7.63058 14.2756 7.24939 14.3071C6.58713 14.3603 6.19498 15.0733 6.50456 15.6612L7.50429 17.6407C4.26824 16.9163 1.85122 14.0347 1.85122 10.5703C1.85122 7.14045 4.22193 4.28535 7.40667 3.52526C8.53513 3.34895 8.38523 1.47206 7.12013 1.70295C2.92817 2.46804 3.49836e-07 6.28531 0 10.5703Z" fill="black"/>
    </svg>
  )
}
export default ReStatus
