import React from 'react'

const MasterUnit = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.2422 10.547H16.748L19.9136 3.16068C20.0411 2.86326 19.9032 2.51876 19.6058 2.3913C19.3084 2.26384 18.9639 2.40162 18.8364 2.69904L15.473 10.547H4.52699L1.16355 2.69904C1.03609 2.40162 0.69168 2.26376 0.39418 2.3913C0.0967578 2.51876 -0.0410156 2.86322 0.0864453 3.16068L3.25203 10.547H1.75781C0.788555 10.547 0 11.3356 0 12.3049V15.8205C0 16.7897 0.788555 17.5783 1.75781 17.5783H2.38281V18.2033C2.38281 18.5269 2.64516 18.7892 2.96875 18.7892C3.29234 18.7892 3.55469 18.5269 3.55469 18.2033V17.5783H16.4453V18.2033C16.4453 18.5269 16.7077 18.7892 17.0312 18.7892C17.3548 18.7892 17.6172 18.5269 17.6172 18.2033V17.5783H18.2422C19.2114 17.5783 20 16.7897 20 15.8205V12.3049C20 11.3356 19.2114 10.547 18.2422 10.547ZM18.8281 15.8205C18.8281 16.1436 18.5653 16.4064 18.2422 16.4064H1.75781C1.43473 16.4064 1.17188 16.1436 1.17188 15.8205V12.3049C1.17188 11.9818 1.43473 11.7189 1.75781 11.7189H18.2422C18.5653 11.7189 18.8281 11.9818 18.8281 12.3049V15.8205Z" fill="black"/>
      <path d="M8.75951 7.54676C8.53068 7.77559 8.53068 8.14656 8.75951 8.37539C8.9883 8.60422 9.35932 8.60422 9.58814 8.37539C9.65248 8.31105 9.79158 8.20313 10.0001 8.20313C10.2086 8.20313 10.3477 8.31105 10.4121 8.37539C10.6408 8.60422 11.0119 8.60426 11.2407 8.37539C11.4695 8.14656 11.4695 7.77559 11.2407 7.54676C11.0481 7.35426 10.631 7.03125 10.0001 7.03125C9.36924 7.03125 8.95205 7.35426 8.75951 7.54676Z" fill="black"/>
      <path d="M4.61342 3.40566C6.05288 1.9652 7.96584 1.17188 9.99999 1.17188C12.0341 1.17188 13.9471 1.9652 15.3865 3.40566C15.6151 3.63449 15.9862 3.6348 16.2152 3.40598C16.4441 3.17727 16.4442 2.80625 16.2155 2.57734C14.5547 0.915313 12.3473 0 9.99999 0C7.65264 0 5.4453 0.915312 3.78452 2.5773C3.55577 2.80621 3.55588 3.17719 3.78483 3.40594C4.0137 3.63469 4.38475 3.63453 4.61342 3.40566Z" fill="black"/>
      <path d="M14.5568 5.06254C14.7857 4.83387 14.7859 4.46285 14.5572 4.23391C13.3396 3.01504 11.7211 2.34375 9.99994 2.34375C8.27873 2.34375 6.66025 3.01504 5.44263 4.23391C5.21396 4.46285 5.21412 4.83383 5.44306 5.06254C5.67201 5.29125 6.04302 5.29105 6.27169 5.06211C7.26791 4.06484 8.59197 3.51562 9.9999 3.51562C11.4078 3.51562 12.7319 4.06484 13.7281 5.06211C13.9567 5.29094 14.3277 5.29141 14.5568 5.06254Z" fill="black"/>
      <path d="M12.8991 6.71859C13.1279 6.48977 13.1279 6.11879 12.8991 5.88996C12.1236 5.11453 11.094 4.6875 9.99992 4.6875C8.90582 4.6875 7.87621 5.11453 7.10082 5.88996C6.87199 6.11879 6.87199 6.48977 7.10082 6.71859C7.32961 6.94742 7.70062 6.94746 7.92945 6.71859C8.48352 6.16453 9.21883 5.85937 9.99992 5.85937C10.781 5.85937 11.5163 6.16453 12.0704 6.71859C12.2992 6.94742 12.6702 6.94742 12.8991 6.71859Z" fill="black"/>
      <path d="M12.3438 12.8906C12.0202 12.8906 11.7578 13.153 11.7578 13.4766V14.6484C11.7578 14.972 12.0202 15.2344 12.3438 15.2344C12.6673 15.2344 12.9297 14.972 12.9297 14.6484V13.4766C12.9297 13.153 12.6673 12.8906 12.3438 12.8906Z" fill="black"/>
      <path d="M14.6875 12.8906C14.3639 12.8906 14.1016 13.153 14.1016 13.4766V14.6484C14.1016 14.972 14.3639 15.2344 14.6875 15.2344C15.0111 15.2344 15.2734 14.972 15.2734 14.6484V13.4766C15.2734 13.153 15.0111 12.8906 14.6875 12.8906Z" fill="black"/>
      <path d="M17.0312 12.8906C16.7077 12.8906 16.4453 13.153 16.4453 13.4766V14.6484C16.4453 14.972 16.7077 15.2344 17.0312 15.2344C17.3548 15.2344 17.6172 14.972 17.6172 14.6484V13.4766C17.6172 13.153 17.3548 12.8906 17.0312 12.8906Z" fill="black"/>
    </svg>
  )
}
export default MasterUnit
