import { Modal } from 'antd'
import { Button } from '@mui/material'
import { Close } from '@mui/icons-material'
import { getColorCode } from '../../../utils/helper'

const ResetModal = ({ isOpen, onClose, onSubmit, title, subTitle }: any) => {
  return (
    <Modal
      open={isOpen}
      footer={null}
      closable={false}
      width={500}
      wrapClassName={'reset_modal_wrapper'}
      centered={true}
    >
      <div className='reset_modal_body_wrapper device-tab'>
        <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
          <h3>{title}</h3>
          <Close
            className='cursor-pointer'
            onClick={() => {
              onClose()
            }}
          />
        </div>
        <div className='mb-2 row-gap-1 text-center'>
          <div className='mb-3 mt-1'>{subTitle}</div>
          <div>
            <Button
              onClick={onSubmit}
              variant='contained'
              size='medium'
              sx={{ background: getColorCode('infoBlue'), marginRight: '10px' }}
            >
              Yes
            </Button>
            <Button
              onClick={() => onClose()}
              size='medium'
              variant='outlined'
              sx={{ color: getColorCode('infoBlue'), borderColor: getColorCode('infoBlue') }}
            >
              No
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ResetModal
