import React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import CyberLogs from './cyberLog'
import IotLogs from './iotLog'
import CustomeTabs from '../../../../components/CustomeTabs'
import ParentalLogs from './parentalLog'
import './activity.scss'
import SupportAgentLog from './supportAgentLog'
import DeviceLog from './deviceLog'

const CustomersActivities = () => {
  const tabsList = [
    {
      label: 'Device',
      tab: (
        <>
          <DeviceLog />
        </>
      )
    },
    {
      label: 'Support Agent',
      tab: (
        <>
          <SupportAgentLog />
        </>
      )
    }
    // {
    //   label: 'Cyber',
    //   tab: (
    //     <>
    //       <CyberLogs />
    //     </>
    //   )
    // },
    // {
    //   label: 'Parental',
    //   tab: (
    //     <>
    //       <ParentalLogs />
    //     </>
    //   )
    // }
    // {
    //   label: 'IoT',
    //   tab: (
    //     <>
    //       <IotLogs />
    //     </>
    //   )
    // }
  ]

  return (
    <Card className='info-card-cls activity-card '>
      <CardHeader className='info-header-cls' title='Activities' />
      <CustomeTabs tabsList={tabsList} divider={<Divider className='' />} className='activity-section' />
    </Card>
  )
}

export default CustomersActivities
