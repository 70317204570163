import React from 'react'

const SpeedTestImage = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_59_348)">
    <path d="M10.3885 17.0903C10.2872 17.0903 10.1858 17.0515 10.1083 16.9748L5.02115 11.8876C4.87234 11.7388 4.86601 11.5006 5.00532 11.3438L8.81809 7.07278C11.4832 3.95497 15.8231 1.93737 19.6074 2.00148C19.8187 2.00623 19.9889 2.17641 19.9936 2.38775C20.0815 6.1744 18.0409 10.512 14.9152 13.1826L10.6513 16.9898C10.5769 17.0571 10.4819 17.0903 10.3885 17.0903ZM5.84512 11.5924L10.4035 16.1508L14.3952 12.5873C17.2494 10.1479 19.1451 6.25989 19.2037 2.79222C15.736 2.85 11.8488 4.7457 9.41411 7.5936L5.84512 11.5924Z" fill="#636363"/>
    <path d="M11.9043 19.9876C11.8663 19.9876 11.8275 19.9821 11.7911 19.9702C11.6486 19.9227 11.5314 19.8135 11.5109 19.6647L10.9679 15.8757C10.937 15.6596 11.0874 15.4593 11.3035 15.4277C11.5196 15.3936 11.7198 15.5464 11.7515 15.7633L12.1758 18.7236C13.5625 17.0653 14.3334 14.963 14.3334 12.8196C14.3334 12.6011 14.5107 12.4238 14.7292 12.4238C14.9477 12.4238 15.125 12.6011 15.125 12.8196C15.125 15.4419 14.0627 18.0088 12.2106 19.8602C12.1314 19.9394 12.0159 19.9876 11.9043 19.9876Z" fill="#636363"/>
    <path d="M6.17734 11.0321C6.15913 11.0321 6.14013 11.0313 6.12114 11.0282L2.33211 10.4852C2.1833 10.4638 2.05903 10.3601 2.01233 10.2169C1.96563 10.0744 2.00363 9.91688 2.11048 9.81161C3.98877 7.93332 6.55489 6.87109 9.17721 6.87109C9.39567 6.87109 9.57298 7.0484 9.57298 7.26686C9.57298 7.48532 9.39567 7.66262 9.17721 7.66262C7.02347 7.66262 4.9101 8.44069 3.26927 9.81952L6.23353 10.2446C6.44962 10.2762 6.60001 10.4757 6.56914 10.6926C6.53985 10.8897 6.37047 11.0321 6.17734 11.0321Z" fill="#636363"/>
    <path d="M14.5614 9.41229C14.054 9.41229 13.5474 9.21916 13.1619 8.83369C12.391 8.06195 12.391 6.80659 13.1619 6.03486C13.9337 5.26312 15.189 5.26312 15.9608 6.03486C16.7317 6.80659 16.7317 8.06195 15.9608 8.83369C15.5745 9.21995 15.0679 9.41229 14.5614 9.41229ZM14.5614 6.24778C14.2574 6.24778 13.9527 6.36334 13.7216 6.59446C13.2593 7.05751 13.2593 7.81104 13.7216 8.27408C14.1846 8.73633 14.9381 8.73554 15.4012 8.27408C15.8634 7.81104 15.8634 7.05751 15.4012 6.59446C15.1693 6.36413 14.8653 6.24778 14.5614 6.24778Z" fill="#636363"/>
    <path d="M1.39593 20.9953C1.29224 20.9953 1.19172 20.9549 1.11573 20.8797C1.01283 20.7768 0.974842 20.6256 1.01679 20.4863C1.1466 20.0533 2.30935 16.2271 3.15628 15.3802C4.11007 14.4264 5.66225 14.4256 6.61604 15.3802C7.56982 16.334 7.56982 17.8862 6.61604 18.8399C5.7691 19.6869 1.94288 20.8496 1.50991 20.9794C1.47192 20.9897 1.43393 20.9953 1.39593 20.9953ZM4.88655 15.4554C4.4623 15.4554 4.03883 15.6169 3.71589 15.939C3.25918 16.3965 2.49932 18.4394 1.99749 19.9979C3.55521 19.4961 5.59893 18.7363 6.05564 18.2795C6.70073 17.6344 6.70073 16.5841 6.05564 15.939C5.73349 15.6169 5.31002 15.4554 4.88655 15.4554Z" fill="#636363"/>
    </g>
    <defs>
    <clipPath id="clip0_59_348">
    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}
export default SpeedTestImage
