import { useQuery } from '@tanstack/react-query'
import xcpemSec from '../axiosInstances/xcpemSec'
import { Device } from '../models/Xcpem'
import { notifyError } from '../components/Toaster'

export const useConnectedDevices = (
  gw_uid: string,
  from?: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['networkmap-devices'],
    async () => {
      const queryString = `/rpc/webapp_wifi_network_health_map_dev_type?_gw_uid=${gw_uid}&_direction=back${
        from ? `&_time=${from}` : '&_time='
      }`

      try {
        const response = await xcpemSec.get<any>(queryString)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Network health map: ${error?.response?.data?.message || error?.message}`)
        }
        // throw new Error('Failed to fetch gateway services')
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
