import { FC } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { formatBytes, getColorCode } from '../../../../utils/helper'
import moment from 'moment'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

const DataConsumption = ({ data }: any) => {
  // score_options
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  const data_consumption_chart_data =
    data?.map((item: any) => {
      // var localtime = moment.utc(item.timestamp).local().format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm:ss`)
      const timestamp = dayjs.utc(item.timestamp, 'YYYYMMDDTHH:mm:ss').tz(timeZone).toDate()
      const dataInMB = item.data_consume_rx === null ? item.data_consume_rx : item.data_consume_rx / 1000000 // Convert bytes to MB
      return [timestamp?.getTime(), dataInMB]
    }) || []

  const options = {
    chart: {
      type: 'areaspline',
      height: 200,
      marginTop: 40
    },
    title: {
      text: 'Data Consumption',
      align: 'left'
    },
    xAxis: {
      type: 'datetime',
      tickInterval: 1 * 60 * 60 * 1000, // 3 hours in milliseconds
      labels: {
        formatter: function (this: any) {
          return moment(this.value).format('HH:mm')
        }
      },
      dateTimeLabelFormats: {
        hour: '%I:%M %p', // Format for hours
        day: '%d/%m %H:%M' // Format for days
      }
    },
    // xAxis: {
    //   type: 'datetime',
    //   tickInterval: 3 * 60 * 60 * 1000,
    //   dateTimeLabelFormats: {
    //     hour: '%I:%M %p', // Format with AM/PM
    //     day: '%I:%M %p' // Format with AM/PM
    //   }
    // },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        formatter: function (this: any) {
          return this.value + ' MB'
        }
      },
      tickPositions: [0, 5, 10, 15, 20, 25, 30]
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5
      }
    },
    series: [
      {
        name: 'Data Consumption',
        data: data_consumption_chart_data,
        color: getColorCode('chartRedBlue')
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      floating: true,
      borderWidth: 0,
      backgroundColor: '#f0f8ff00'
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        const timestamp =
          moment(this.x).local().fromNow() +
          '(' +
          moment(this.x).local().format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm:ss`) +
          ')'
        return (
          '<div class="custom-tooltip">' +
          '<span><b>' +
          this.series.name +
          ': </b></span>' +
          '<span>' +
          (this.y || 0).toFixed(2) +
          ' MB' +
          '</span><br/>' +
          '<span> <b>when: </b>' +
          timestamp +
          '</span>' +
          '</div>'
        )
      }
    }
  }
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default DataConsumption
