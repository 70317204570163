import * as Tooltip from '@radix-ui/react-tooltip'
import './styles.scss'
import { useLocation, useNavigate } from 'react-router-dom'

const QuickSwitchToolTip = ({ email, serialNumber, alert }: any) => {
  const navigate = useNavigate()

  const location = useLocation()

  const currentPath = location.pathname

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button
            type='button'
            onClick={() => navigate(`/customers/${serialNumber}`)}
            className={`w-[40px] h-[40px] inline-flex justify-center items-center rounded-full ${
              currentPath.includes(serialNumber) ? 'bg-alerts-critical text-white' : ''
            }   btn-popover`}
          >
            <span className=' w-[30px] h-[30px] inline-flex justify-center items-center rounded-full  btn-span'>
              {email.charAt(0).toUpperCase()}
            </span>
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side='left'
            className='TooltipContent  relative border-[2px] border-alerts-critical'
            sideOffset={5}
          >
            <div className='flex flex-col gap-y-3'>
              <div className='flex gap-x-[3px]'>
                <span className='font-sf-semibold'>User:</span>
                <span className='font-sf-light'>{email}</span>
              </div>
              <div className='flex gap-x-[3px]'>
                <span className='font-sf-semibold'>Alert:</span>
                <span className='font-sf-light'>{alert}</span>
              </div>
            </div>

            <svg className='TooltipArrow' viewBox='0 0 10 10'>
              <path d='M0,0 L10,5 L0,10' />
            </svg>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

export default QuickSwitchToolTip
