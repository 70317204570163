import React from 'react'

const UpTime = () => {
  return (
    <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.1322 4.06633L18.3822 3.31632C16.2549 1.19271 13.3719 0 10.366 0C7.36018 0 4.47715 1.19271 2.34984 3.31632L1.59983 4.06633C1.04434 4.62352 0.732422 5.37821 0.732422 6.16499C0.732422 6.95177 1.04434 7.70646 1.59983 8.26365L2.34984 9.01366C4.47715 11.1373 7.36018 12.33 10.366 12.33C13.3719 12.33 16.2549 11.1373 18.3822 9.01366L19.1322 8.26365C19.6877 7.70646 19.9996 6.95177 19.9996 6.16499C19.9996 5.37821 19.6877 4.62352 19.1322 4.06633ZM18.1552 7.29345L17.412 8.03658C15.5426 9.90387 13.0083 10.9527 10.366 10.9527C7.72374 10.9527 5.18951 9.90387 3.32004 8.03658L2.57691 7.25904C2.27774 6.9597 2.10968 6.5538 2.10968 6.13058C2.10968 5.70737 2.27774 5.30147 2.57691 5.00212L3.32004 4.25899C5.18951 2.3917 7.72374 1.34285 10.366 1.34285C13.0083 1.34285 15.5426 2.3917 17.412 4.25899L18.1552 5.00212C18.4543 5.30147 18.6224 5.70737 18.6224 6.13058C18.6224 6.5538 18.4543 6.9597 18.1552 7.25904V7.29345Z" fill="black"/>
      <path d="M10.3665 2.68994C9.686 2.68994 9.02083 2.89172 8.45506 3.26976C7.88928 3.6478 7.44831 4.18512 7.18791 4.81377C6.92752 5.44243 6.85938 6.13419 6.99213 6.80156C7.12488 7.46894 7.45255 8.08196 7.9337 8.56312C8.41486 9.04427 9.02788 9.37194 9.69526 9.50469C10.3626 9.63744 11.0544 9.56931 11.683 9.30891C12.3117 9.04851 12.849 8.60754 13.2271 8.04177C13.6051 7.47599 13.8069 6.81082 13.8069 6.13037C13.8069 5.21791 13.4444 4.34282 12.7992 3.69762C12.154 3.05241 11.2789 2.68994 10.3665 2.68994ZM10.3665 8.19462C9.95818 8.19462 9.55908 8.07356 9.21961 7.84674C8.88015 7.61991 8.61557 7.29752 8.45933 6.92032C8.30309 6.54313 8.26221 6.12808 8.34186 5.72765C8.42151 5.32723 8.61811 4.95941 8.9068 4.67072C9.1955 4.38203 9.56331 4.18543 9.96374 4.10578C10.3642 4.02613 10.7792 4.06701 11.1564 4.22324C11.5336 4.37948 11.856 4.64406 12.0828 4.98353C12.3096 5.32299 12.4307 5.7221 12.4307 6.13037C12.4307 6.67784 12.2132 7.2029 11.8261 7.59002C11.439 7.97714 10.9139 8.19462 10.3665 8.19462Z" fill="black"/>
    </svg>
  )
}
export default UpTime
