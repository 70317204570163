import React from "react";

const HeaderLogo = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      <svg
        width="180"
        height="28"
        viewBox="0 0 180 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M97.8855 25.0302C96.2534 25.0302 94.9248 23.706 94.9248 22.0793V5.34881C94.9248 3.72214 96.2534 2.39795 97.8855 2.39795C99.5176 2.39795 100.846 3.72214 100.846 5.34881V22.0793C100.846 23.706 99.5244 25.0302 97.8855 25.0302Z"
          fill="#091554"
        />
        <path
          d="M108.257 20.2238C108.932 19.2357 110.227 18.9466 111.299 19.4776C112.6 20.1229 114.125 20.4859 115.615 20.4859C117.207 20.4859 118.441 19.7129 118.441 18.624C118.441 15.3102 107.738 16.4932 107.738 9.40845C107.738 5.18718 111.838 2.55225 116.208 2.55225C117.786 2.55225 119.445 2.88161 120.862 3.36558C122.413 3.8966 123.06 5.73164 122.17 7.10288C121.509 8.11787 120.228 8.50101 119.102 8.05065C118.346 7.74817 117.517 7.55324 116.667 7.55324C115.028 7.55324 113.659 8.1918 113.659 9.32106C113.659 12.1845 124.362 11.0486 124.362 18.671C124.362 22.8923 120.214 25.4802 115.568 25.4802C113.363 25.4802 111.063 24.9357 108.979 23.914C107.698 23.2889 107.259 21.6824 108.062 20.5061L108.257 20.2238Z"
          fill="#091554"
        />
        <path
          d="M140.958 2.55225C147.655 2.55225 152.713 7.68095 152.713 14.0397C152.713 20.3985 147.655 25.4802 140.958 25.4802C134.262 25.4802 129.204 20.3985 129.204 14.0397C129.204 7.68095 134.262 2.55225 140.958 2.55225ZM140.958 20.0356C144.418 20.0356 146.792 17.3603 146.792 14.0397C146.792 10.7192 144.425 8.0036 140.958 8.0036C137.499 8.0036 135.125 10.7259 135.125 14.0397C135.125 17.3536 137.492 20.0356 140.958 20.0356Z"
          fill="#091554"
        />
        <path
          d="M177.221 5.49669C175.885 4.07839 173.404 2.39795 169.168 2.39795C164.927 2.39795 162.452 4.08511 161.116 5.49669C158.318 8.46099 158.311 12.3731 158.345 13.1998V22.0457C158.345 23.6925 159.68 25.0234 161.332 25.0234C162.984 25.0234 164.32 23.6925 164.32 22.0457V13.1326L164.313 12.9511C164.313 12.931 164.266 10.8607 165.466 9.58353C166.235 8.77019 167.476 8.36017 169.168 8.36017C170.841 8.36017 172.075 8.76347 172.844 9.55664C174.091 10.8472 174.024 12.9511 174.024 12.9511L174.017 22.0457C174.017 23.6925 175.353 25.0234 177.005 25.0234C178.657 25.0234 179.992 23.6925 179.992 22.0457V13.1998C180.033 12.3731 180.019 8.46099 177.221 5.49669Z"
          fill="#091554"
        />
        <path
          d="M41.7965 21.9779C43.1318 23.3962 45.6135 25.0766 49.8487 25.0766C54.0838 25.0766 56.5656 23.3895 57.9009 21.9779C60.6996 19.0136 60.7064 15.1015 60.6726 14.2748V5.42891C60.6726 3.78208 59.3373 2.45117 57.6851 2.45117C56.0328 2.45117 54.6975 3.78208 54.6975 5.42891V14.342L54.7043 14.5235C54.7043 14.5436 54.7515 16.6139 53.5511 17.8911C52.7823 18.7044 51.5414 19.1144 49.8487 19.1144C48.1762 19.1144 46.9421 18.7111 46.1732 17.918C44.9256 16.6274 44.9931 14.5235 44.9931 14.5235L44.9998 5.42891C44.9998 3.78208 43.6645 2.45117 42.0123 2.45117C40.36 2.45117 39.0247 3.78208 39.0247 5.42891V14.2748C38.991 15.1015 38.9977 19.0136 41.7965 21.9779Z"
          fill="#091554"
        />
        <path
          d="M85.7063 5.49669C84.371 4.07839 81.8893 2.39795 77.6541 2.39795C73.4189 2.39795 70.9372 4.08511 69.6019 5.49669C66.8032 8.46099 66.7964 12.3731 66.8301 13.1998V22.0457C66.8301 23.6925 68.1654 25.0234 69.8177 25.0234C71.4699 25.0234 72.8052 23.6925 72.8052 22.0457V13.1326L72.7985 12.9511C72.7985 12.931 72.7513 10.8607 73.9517 9.58353C74.7205 8.77019 75.9614 8.36017 77.6541 8.36017C79.3266 8.36017 80.5607 8.76347 81.3295 9.55664C82.5771 10.8472 82.5097 12.9511 82.5097 12.9511L82.503 22.0457C82.503 23.6925 83.8383 25.0234 85.4905 25.0234C87.1428 25.0234 88.4781 23.6925 88.4781 22.0457V13.1998C88.5118 12.3731 88.505 8.46099 85.7063 5.49669Z"
          fill="#091554"
        />
        <path
          d="M29.3812 22.469C29.57 22.7379 29.8668 22.8791 30.1702 22.8791C30.3591 22.8791 30.5546 22.8186 30.7232 22.7043C33.6569 20.6475 35.4103 17.2866 35.4103 13.7106C35.4103 10.1347 33.6569 6.77379 30.7232 4.71694C30.2849 4.41446 29.6847 4.52201 29.3812 4.9522C29.0777 5.38239 29.1789 5.98735 29.6172 6.28983C32.0383 7.99043 33.4883 10.7598 33.4883 13.7106C33.4883 16.6615 32.0383 19.4308 29.6172 21.1314C29.1789 21.4339 29.0777 22.0321 29.3812 22.469Z"
          fill="#FF6650"
        />
        <path
          d="M27.0815 19.8005C26.7376 19.8005 26.4004 19.6123 26.2251 19.2829C25.9755 18.8124 26.1576 18.2343 26.6297 17.9856C28.2348 17.1521 29.2261 15.512 29.2261 13.7106C29.2261 11.9092 28.228 10.2691 26.6297 9.43558C26.1576 9.18688 25.9755 8.60881 26.2251 8.13829C26.4746 7.66777 27.0546 7.48628 27.5266 7.73499C29.7656 8.90456 31.1616 11.1967 31.1616 13.7173C31.1616 16.238 29.7724 18.5301 27.5266 19.6996C27.385 19.7669 27.2299 19.8005 27.0815 19.8005Z"
          fill="#FF6650"
        />
        <path
          d="M24.3907 16.1641C25.7502 16.1641 26.8522 15.0656 26.8522 13.7106C26.8522 12.3556 25.7502 11.2572 24.3907 11.2572C23.0313 11.2572 21.9292 12.3556 21.9292 13.7106C21.9292 15.0656 23.0313 16.1641 24.3907 16.1641Z"
          fill="#FF6650"
        />
        <path
          d="M16.1836 13.7645L24.1481 4.79092C25.1665 3.51379 24.9574 1.65858 23.6828 0.643595C22.4015 -0.371392 20.5401 -0.163017 19.5218 1.1074L12.4003 9.03236L5.27876 1.11412C4.26043 -0.163017 2.39912 -0.371392 1.11778 0.650317C-0.163554 1.6653 -0.372613 3.52051 0.652456 4.79765L8.61697 13.7712L0.645712 22.6305C-0.372614 23.9076 -0.163553 25.7628 1.11104 26.7778C2.39238 27.7928 4.25369 27.5844 5.27201 26.314L12.4003 18.5033L19.5218 26.314C20.5401 27.5911 22.4015 27.7995 23.6828 26.7778C24.9641 25.7628 25.1732 23.9076 24.1481 22.6305L16.1836 13.7645Z"
          fill="#091554"
        />
      </svg>
    </div>
  );
};
export default HeaderLogo;
