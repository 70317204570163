/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import AnimatedLayout from '../../../Layouts/AnimatedLayout'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import AlertsImage from '../../../common-images/app-logo/customer-images/AlertsImage'
import ConfigurationImage from '../../../common-images/app-logo/customer-images/ConfigurationImage'
import DeviceControlImage from '../../../common-images/app-logo/customer-images/DeviceControlImage'
import DeviceImage from '../../../common-images/app-logo/customer-images/DeviceImage'
import QoeImage from '../../../common-images/app-logo/customer-images/QoeImage'
import ServiceImage from '../../../common-images/app-logo/customer-images/ServiceImage'
import SpeedTestImage from '../../../common-images/app-logo/customer-images/SpeedTestImage'
import TopologyImage from '../../../common-images/app-logo/customer-images/TopologyImage'
import OverviewImage from '../../../common-images/app-logo/customer-images/OverviewImages'
import HealthCheckImage from '../../../common-images/app-logo/customer-images/HealthCheckImages'
import { useLocation, useParams, useNavigate } from 'react-router'
import { BreadcrumbContext } from '../../../contexts/BreadcrumbContext'
import { useDispatch, useSelector } from 'react-redux'
import { getAccountGetWayInfo } from '../../../redux/deviceStatistics/deviceStatistics'
import { RootState } from '../../../redux/deviceStatistics/types'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import GatewayLogs from '../Logs'
import Statistics from '../Statistics'
import StatisticsLogo from '../../../common-images/app-logo/customer-images/StatisticsLogo'
import LogsIcon from '../../../common-images/app-logo/customer-images/LogsIcon'
import SpeedTest from '../SpeedTest'
import CustomersOverview from '../Overview'
import Provisioning from '../../../components/Device-table/Provisioning'
import QoePage from '../Qoe'
import { ElementEqual, ShieldSecurity, Global } from 'iconsax-react'
import CustomerServices from '../../../components/customers/customer-services/customer-services'
import CyberDashboard from '../../Dashboard/cyber-security/dashboard'
import CyberThreats from '../../Dashboard/cyber-security/threats'
import Devices from '../Devices'
import { DeviceInformationRootState } from '../../../redux/deviceInformation/types'
import Topology from '../Topology'
import Policy from '../../Dashboard/parental/policy/policy'
import Dashboard from '../../Dashboard/parental/dashboard/dashboard'
import Supervision from '../../Dashboard/parental/supervision/supervision'
import DeviceControl from '../DeviceControl'
import ConfigurationPage from '../Configuration'
import SessionTimeOutModal from '../../../components/SessionTimeOutModal'
import { cleanCurrentGatewayInfoFromSessionStorage, getColorCode } from '../../../utils/helper'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  width?: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, width = 'auto', ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: width }}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function a11yInnerProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function a11ySecurityProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const CustomersDashboard = () => {
  const dispatch: any = useDispatch()
  const location = useLocation()
  const deviceInformation = useSelector((state: DeviceInformationRootState) => state.deviceInformation.data)
  const userGateways: any = useSelector((state: RootState) => state.deviceStatistics.userGateways) //Get All user devices data
  const activeValue: any = useSelector((state: RootState) => state.deviceStatistics.activeTab) //Get Active Tab
  const [innerValue, setInnerValue] = useState(0)
  const [securityValue, setSecurityValue] = useState(0)
  const [gwUid, setGwUid] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [provistionId, setProvistionId] = useState('')
  const [activeDeviceOption, setActiveDeviceOption] = useState<any>({
    label: '',
    Id: ''
  })
  const navigate = useNavigate()

  const { data: gatewayInfo, isLoading, isFetching, error, refetch }: any = useGatewayInfo(gwUid) //User device data
  const { updateBreadcrumbData, updateFilters, updateDisabled } = useContext(BreadcrumbContext)
  const { id, email } = useParams()

  useEffect(() => {
    if (!!location.pathname && location.pathname.split('/')[2]) {
      setGwUid(location.pathname.split('/')[2])
      dispatch(getAccountGetWayInfo())
    }
  }, [location])
  useEffect(() => {
    refetch()
  }, [gwUid])

  useEffect(() => {
    if (!gatewayInfo) return
    const nav: any = [
      {
        label: 'Home',
        href: '/'
      },
      {
        label: 'Customers',
        href: '/dashboard'
      }
    ]
    if (!!gatewayInfo.email && !!gatewayInfo.gw_uid) {
      nav.push({
        label: `${gatewayInfo.email}`,
        href: `/dashboard/${gatewayInfo.gw_uid}`
      })
    } else {
      nav.push({
        label: (
          <div
            className='provisionLink'
            onClick={(e: any) => {
              e.stopPropagation()
              setProvistionId(gatewayInfo.gw_uid || '')
              setIsModalOpen(true)
            }}
            style={{
              color: getColorCode('infoBlue')
            }}
          >
            Click here to Provision
          </div>
        ),
        href: `/dashboard/${gatewayInfo.gw_uid}`,
        onClick: true
      })
    }

    if (location.search !== '') {
      nav.push(
        { label: 'Parental Control', href: `/dashboard/${gatewayInfo?.gw_uid}?email=${gatewayInfo?.email}` },
        {
          label: innerValue === 0 ? 'Home' : innerValue === 1 ? 'Supervision' : 'Policy',
          href: `/dashboard/${gatewayInfo?.gw_uid}?email=${gatewayInfo?.email}`
        }
      )
    }
    if (!!email) {
      nav.push(
        { label: 'Cyber Security', href: `/dashboard/${gatewayInfo?.gw_uid}/${gatewayInfo?.email}` },
        {
          label: securityValue === 0 ? 'Home' : 'Threats',
          href: `/dashboard/${gatewayInfo?.gw_uid}/${gatewayInfo?.email}`
        }
      )
    }
    updateBreadcrumbData(nav)

    sessionStorage.setItem('email', gatewayInfo.email) // for get all Devices data
    sessionStorage.setItem('mac', gatewayInfo.wan_mac)
    sessionStorage.setItem('gwuid', gatewayInfo.gw_uid)

    dispatch(getAccountGetWayInfo()) // for get all Devices data

    return () => {
      sessionStorage.removeItem('email')
    }
  }, [gatewayInfo, securityValue, innerValue, gwUid, location])

  useEffect(() => {
    if (!!activeDeviceOption.Id) {
      dispatch({ type: 'SET_CUSTOMER_GWUID_REQUEST', payload: activeDeviceOption.Id })
            sessionStorage.setItem('gwuid', activeDeviceOption.Id)
    } else {
      dispatch({ type: 'SET_CUSTOMER_GWUID_REQUEST', payload: '' })
      cleanCurrentGatewayInfoFromSessionStorage()
    }
  }, [activeDeviceOption])

  useEffect(() => {
    if (!!gatewayInfo) setActiveDeviceOption({ label: gatewayInfo.title || 'No Title', Id: gatewayInfo.gw_uid })
  }, [gwUid, gatewayInfo])

  useEffect(() => {
    if (userGateways !== undefined && userGateways.length > 0) {
      const firstArrayData = userGateways.filter((res: any) => {
        return res.dev_mesh_role === 'CAP' && res.is_online
      })
      const secondArrayData = userGateways.filter((res: any) => {
        return res.dev_mesh_role !== 'CAP' && res.is_online
      })
      const thirdArrayData = userGateways.filter((res: any) => {
        return res.dev_mesh_role === 'CAP' && !res.is_online
      })
      const lastArrayData = userGateways.filter((res: any) => {
        return res.dev_mesh_role !== 'CAP' && !res.is_online
      })

      const filterUpdatedData = [...firstArrayData, ...secondArrayData, ...thirdArrayData, ...lastArrayData]

      const filters: any = [
        {
          filterKey: 'dataFilter',
          options: filterUpdatedData.map((item: any) => {
            return { label: `${item.title}`, Id: `${item.gw_uid}` }
          }) as any,
          useLowerCase: true,
          tags: filterUpdatedData.map((item: any) => {
            return (
              <div className='d-flex'>
                {item?.dev_mesh_role === 'CAP' ? (
                  <div
                    className={'master_tag'}
                    style={{ background: getColorCode('blueBlue'), color: getColorCode('white') }}
                  >
                    {' '}
                    Master
                  </div>
                ) : (
                  <div
                    className={'satellite_tag'}
                    style={{ border: `1px solid ${getColorCode('blueBlue')}`, color: getColorCode('blueBlue') }}
                  >
                    Satellite
                  </div>
                )}
                {item?.is_online ? (
                  <div
                    className={'online_tag'}
                    style={{ background: getColorCode('darkGreenToGreen'), color: getColorCode('white') }}
                  >
                    {' '}
                    Online
                  </div>
                ) : (
                  <div
                    className={'offline_tag'}
                    style={{ border: `1px solid ${getColorCode('grayToGray')}`, color: getColorCode('grayToGray') }}
                  >
                    Offline
                  </div>
                )}
              </div>
            )
          }) as any[],
          activeOption: activeDeviceOption as any,
          action: (e: string, opt: any) => {
            sessionStorage.setItem('gwuid', opt.Id)
            if (e === 'dataFilter') {
              setActiveDeviceOption(opt)
            }
          }
        }
      ]
      updateFilters(filters)
    } else if (!!gatewayInfo) {
      const filters: any = [
        {
          filterKey: 'dataFilter',
          options: [{ label: `${gatewayInfo.title}` || 'No title', Id: `${gatewayInfo.gw_uid}` }],
          useLowerCase: true,
          tags: [
            <div className='d-flex'>
              {gatewayInfo?.dev_mesh_role === 'CAP' ? (
                <div
                  className={'master_tag'}
                  style={{ background: getColorCode('blueBlue'), color: getColorCode('white') }}
                >
                  Master
                </div>
              ) : (
                <div
                  className={'satellite_tag'}
                  style={{ border: `1px solid ${getColorCode('blueBlue')}`, color: getColorCode('blueBlue') }}
                >
                  Satellite
                </div>
              )}
              {gatewayInfo?.is_online ? (
                <div
                  className={'online_tag'}
                  style={{ background: getColorCode('darkGreenToGreen'), color: getColorCode('white') }}
                >
                  Online
                </div>
              ) : (
                <div
                  className={'offline_tag'}
                  style={{ border: `1px solid ${getColorCode('grayToGray')}`, color: getColorCode('grayToGray') }}
                >
                  Offline
                </div>
              )}
            </div>
          ],
          activeOption: activeDeviceOption as any,
          action: (e: string, opt: any) => {
            sessionStorage.setItem('gwuid', opt.Id)
            if (e === 'dataFilter') {
              setActiveDeviceOption(opt)
            }
          }
        }
      ]
      updateFilters(filters)
    }
  }, [userGateways, activeDeviceOption, gatewayInfo])
  useEffect(() => {
    if (isFetching) {
      updateDisabled(isFetching)
    } else {
      setTimeout(() => {
        updateDisabled(isFetching)
      }, 1000)
    }
  }, [isFetching])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch({ type: 'SET_DEVICE_ACTIVE_TAB_REQUEST', payload: newValue })
    if (newValue !== 4) {
      dispatch({ type: 'SET_DEVICE_MAC_ID_REQUEST', payload: '' })
    }
  }

  const handleInnerChange = (event: React.SyntheticEvent, newValue: number) => {
    setInnerValue(newValue)
  }

  const handleChangeSecurity = (event: React.SyntheticEvent, newValue: number) => {
    setSecurityValue(newValue)
  }

  useEffect(() => {
    if (location.search !== '') {
      setInnerValue(0)
    }
    if (email) {
      setSecurityValue(0)
    }
  }, [location, email])

  return (
    <AnimatedLayout>
      <div className='customers_wrapper'>
        <div className='custom_main_wrapper'>
          <div className='customer_header_wrapper flex items-center justify-between pr-[20px]'>
            {location.search !== '' ? (
              <Tabs
                orientation='horizontal'
                variant='scrollable'
                aria-label='basic tabs example'
                onChange={handleInnerChange}
                value={innerValue}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: getColorCode('blueBlue')
                  }
                }}
                sx={{
                  '.Mui-selected': {
                    color: `${getColorCode('blueBlue')} !important`
                  }
                }}
              >
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <ElementEqual />
                      <label className='tab-label'>Dashboard</label>
                    </div>
                  }
                  {...a11yInnerProps(0)}
                  className='customer-tab'
                />
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <Global />
                      <label className='tab-label'>Supervision</label>
                    </div>
                  }
                  {...a11yInnerProps(1)}
                  className='customer-tab'
                />
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <ShieldSecurity />
                      <label className='tab-label'>Policy</label>
                    </div>
                  }
                  {...a11yInnerProps(2)}
                  className='customer-tab'
                />
              </Tabs>
            ) : email !== undefined ? (
              <Tabs
                orientation='horizontal'
                variant='scrollable'
                aria-label='basic tabs example'
                onChange={handleChangeSecurity}
                value={securityValue}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: getColorCode('blueBlue')
                  }
                }}
                sx={{
                  '.Mui-selected': {
                    color: `${getColorCode('blueBlue')} !important`
                  }
                }}
              >
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <ElementEqual />
                      <label className='tab-label'>Dashboard</label>
                    </div>
                  }
                  {...a11ySecurityProps(0)}
                  className='customer-tab'
                />
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <Global />
                      <label className='tab-label'>Threats</label>
                    </div>
                  }
                  {...a11ySecurityProps(1)}
                  className='customer-tab'
                />
              </Tabs>
            ) : (
              <Tabs
                orientation='horizontal'
                variant='scrollable'
                aria-label='basic tabs example'
                onChange={handleChange}
                value={activeValue}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: getColorCode('blueBlue')
                  }
                }}
                sx={{
                  '.Mui-selected': {
                    color: `${getColorCode('blueBlue')} !important`
                  }
                }}
              >
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <OverviewImage />
                      <label className='tab-label'>Overview</label>
                    </div>
                  }
                  {...a11yProps(0)}
                  className='customer-tab'
                />
                {/* <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <HealthCheckImage />
                      <label className='tab-label'>Health Check</label>
                    </div>
                  }
                  {...a11yProps(1)}
                  className='customer-tab-health'
                  disabled={true}
                /> */}
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <TopologyImage />
                      <label className='tab-label'>Topology</label>
                    </div>
                  }
                  {...a11yProps(2)}
                  className='customer-tab'
                />
                {/* <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <DeviceImage />
                      <label className='tab-label'>Devices</label>
                    </div>
                  }
                  {...a11yProps(3)}
                  className='customer-tab'
                /> */}
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <QoeImage />
                      <label className='tab-label'>QoE</label>
                    </div>
                  }
                  {...a11yProps(3)}
                  className='customer-tab'
                />
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <SpeedTestImage />
                      <label className='tab-label'>Speed Test</label>
                    </div>
                  }
                  {...a11yProps(4)}
                  className='customer-tab'
                />
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <StatisticsLogo />
                      <label className='tab-label'>Statistics</label>
                    </div>
                  }
                  {...a11yProps(5)}
                  className='customer-tab'
                />
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <ServiceImage />
                      <label className='tab-label'>Services</label>
                    </div>
                  }
                  {...a11yProps(6)}
                  className='customer-tab'
                />
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <LogsIcon />
                      <label className='tab-label'>Logs</label>
                    </div>
                  }
                  {...a11yProps(7)}
                  className='customer-tab'
                />
                {/* <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <AlertsImage />
                      <label className='tab-label'>Alerts</label>
                    </div>
                  }
                  {...a11yProps(8)}
                  className='customer-tab'
                  disabled={true}
                /> */}
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <DeviceControlImage />
                      <label className='tab-label'>Device Control</label>
                    </div>
                  }
                  {...a11yProps(9)}
                  className='customer-tab-control'
                  // disabled={true}
                />
                <Tab
                  label={
                    <div className='d-flex tab-div'>
                      <ConfigurationImage />
                      <label className='tab-label'>Configuration</label>
                    </div>
                  }
                  {...a11yProps(10)}
                  className='customer-tab'
                  // disabled={true}
                />
              </Tabs>
            )}
          </div>
          {(location.search !== '' || email !== undefined) && (
            <button
              type='button'
              onClick={() => {
                sessionStorage.removeItem('isPCverified')
                navigate(`/dashboard/${id}`)
              }}
              className=' text-white px-16 py-2 rounded-[5px] cursor-pointer exitbutton'
              style={{
                background: getColorCode('infoBlue')
              }}
            >
              Exit {location.search !== '' ? 'Parental' : email !== undefined ? 'Cyber' : 'Home'}
            </button>
          )}
        </div>
        <div className='d-flex justify-center mt-[10px !important] pe-4 ps-4 pb-4 mt-4 '>
          <div className={`w-full`}>
            {location.search !== '' ? (
              <>
                <TabPanel value={innerValue} index={0}>
                  <Dashboard />
                </TabPanel>
                <TabPanel value={innerValue} index={1}>
                  <Supervision />
                </TabPanel>
                <TabPanel value={innerValue} index={2}>
                  <Policy />
                </TabPanel>
              </>
            ) : email !== undefined ? (
              <>
                <TabPanel value={securityValue} index={0}>
                  <CyberDashboard />
                </TabPanel>
                <TabPanel value={securityValue} index={1}>
                  <CyberThreats />
                </TabPanel>
              </>
            ) : (
              <>
                <TabPanel value={activeValue} index={0}>
                  <CustomersOverview />
                </TabPanel>
                {/* <TabPanel value={activeValue} index={1}>
                  Index 2
                </TabPanel> */}
                <TabPanel value={activeValue} index={1}>
                  <Topology serialNumber={gwUid} links={undefined} />
                </TabPanel>
                {/* <TabPanel value={activeValue} index={3}>
                  <Devices
                    hideHeader={false}
                    id={activeDeviceOption.Id}
                    meshRole={deviceInformation?.dev_mesh_role || null}
                  />
                </TabPanel> */}
                <TabPanel value={activeValue} index={2}>
                  <QoePage />
                </TabPanel>
                <TabPanel value={activeValue} index={3}>
                  <SpeedTest />
                </TabPanel>
                <TabPanel value={activeValue} index={4}>
                  <Statistics />
                </TabPanel>
                <TabPanel value={activeValue} index={5}>
                  {/* {gatewayInfo && ( */}
                  <CustomerServices />
                  {/* )} */}
                </TabPanel>
                <TabPanel value={activeValue} index={6}>
                  <GatewayLogs />
                </TabPanel>
                {/* <TabPanel value={activeValue} index={8}>
                  Index 9
                </TabPanel> */}
                <TabPanel value={activeValue} index={7}>
                  <DeviceControl />
                </TabPanel>
                <TabPanel value={activeValue} index={8}>
                  <ConfigurationPage />
                </TabPanel>
              </>
            )}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Provisioning
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          provistionId={provistionId}
          refetch={refetch}
        />
      )}
    </AnimatedLayout>
  )
}

export default CustomersDashboard
