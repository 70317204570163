import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getColorCode } from '../../../../utils/helper'
import moment from 'moment'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

const EffectivePhyRxRate = ({ data }: any) => {
  // effective_rx_options
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  const effective_phy_rx_rate_chart_data =
    data?.map((item: any) => {
      var localtime = dayjs.utc(item.timestamp, 'YYYYMMDDTHH:mm:ss').tz(timeZone).toDate()

      return [localtime?.getTime(), item.eff_phy_rx]
      // return [Date.parse(item.timestamp), item.eff_phy_rx]
    }) || []

  const options = {
    chart: {
      type: 'area',
      height: 210,
      marginTop: 50,
      marginBottom: 40
    },
    title: {
      text: 'Effective PHY rate (Rx)',
      align: 'left',
      x: 10
    },
    xAxis: {
      type: 'datetime',
      tickInterval: 1 * 60 * 60 * 1000, // 3 hours in milliseconds
      labels: {
        formatter: function (this: any) {
          return moment(this.value).format('HH:mm')
        }
      },
      dateTimeLabelFormats: {
        hour: '%I:%M %p', // Format for hours
        day: '%d/%m %H:%M' // Format for days
      }
    },
    // xAxis: {
    //   type: 'datetime',
    //   tickInterval: 3 * 60 * 60 * 1000, // 3 hours in milliseconds
    //   dateTimeLabelFormats: {
    //     hour: '%I:%M %p', // Format with AM/PM
    //     day: '%I:%M %p' // Format with AM/PM
    //   }
    // },
    yAxis: {
      title: {
        text: undefined
      },
      gridLineColor: 'transparent',
      lineWidth: 1,
      min: 0,
      max: 100,
      labels: {
        formatter: function (this: any) {
          return this.value + '%'
        },
        step: 2
      },
      tickInterval: 20
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      floating: true,
      borderWidth: 0,
      backgroundColor: '#f0f8ff00'
    },
    plotOptions: {
      area: {
        dataLabels: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: 'Effective PHY rate (Rx)',
        data: effective_phy_rx_rate_chart_data,
        color: getColorCode('chartRedPink')
      }
    ],

    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        const timestamp =
          moment(this.x).local().fromNow() +
          '(' +
          moment(this.x).local().format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm:ss`) +
          ')'
        return (
          '<div class="custom-tooltip">' +
          '<span><b>' +
          this.series.name +
          ': </b></span>' +
          '<span>' +
          (this.y as any).toFixed(2) +
          '(%)</span><br/>' +
          '<span> <b>when: </b>' +
          timestamp +
          '</span>' +
          '</div>'
        )
      }
    }
  }
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default EffectivePhyRxRate
