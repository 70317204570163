import React from 'react'

const GroupImage = () => {
  return (
    <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 11.76H0V10.4533C0 4.6877 4.70863 0 10.5 0C16.2914 0 21 4.6877 21 10.4533V11.76ZM0.874947 10.8889H20.1251V10.4533C20.1251 5.17228 15.8046 0.871058 10.5 0.871058C5.19537 0.871058 0.874947 5.17228 0.874947 10.4533V10.8889Z" fill="black"/>
        <path d="M14.395 6.72021L15.1199 7.30019L11.6449 10.0802L10.9199 9.50024L14.395 6.72021Z" fill="black"/>
        <path d="M18.48 10.92H17.5934V10.0801C17.5934 6.37356 14.4124 3.35997 10.5 3.35997C6.58762 3.35997 3.40663 6.37356 3.40663 10.0801V10.92H2.52002V10.0801C2.52002 5.91152 6.09991 2.52002 10.5 2.52002C14.9001 2.52002 18.48 5.91152 18.48 10.0801V10.92Z" fill="black"/>
        <path d="M12.5999 10.9199H11.7599V10.5C11.7599 9.80694 11.193 9.24 10.4999 9.24C9.80682 9.24 9.23987 9.80694 9.23987 10.5V10.9199H8.3999V10.5C8.3999 9.33967 9.3397 8.3999 10.4999 8.3999C11.6601 8.3999 12.5999 9.33967 12.5999 10.5V10.9199Z" fill="black"/>
        <path d="M10.0801 0.839844H10.9201V3.35984H10.0801V0.839844Z" fill="black"/>
        <path d="M17.0101 3.35986L17.6401 3.9899L15.7502 5.87986L15.1201 5.24999L17.0101 3.35986Z" fill="black"/>
        <path d="M3.15006 3.35986L5.04002 5.24982L4.40998 5.87986L2.52002 3.9899L3.15006 3.35986Z" fill="black"/>
    </svg>

  )
}
export default GroupImage
