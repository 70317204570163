import { ReactNode, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import QuickSwitchToolTip from '../../shadcn/components/ui/radix-tooltip'
import VerifyPin from '../../components/parental-components/verify-pin/verify-pin'
import './styles.scss'

type ParentalLayoutProps = {
  children: ReactNode
  bg?: string
  serialNumber: string
  useSidebar: 'Parental' | 'Cyber' | 'Home'
}

const ParentalLayout = ({ children, bg, serialNumber, useSidebar }: ParentalLayoutProps) => {
  const activeAlerts = localStorage.getItem('assignedAlerts')

  const navigate = useNavigate()

  const location = useLocation()

  const isNotificationsActive = location.pathname === '/notifications'

  const [pcVerified, setPcVerified] = useState<boolean>(false)
  const [open, setOpen] = useState(true)

  const isPCverified = sessionStorage.getItem('isPCverified') === 'true' || pcVerified

  const handleOnPCVerified = () => {
    setPcVerified(true)
  }

  const toggleDrawer = (e: any) => {
    e.preventDefault()
    setOpen(!open)
  }
  return (
    <div className='flex overflow-hide layout-wrapper'>
      {/* <div  className={
          open
            ? 'w-full max-w-[21.875rem]  p-[31px]  border-r-2 md:block h-screen sidebars'
            : 'w-full max-w-[7.9rem]  p-[31px]  border-r-2 md:block h-screen sidebars'
        }>
        <ParentalSidebar serialNumber={serialNumber} useSidebar={useSidebar} open={open} />
      </div> */}

      <div className={`grow h-[calc(100vh-230px)] overflow-x-auto overflow-y-hidden layout-wrapper-inner`}>
        {/* navigation */}
        {/* <nav className='bg-white border-b-4  border-l-[3px] border-[#EFF2FC] py-[3px] px-[28px]'>
          <div className='flex items-center justify-between gap-x-[32px] py-[10px]'>
            <div className='grow max-w-[960px]'>
              <div className='flex items-center gap-x-[16px] '>
                <div className='mobile-menu-icon menu-icon-right' onClick={toggleDrawer}>
                  <HambergerMenu size='28' color='#FF8A65' />
                </div>
                <button
                  className='block text-center w-[60px] h-[60px] hover:bg-background-lightgray rounded-[15px]  border-[2px] border-[#EFF2FC]  text-white '
                  aria-current='page'
                >
                  <svg
                    width='24'
                    height='24'
                    className='mx-auto hover:animate-spin'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M22 12.0001C22 17.5201 17.52 22.0001 12 22.0001C6.48 22.0001 3.11 16.4401 3.11 16.4401M3.11 16.4401H7.63M3.11 16.4401V21.4401M2 12.0001C2 6.48006 6.44 2.00006 12 2.00006C18.67 2.00006 22 7.56006 22 7.56006M22 7.56006V2.56006M22 7.56006H17.56'
                      stroke='#6C83FF'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                {useSidebar === 'Home' && (
                  <div className=' grow   md:max-w-[848px] shrink-0'><GlobalSearchInput /></div>
                )}
              </div>
            </div>

            <div className='min-w-[230px] hidden md:block text-right'>
              <div>
                <button
                  type='button'
                  onClick={() => {
                    sessionStorage.removeItem('isPCverified')
                    navigate(`/dashboard/${sessionStorage.getItem('gwuid') || serialNumber}`)
                  }}
                  className='bg-alerts-info text-white px-16 py-2 rounded-[5px] cursor-pointer'
                >
                  Exit {useSidebar === 'Parental' ? 'Parental' : useSidebar === 'Cyber' ? 'Cyber' : 'Home'}
                </button>
              </div>
              <div className='flex  gap-x-[32px] items-center hidden'>
                <button
                  className={`block text-center w-[60px] ${
                    isNotificationsActive ? 'bg-alerts-closed text-white' : 'text-black'
                  }   h-[60px] rounded-[15px]  border-[2px] border-[#EFF2FC] hover:text-layout-textOnSurface2   hover:bg-background-lightgray relative`}
                  aria-current='page'
                  onClick={() => navigate('/notifications')}
                >
                  <Notification size={24} className='mx-auto' />
                  <span className='absolute top-[-15px] rounded-full bg-alerts-critical w-[33px] h-[33px] flex items-center justify-center  right-[-12px] text-white'>
                    2
                  </span>
                </button>

                <LogoutModal />

                <button
                  type='button'
                  onClick={() => navigate('/profile')}
                  className='text-alerts-critical relative w-[44px] h-[44px]  hover:ring-1 focus:ring-1  focus:outline-none font-medium rounded-full text-sm  text-center mr-3 md:mr-0 border-[2px] border-[#A3D696] '
                >
                  {user && user.avatar ? (
                   <img src={avatar} className='object-fit p-1' alt={`${getFirstTwoLetters(user?.name || 'user')}`} />
                  ) : (
                   getFirstTwoLetters(user?.name || '')
                  )}
                  <div className='absolute inset-[-2px]  bg-alerts-closed w-[44px] h-[44px] text-white flex items-center justify-center rounded-full bg-opacity-80 opacity-0 hover:opacity-100 transition-opacity duration-300'>
                    PK
                  </div>
                </button>
              </div>
            </div>
          </div>
        </nav> */}

        <div className='flex layout-inner-main'>
          <div className='w-full overflow-x-auto layout-inner-block'>
            {/* breadcrumb */}
            {/* <Breadcrumb serialNumber={serialNumber} useSidebar={useSidebar} /> */}
            {/* page content */}
            <div className='py-[20px] page-content '>
              {isPCverified ? children : <VerifyPin onPcVerified={handleOnPCVerified} />}
            </div>
          </div>
          {activeAlerts !== null && (
            <div className='w-[62px] quick-switch-navbar bg-white flex flex-col items-center pt-[34px] gap-y-[6px] layout-inner-mainblock'>
              {activeAlerts !== null
                ? JSON.parse(activeAlerts).map((alert: any, index: number) => {
                    return (
                      <QuickSwitchToolTip
                        key={`active_alert_${index}`}
                        email={alert.user}
                        serialNumber={alert.serialNumber}
                        alert={alert.alert}
                      />
                    )
                  })
                : null}
            </div>
          )}
        </div>
        {/* <Toaster /> */}
      </div>
    </div>
  )
}

export default ParentalLayout
