import { CustomerState, CustomerAction } from './types'

const initialState: CustomerState = {
  data: [],
  loading: false,
  error: null,
  totalData: [],
  activeGw_Uid: ''
}

const customerStatisticsReducer = (state = initialState, action: CustomerAction): CustomerState => {
  switch (action.type) {
    case 'GET_CUSTOMER_STATISTICS_REQUEST':
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      }
    case 'GET_CUSTOMER_STATISTICS_SUCCESS':
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null
      }
    case 'GET_CUSTOMER_STATISTICS_COUNT_REQUEST':
      return {
        ...state,
        totalData: [],
        loading: true,
        error: null
      }
    case 'GET_CUSTOMER_STATISTICS_COUNT_SUCCESS':
      return {
        ...state,
        totalData: action.payload,
        loading: false,
        error: null
      }
    case 'GET_CUSTOMER_STATISTICS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'SET_CUSTOMER_GWUID_REQUEST':
      return {
        ...state,
        activeGw_Uid: action.payload
      }
    default:
      return state
  }
}

export default customerStatisticsReducer
