import React, { useState } from 'react'
import { Box, Button, Snackbar, TextField } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { getColorCode } from '../../utils/helper'
import { deleteAccount } from '../../hook/useDeleteAccount'
import { Modal } from 'antd'

function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}
const DeleteAccount = () => {
  const [email, setEmail] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [gid, setGid] = useState<any>('')
  const { mutate: deleteMutate } = deleteAccount()
  const [snackBarInfo, setSnackBarInfo] = useState<any>({
    isOpen: false,
    message: '',
    type: 'Closed'
  })

  const handleChange = (e: any) => {
    console.log(e.target.name, e.target.value)
    if (e.target.name === 'email') {
      setEmail(e.target.value)
    } else {
      setGid(e.target.value)
    }
  }
  const onSubmit = async () => {
    setIsModalOpen(true)
    console.log('============', email, gid)
    // try {
    //   const res = await deleteMutate({ id: '' })
    //   console.log('-------', res)
    // } catch (error) {
    //   setSnackBarInfo({
    //     isOpen: true,
    //     message: `Failed to delete Account.`,
    //     type: 'redToRed'
    //   })
    // }
  }

  const handleDeleteAccount = () => {
    handleCancel()
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <Box maxWidth={'500px'} margin={'auto'}>
        <Snackbar
          open={snackBarInfo.isOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={3000}
          onClose={() =>
            setSnackBarInfo({
              isOpen: false,
              message: snackBarInfo.message,
              type: snackBarInfo.type
            })
          }
          sx={{ backgroundColor: 'transparent' }}
        >
          <div>
            <Alert
              severity='success'
              sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
            >
              {snackBarInfo.message}
            </Alert>
          </div>
        </Snackbar>
        <Box marginTop={'30px'} fontWeight={'bold'} textAlign={'center'}>
          Deleting xIQ Account
        </Box>
        <Box marginTop={'10px'}>
          Deleting your account will permanently erase all of your information, devices, reports, and configurations
          from our database. This action cannot be reversed.
        </Box>
        <Box marginTop={'10px'}>
          After the process is completed, you will receive a notification email at the address provided above.
        </Box>
        <Box display={'flex'} marginTop={'10px'}>
          <Box marginLeft={'5px'} marginRight={'5px'}>
            <TextField
              type='email'
              id='outlined-basic'
              label='Email'
              variant='outlined'
              size='small'
              name='email'
              onChange={e => handleChange(e)}
            />
          </Box>
          <Box marginLeft={'5px'} marginRight={'5px'}>
            <TextField
              id='outlined-basic'
              label='Gateway UID'
              variant='outlined'
              size='small'
              name='gid'
              onChange={e => handleChange(e)}
            />
          </Box>
        </Box>
        <Box marginTop={'10px'}>
          <Button
            variant='contained'
            onClick={onSubmit}
            style={{
              color: getColorCode('logOutBtnText'),
              background: getColorCode('logOutBtnBg')
              // border: `2px solid ${getColorCode('logOutBtnText')}`
            }}
          >
            Request Delete
          </Button>
        </Box>
        <Box marginTop={'10px'}>
          To proceed, kindly provide your email address and gateway UID (if the device is linked to your account). If
          the device is not associated with your account, you may leave this field blank.
        </Box>
        <Box marginTop={'10px'}>
          <Box fontWeight={'bold'}>NOTE:</Box>
          <Box>
            You can find your gateway UID on the sticker located at the bottom of your device. It is typically printed
            alongside other device information.
          </Box>
        </Box>
      </Box>
      <Modal
        open={isModalOpen}
        footer={null}
        closable={false}
        width={600}
        wrapClassName={'log_out_wrapper'}
        centered={true}
      >
        <div className=''>
          <div className='log_out_title'>
            <h3>Request Received</h3>
          </div>
          <div className='log_out_body_wrapper'>
            <div className='log_out_body'>
              <h5 className='conformation_text'>
                Your request for the deletion of your xIQ account has been received. Once the process is completed, you
                will receive a notification email at the address provided.
              </h5>
            </div>
            <div className='flex justify-center'>
              {/* <button
                type='button'
                onClick={handleCancel}
                className='cancel_btn'
                style={{
                  color: getColorCode('cancelBtnText'),
                  background: getColorCode('cancelBtnBg'),
                  border: `2px solid ${getColorCode('cancelBtnText')}`
                }}
              >
                Cancel
              </button> */}
              <Button
                variant='contained'
                onClick={handleDeleteAccount}
                style={{
                  color: getColorCode('logOutBtnText'),
                  background: getColorCode('logOutBtnBg')
                  // border: `2px solid ${getColorCode('logOutBtnText')}`
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DeleteAccount
