import { useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { apiUrl } from '../app-config'
import xcpemSec from '../axiosInstances/xcpemSec'
import { GatewayServices } from '../models/Xcpem'
import { notifyError } from '../components/Toaster'

export const useGatewayServices: any = (
  activeGw_Uid: string
): {
  data: GatewayServices | undefined
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['gateway-services'],
    async () => {
      const gw_uid = activeGw_Uid || sessionStorage.getItem('gwuid')
      if (!gw_uid) return
      const endpoint = `${apiUrl}/webapp_gw_srv_list?gw_uid=eq.${gw_uid}`
      try {
        const response = await xcpemSec.get<GatewayServices[]>(endpoint)
        return response.data[0]
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`GW srv list: ${error?.response?.data?.message || error?.message}`)
        }
        // throw new Error('Failed to fetch gateway services')
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, error, isFetching, isFetched, refetch }
}

export const usePutGatewayServices = () => {
  const putRequest = async ({ gw_uid, payload }: any) => {
    const gw_uida = gw_uid || sessionStorage.getItem('gwuid')
    const endpoint = `${apiUrl}/webapp_gw_srv_list?gw_uid=eq.${gw_uida}`

    try {
      const response = await xcpemSec.patch(endpoint, payload)
      return response.data
    } catch (error: any) {
      notifyError(`GW srv list: ${error?.response?.data?.message || error?.message}`)
      throw new Error(error?.response?.data?.message || error?.message)
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error, isSuccess } = useMutation(putRequest)

  return { mutate, isLoading, error, isSuccess }
}
