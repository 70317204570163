import Box from '@mui/material/Box'
// @ts-ignore
import styles from './styles.module.scss'
import { getColorCode } from '../../../utils/helper'
import RadialBarChart from '../radial-progress-bar/radial-progress-bar'
import { ArrowUp2 } from 'iconsax-react'
import TrafficScannedBarChart from './bar-chart/bar-chart'

import { ArrowSquareDown, ArrowSquareRight, ArrowSquareUp, TickSquare } from 'iconsax-react'
import { FC } from 'react'
import SessionTimeOutModal from '../../SessionTimeOutModal'

export const getAlertDescriptionIcon = (status: string, size: number = 32, variant: string = 'Linear'): any => {
  const colorCode = getColorCode(status)

  switch (status) {
    case 'Info':
      return <ArrowSquareDown color={colorCode} size={size} variant={variant as any} />
      break
    case 'Warning':
      return <ArrowSquareRight color={colorCode} size={size} variant={variant as any} />
      break
    case 'Critical':
    case 'redToBlue':
      return <ArrowSquareUp color={colorCode} size={size} variant={variant as any} />
      break
    case 'Closed':
      return <TickSquare color={colorCode} size={size} variant={variant as any} />
      break
  }
}

interface Props {
  trafficScanned: string
}

const TrafficScanned: FC<Props> = ({ trafficScanned }) => {
  return (
    <Box className={styles.security_score}>
      <p className={styles.title}>Traffic Scanned</p>

      <div className={styles.security_score_inner}>
        <span className={styles.innerTitle}>{trafficScanned}</span>
        {/*<span className='inline-flex text-alerts-closed gap-x-[6px] items-center'>*/}
        {/*  <ArrowUp2 size='35' className='' variant='Bold' />*/}
        {/*  <span className='text-[30px]'>50%</span>*/}
        {/*</span>*/}
      </div>

      {/*<div className='mt-auto'>*/}
      {/*  <TrafficScannedBarChart />*/}
      {/*</div>*/}
    </Box>
  )
}

export default TrafficScanned
