import { FC } from 'react'
import { getColorCode } from '../../../utils/helper'
import WifiClassifier from './wifi-classifier'

interface Props {
  x: number
  y: number
  color: string
  image: string
  isMaster: boolean
  deviceName: string
  connectionType: 'Wireless' | 'LAN' | 'ETHERNET' | 'WIFI'
  rssi?: number
  speed?: number
  mac: string
  showMac: boolean
  showRssi: boolean
  showName: boolean
  showSpeed: boolean
  parentX: number
  parentY: number
  isActiveTile?: boolean
  onClick?: (mac: string) => void
  deviceInfo?: any
  isSatellite?: boolean
}

function isWithinPercentageRange(baseValue: number, valueToCheck: number, percentage: number) {
  const lowerLimit = baseValue * (1 - percentage / 100)
  const upperLimit = baseValue * (1 + percentage / 100)
  return valueToCheck >= lowerLimit && valueToCheck <= upperLimit
}

export const CustomNode: FC<Props> = ({
  x,
  y,
  color,
  image,
  isMaster,
  deviceName,
  connectionType,
  rssi,
  speed,
  mac,
  showMac,
  showName,
  showRssi,
  showSpeed,
  parentX,
  parentY,
  isActiveTile,
  onClick,
  deviceInfo,
  isSatellite
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(deviceInfo) // Call the onClick function with the mac parameter
    }
  }

  const onLeft = x < parentX
  const onTop = y < parentY

  const nodeStyle: any = {
    position: 'absolute',
    left: isMaster || isSatellite ? x - 130 : x - 120,
    top: isMaster || isSatellite ? y - 200 : y - 70,
    padding: 0,
    flexDirection: onTop ? 'column-reverse' : 'column'
  }

  const textContainerStyle: any = {
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'column',
    alignItems: onLeft ? 'end' : 'start',
    width: '100%',
    minWidth: '450px',
    position: 'absolute',
    // left:  (onLeft && isWithinPercentageRange(parentX, x, 20) && onTop) ?  '-200px' :  (onLeft && !isWithinPercentageRange(parentX, x, 20) && onTop ) ? '-200px' : (onLeft && !onTop && !isWithinPercentageRange(parentX, x, 20) ) ? '-200px' :  '5px',
    // top: isWithinPercentageRange(parentX,x,30) ? '20px' : '0px',
    left: onLeft ? '-450px' : '185px',
    top:
      onTop && isWithinPercentageRange(parentX, x, 30)
        ? '-55px'
        : !onTop && isWithinPercentageRange(parentX, x, 20)
        ? '30px'
        : '-5px'
  }

  const RouterNodeStyle: any = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: 340
  }

  const width = isMaster || isSatellite ? 250 : 200
  const height = isMaster || isSatellite ? 450 : 150

  const deviceNamecleaned = deviceName?.includes('Nixplay Seed WiFi Digital Picture Frame') ? 'Smart TV' : deviceName
  const folder: any = process.env.REACT_APP_TITLE

  return (
    <>
      <div style={nodeStyle} onClick={handleClick}>
        <svg
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          width={width}
          height={height}
          viewBox='0 0 200 173.20508075688772'
          // style={{
          //   filter: 'drop-shadow(rgba(255, 255, 255, 0.5) 0px 0px 8px)'
          // }}
        >
          <g stroke={isActiveTile ? '#FF8181' : 'none'} stroke-width='12'>
            <path
              fill={color}
              d='M20.000000000000004 121.2435565298214Q0 86.60254037844386 20.000000000000004 51.96152422706632L29.999999999999996 34.64101615137754Q50 0 90 0L110 0Q150 0 170 34.64101615137754L180 51.96152422706632Q200 86.60254037844386 180 121.2435565298214L170 138.56406460551017Q150 173.20508075688772 110 173.20508075688772L90 173.20508075688772Q50 173.20508075688772 29.999999999999996 138.56406460551017Z'
            ></path>
            <image
              xlinkHref={`/images/network-map-${
                folder.toLowerCase() !== 'xunison' ? 'hitron-images' : 'images'
              }/${image}.png`}
              x='-50'
              y='25'
              width='300'
              height='125'
            />
          </g>
        </svg>

        <div style={isMaster || isSatellite ? RouterNodeStyle : textContainerStyle}>
          {/*<div className=' flex flex-col   justify-center items-center w-full bg-transparent p-0 gap-y-0'>*/}
          {showName && <span className='text-[34px] font-sf-semibold text-[#282828]'>{deviceNamecleaned}</span>}
          {showMac && <span className='text-[32px] font-sf-semibold text-[#282828]'>{mac}</span>}
          {isMaster && showSpeed && (
            <div className='inline-flex items-center justify-center p-0'>
              <div className='px-3  text-[22px]  py-1 bg-alerts-closed text-white rounded-l-[10px] speedtest-button border-r-2 border-white'>
                -
              </div>

              <div className='px-3  text-[22px]  py-1 bg-alerts-critical text-white rounded-r-[10px] speedtest-button'>
                -
              </div>
            </div>
          )}
          {(!isMaster || isSatellite) && showRssi && (
            <span className=' text-[28px]  font-sf-medium text-[#282828]  '>
              {connectionType === 'WIFI' ? `(${rssi} dBm / ${speed} Mbps)` : `${speed} Mbps`}
            </span>
          )}
        </div>
      </div>
    </>
  )
}

export const BandClassifier = ({ sourceX, sourceY, link, showchannel, mode }: any) => {
  const x1 = sourceX
  const y1 = sourceY
  const x2 = link.x
  const y2 = link.y

  const centerX = (x1 + x2) / 2
  const centerY = (y1 + y2) / 2

  const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI)

  const isLeftSide = x1 > x2

  const isFlipped = x1 == x2 && y1 > y2

  const placeholder: any = {
    position: 'absolute',
    left: isFlipped ? centerX - 25 : centerX + 1,
    top: isFlipped ? centerY - 40 : centerY,
    transform: !isFlipped ? `translate(-50%, -50%) rotate(${angle}deg)` : 'none',
    padding: 0,
    display: 'flex',
    flexDirection: isFlipped ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem'
  }

  const textStyles: any = {
    transform: isLeftSide ? 'scale(-1, -1)' : 'none', // Invert text on the left side
    backgroundColor: 'rgb(169 169 169)', // Define your background color
    // backgroundColor: getColorCode('Closed'), // Define your background color
    padding: '0px 18px',
    minWidth: '100px',
    borderRadius: '999px',
    color: 'black',
    fontSize: '34px',
    fontFamily: 'SFProDisplay-Light, sans-serif',
    fontWeight: 'light',
    textAlign: 'center'
  }

  const flippedSvg = {
    transform: isLeftSide ? 'scale(-1, -1)' : 'none'
  }

  return (
    <>
      <div style={placeholder} className={`flex flex-row gap-x-10 ${isFlipped ? 'flex-col' : 'flex-row'}`}>
        {/* {showchannel && <WifiClassifier mode={mode} style={isLeftSide ? flippedSvg : {}} />} */}
        {showchannel && (
          <span className='bg-alerts-closed px-2 rounded-full text-white font-sf-light text-[32px]' style={textStyles}>
            {link.channel && `Ch-${link.channel}`}
          </span>
        )}
      </div>
    </>
  )
}
